import axios from "axios";
import * as config from "../../../config";

export function getvisitProfile(Id) {
    return axios
        .get(config.urlAPI + "/visit/" + Id, config.headerCallAPI)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            return err.response;
        });
}

export function getstudentdetailProfile(Id) {
    return axios
        .get(config.urlAPI + "/getstudentdetailProfile/" + Id, config.headerCallAPI)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            return err.response;
        });
}

export function getParentVisits(Id) {
    return axios
        .get(config.urlAPI + "/getparentvisits/" + Id)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            return err.response;
        });
}

export function getSibling(sibling) {
    return axios
        .get(config.urlAPI + "/getsibling/" + sibling)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            return err.response;
        });
}

export function getguardian1(id) {
    return axios
        .get(config.urlAPI + "/getguardian1/" + id)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            return err.response;
        });
}

export function getguardian2(id) {
    return axios
        .get(config.urlAPI + "/getguardian2/" + id)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            return err.response;
        });
}

export function getimmunization_gethealthCheckStudents_health(id) {
    return axios
        .get(config.urlAPI + "/getimmunization_gethealthCheckStudents_health/"+id)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            return err.response;
        });
}

export function getdocumentbyvisit_id(id) {
    return axios
        .get(config.urlAPI + "/getdocumentbyvisit_id/"+id)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            return err.response;
        });
}

export function submitregister(data) {
    return axios
        .post(config.urlAPI + "/submitregister", data)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            return err.response;
        });
}

export function getinterview(id) {
    return axios
        .get(config.urlAPI + "/getinterview/"+id)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            return err.response;
        });
}

