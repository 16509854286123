import axios from "axios";
import * as config from "../../../config";

export function getstudentdetailProfile(Id) {
    return axios
        .get(config.urlAPI + "/getstudentdetailProfile/" + Id, config.headerCallAPI)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            return err.response;
        });
}

export function getdocument(visit_id) {
    return axios
        .get(config.urlAPI + "/getdocument/"+visit_id)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            return err.response;
        });
}

export function updatedoctype1(data, student_id) {
    return axios
        .post(
            config.urlAPI + "/doctype1/upload/" + student_id,
            data,
            config.headerCallAPI
        )
        .then((res) => {
            return res;
        })
        .catch((err) => {
            return err.response;
        });
}

export function updatedoctype3(data, student_id) {
    return axios
        .post(
            config.urlAPI + "/doctype3/upload/" + student_id,
            data,
            config.headerCallAPI
        )
        .then((res) => {
            return res;
        })
        .catch((err) => {
            return err.response;
        });
}

export function updatedoctype4(data, student_id) {
    return axios
        .post(
            config.urlAPI + "/doctype4/upload/" + student_id,
            data,
            config.headerCallAPI
        )
        .then((res) => {
            return res;
        })
        .catch((err) => {
            return err.response;
        });
}
