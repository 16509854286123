import React, { useState, useEffect } from "react";
import { Field, reduxForm, formValueSelector } from "redux-form";
import renderField from "../../../components/renderField";
import renderSelectField from "../../../components/renderSelectField";
import renderFileField from "../../../components/renderFileField";
import renderDateTimeField from "../../../components/renderDateTimeField";
import renderDateOnlyField from "../../../components/renderDateOnlyField";
import renderCheckbox from "../../../components/renderCheckboxField";
import { Button, Form, Message, Grid, Card } from "semantic-ui-react";
import SecondButton from "../../../components/secondbutton";
import * as config from "../../../config";
import { connect } from "react-redux";
import {
  Header,
  Image,
  Segment,
  Breadcrumb,
  Loader,
  Dimmer,
  Tab,
  Table,
  Icon,
  Label,
  Step,
  FormGroup,
} from "semantic-ui-react";
import renderDateField from "../../../components/renderDateOnlyField";
import { withRouter } from "react-router-dom";
import Wrap from "../../../components/wrap";
import ModalBasic from "../../../components/modal";
import { getvisitProfile } from "./service";

const validate = (values) => {
  const errors = {};
  if (!values.pdpa) errors.pdpa = true;
  return errors;
};

let PDPA = (props) => {
  const {
    loading,
    pristine,
    submitting,
    onSubmit,
    handleSubmit,
    initialValue,
    onClose,
    errormsg,
    // start_card,
    // end_card,
    // studentId,
  } = props;
  // console.log(Id['1'])
  const Id = props.location.search.split("=");
  //   const [visitProfiles] = useState(Id[1]);
  //   const [visitData, setVisitData] = useState([]);
  const [open, setOpen] = useState(false);

  //   const showPopup = () => {
  //     setOpen(true);
  //   };
  //   const popUpClose = () => {
  //     setOpen(false);
  //     window.location.reload();
  //     window.close();
  //   };

  //   useEffect(() => {
  //     if (visitProfiles) {
  //       getData();
  //     }
  //   }, [visitProfiles]);

  //   const getData = () => {
  //     getvisitProfile(Id[1]).then((res) => {
  //       if (res && res.status === 200) {
  //         setVisitData(res.data);
  //       }
  //     });
  //   };
  //   console.log(visitData);
  console.log(`pdpa loading`);

  return (
    <>
      <div>
        {/* {visitData && (
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Header as="h4">
              Parent/Guardian Consent Form photographing and Filming of
              Student./ แบบฟอร์มยินยอมของผู้ปกครอง
              การถ่ายภาพและการถ่ายวีดีโอของนักเรียน
            </Header>
            <Step.Content style={{ paddingLeft: "3%" }}>
              Kidz Village International Kindergarten's policy of photographing
              and Filming of students is as follows: /
              นโยบายการถ่ายภาพและถ่ายวีดีโอของโรงเรียนอนุบาลนานาชาติ คิดส์
              วิลเลจ มีดังนี้
            </Step.Content>
            <Step.Content style={{ paddingLeft: "6%", fontSize: "95%" }}>
              <ul>
                <li>
                  Photographs and videos of Students engaged in class and field
                  activities will be takenthroughout the year. /
                  ภาพถ่ายและวีดีโอของนักเรียนที่มีส่วนร่วมในกิจกรรมในชั้นเรียนและภาคสนามจะถูกถ่ายตลอดทั้งปี
                </li>
                <br></br>
                <li>
                  Photographs and videos willbe used for class /school displays
                  and portfolios./ ภาพถ่ายและวีดีโอจะใช้สำหรับการแสดงในชั้นเรียน
                  /โรงเรียนและแฟ้มผลงาน
                </li>
                <br></br>
                <li>
                  Photographs and video may be used for promotional purposes
                  such as brochures or print advertising. /
                  อาจใช้ภาพถ่ายและวีดีโอเพื่อวัตถุประสงค์ในการส่งเสริมการขายเช่น
                  โบรชัวร์หรือโฆษณาสิ่งพิมพ์
                </li>
                <br></br>
                <li>
                  Photographs and video may be displayed on the school website.
                  / รูปภาพและวิดีโออาจแสดงบนเว็บไซต์ของโรงเรียน
                </li>
                <br></br>
                <li>
                  Photographs and video may be displayed on other school
                  marketing users. /
                  รูปภาพและวิดีโออาจปรากฎบนการใช้การตลาดของโรงเรียนอื่นๆ
                </li>
                <br></br>
              </ul>
            </Step.Content>
            <Grid>
              <Grid.Row>
                <Grid.Column>
                  <Field
                    name="pdpa"
                    required={true}
                    component={renderCheckbox}
                  />
                </Grid.Column>
                <Grid.Column width="4">I Grant / ข้าพเจ้ายินยอม</Grid.Column>
              </Grid.Row>
            </Grid>
            <br></br>
            <Button
              floated="left"
              content="Submit"
              style={{
                backgroundColor: config.colorTheme,
                color: "#ffff",
                minWidth: "5%",
              }}
              loading={loading}
              //   onClick={() => showPopup()}
            />
          </Form>
        )} */}

        <Form onSubmit={handleSubmit(onSubmit)}>
          <Header as="h4">
            Parent/Guardian Consent Form photographing and Filming of Student./
            แบบฟอร์มยินยอมของผู้ปกครอง การถ่ายภาพและการถ่ายวีดีโอของนักเรียน
          </Header>
          <Step.Content style={{ paddingLeft: "3%" }}>
            Kidz Village International Kindergarten's policy of photographing
            and Filming of students is as follows: /
            นโยบายการถ่ายภาพและถ่ายวีดีโอของโรงเรียนอนุบาลนานาชาติ คิดส์ วิลเลจ
            มีดังนี้
          </Step.Content>
          <Step.Content style={{ paddingLeft: "6%", fontSize: "95%" }}>
            <ul>
              <li>
                Photographs and videos of Students engaged in class and field
                activities will be takenthroughout the year. /
                ภาพถ่ายและวีดีโอของนักเรียนที่มีส่วนร่วมในกิจกรรมในชั้นเรียนและภาคสนามจะถูกถ่ายตลอดทั้งปี
              </li>
              <br></br>
              <li>
                Photographs and videos willbe used for class /school displays
                and portfolios./ ภาพถ่ายและวีดีโอจะใช้สำหรับการแสดงในชั้นเรียน
                /โรงเรียนและแฟ้มผลงาน
              </li>
              <br></br>
              <li>
                Photographs and video may be used for promotional purposes such
                as brochures or print advertising. /
                อาจใช้ภาพถ่ายและวีดีโอเพื่อวัตถุประสงค์ในการส่งเสริมการขายเช่น
                โบรชัวร์หรือโฆษณาสิ่งพิมพ์
              </li>
              <br></br>
              <li>
                Photographs and video may be displayed on the school website. /
                รูปภาพและวิดีโออาจแสดงบนเว็บไซต์ของโรงเรียน
              </li>
              <br></br>
              <li>
                Photographs and video may be displayed on other school marketing
                users. / รูปภาพและวิดีโออาจปรากฎบนการใช้การตลาดของโรงเรียนอื่นๆ
              </li>
              <br></br>
            </ul>
          </Step.Content>
          <Grid columns={2}>
            <Grid.Row>
              <Grid.Column width={1}>
                <Field name="pdpa" required={true} component={renderCheckbox} />
              </Grid.Column>
              <Grid.Column>I Grant / ข้าพเจ้ายินยอม</Grid.Column>
            </Grid.Row>
          </Grid>
          <br></br>
          <Button
            floated="left"
            content="Submit"
            style={{
              backgroundColor: config.colorTheme,
              color: "#ffff",
              minWidth: "5%",
            }}
            loading={loading}
            //   onClick={() => showPopup()}
          />
        </Form>
        <br></br>
        <br></br>

        {/* <ModalBasic open={open} size="big" close={popUpClose}>
          <Header as="h2" style={{ textAlign: "center" }}>
            บัททึกข้อมูลเรียบร้อย
          </Header>
          <center>
            <Button
              content="OK"
              style={{
                backgroundColor: config.colorTheme,
                color: "#ffffff",
                minWidth: "5%",
              }}
              onClick={() => popUpClose()}
            />
          </center>
        </ModalBasic> */}
      </div>

      {/* 
      {!visitData && (
        <>
          <p style={{ color: "red", textAlign: "center" }}>
            คุณยังไม่ได้ทำการลงทะเบียนเยี่ยมชมโรงเรียน
          </p>
          <p style={{ textAlign: "center" }}>
            Click Link เพื่อลงทะเบียน:
            <a href="https://kidzweb-public-dot-kidz-village.as.r.appspot.com/xxx/visitrequest">
              https://kidzweb-public-dot-kidz-village.as.r.appspot.com/xxx/visitrequest
            </a>
          </p>
        </>
      )} */}
    </>
  );
};

PDPA = reduxForm({
  // a unique name for the form
  form: "PDPA",
  validate,
  enableReinitialize: true,
})(PDPA);

// const selector = formValueSelector("class");
// PDPA = connect((state) => {
//   const start_card = selector(state, "start_card");
//   const end_card = selector(state, "end_card");
//   return {
//     start_card: start_card,
//     end_card: end_card,
//   };
// })(PDPA);

export default withRouter(PDPA);
