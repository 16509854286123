import axios from "axios";
import * as config from "../../../config";

export function postVisitRequest(data) {
    return axios
        .post(config.urlAPI + "/addvisitrequest", data)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            return err.response;
        });
}

export function getdatavisitrequest() {
    return axios
        .get(config.urlAPI + "/getdatavisitrequest", config.headerCallAPI)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            return err.response;
        });
}

export function updatevisitprofile(data) {
    return axios
        .post(config.urlAPI + "/updatevisitprofile/" + data.Id, data, config.headerCallAPI)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            return err.response;
        });
}

export function getvisitProfile(Id) {
    return axios
        .get(config.urlAPI + "/visit/" + Id, config.headerCallAPI)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            return err.response;
        });
}

export function addapplicationstudent(data) {
    return axios
        .post(config.urlAPI + "/addapplicationstudent", data)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            return err.response;
        });
}

export function updateapplicationstudent(data) {
    return axios
        .post(config.urlAPI + "/updateapplicationstudent", data)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            return err.response;
        });
}

export function updatesibling1(data) {
    return axios
        .post(config.urlAPI + "/updatesibling1/"+ data.id, data)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            return err.response;
        });
}

export function updatesibling2(data) {
    return axios
        .post(config.urlAPI + "/updatesibling2/"+ data.id, data)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            return err.response;
        });
}

export function addsibling(data) {
    return axios
        .post(config.urlAPI + "/addsibling", data)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            return err.response;
        });
}

export function addsibling2(data) {
    return axios
        .post(config.urlAPI + "/addsibling2", data)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            return err.response;
        });
}

export function getSibling1(sibling1) {
    return axios
        .get(config.urlAPI + "/getsibling1/" + sibling1)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            return err.response;
        });
}

export function getSibling2(sibling2) {
    return axios
        .get(config.urlAPI + "/getsibling2/" + sibling2)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            return err.response;
        });
}

export function updateapplicationstudentdetail(data) {
    return axios
        .post(config.urlAPI + "/updateapplicationstudentdetail/" + data.visit_id, data, config.headerCallAPI)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            return err.response;
        });
}

export function getstudentdetailProfile(Id) {
    return axios
        .get(config.urlAPI + "/getstudentdetailProfile/" + Id, config.headerCallAPI)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            return err.response;
        });
}

export function getfather(student_Id) {
    return axios
        .get(config.urlAPI + "/getfather/" + student_Id)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            return err.response;
        });
}

export function updatefather(data) {
    return axios
        .post(config.urlAPI + "/updatefather", data)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            return err.response;
        });
}

export function addfather(data) {
    return axios
        .post(config.urlAPI + "/addfather", data)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            return err.response;
        });
}

export function getmother(student_Id) {
    return axios
        .get(config.urlAPI + "/getmother/" + student_Id)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            return err.response;
        });
}

export function updatemother(data) {
    return axios
        .post(config.urlAPI + "/updatemother", data)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            return err.response;
        });
}

export function addmother(data) {
    return axios
        .post(config.urlAPI + "/addmother", data)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            return err.response;
        });
}

export function getemergency(data) {
    return axios
        .get(config.urlAPI + "/getemergency/" + data)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            return err.response;
        });   
}

export function updateemergency(data) {
    return axios
        .post(config.urlAPI + "/updateemergency", data)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            return err.response;
        });
}

export function getguardian1(id) {
    return axios
        .get(config.urlAPI + "/getguardian1/" + id)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            return err.response;
        });
}

export function getguardian2(id) {
    return axios
        .get(config.urlAPI + "/getguardian2/" + id)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            return err.response;
        });
}

export function updateguardian1(data) {
    return axios
        .post(config.urlAPI + "/updateguardian1", data)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            return err.response;
        });
}

export function updateguardian2(data) {
    return axios
        .post(config.urlAPI + "/updateguardian2", data)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            return err.response;
        });
}

export function addguardian1(data) {
    return axios
        .post(config.urlAPI + "/addguardian1", data)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            return err.response;
        });
}

export function addguardian2(data) {
    return axios
        .post(config.urlAPI + "/addguardian2", data)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            return err.response;
        });
}

export function getvaccine(id) {
    return axios
        .get(config.urlAPI + "/getvaccine/"+id)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            return err.response;
        });
}

export function addhealth(data) {
    return axios
        .post(config.urlAPI + "/addhealth", data)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            return err.response;
        });
}

export function getimmunization(id) {
    return axios
        .get(config.urlAPI + "/getimmunization/"+id)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            return err.response;
        });
}

export function getdocument(visit_id) {
    return axios
        .get(config.urlAPI + "/getdocument/"+visit_id)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            return err.response;
        });
}

export function updatedoctype1(data, student_id) {
    return axios
        .post(
            config.urlAPI + "/doctype1/upload/" + student_id,
            data,
            config.headerCallAPI
        )
        .then((res) => {
            return res;
        })
        .catch((err) => {
            return err.response;
        });
}

export function updatedoctype2(data, student_id) {
    return axios
        .post(
            config.urlAPI + "/doctype2/upload/" + student_id,
            data,
            config.headerCallAPI
        )
        .then((res) => {
            return res;
        })
        .catch((err) => {
            return err.response;
        });
}

export function updatedoctype3(data, student_id) {
    return axios
        .post(
            config.urlAPI + "/doctype3/upload/" + student_id,
            data,
            config.headerCallAPI
        )
        .then((res) => {
            return res;
        })
        .catch((err) => {
            return err.response;
        });
}

export function updatedoctype4(data, student_id) {
    return axios
        .post(
            config.urlAPI + "/doctype4/upload/" + student_id,
            data,
            config.headerCallAPI
        )
        .then((res) => {
            return res;
        })
        .catch((err) => {
            return err.response;
        });
}

export function updatedoctype5(data, student_id) {
    return axios
        .post(
            config.urlAPI + "/doctype5/upload/" + student_id,
            data,
            config.headerCallAPI
        )
        .then((res) => {
            return res;
        })
        .catch((err) => {
            return err.response;
        });
}

export function updatedoctype6(data, student_id) {
    return axios
        .post(
            config.urlAPI + "/doctype6/upload/" + student_id,
            data,
            config.headerCallAPI
        )
        .then((res) => {
            return res;
        })
        .catch((err) => {
            return err.response;
        });
}

export function updatedoctype7(data, student_id) {
    return axios
        .post(
            config.urlAPI + "/doctype7/upload/" + student_id,
            data,
            config.headerCallAPI
        )
        .then((res) => {
            return res;
        })
        .catch((err) => {
            return err.response;
        });
}

export function getinterview(id) {
    return axios
        .get(config.urlAPI + "/getinterview/"+id)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            return err.response;
        });
}
