// import React from "react";
import React, { useState, useEffect } from "react";
import { Field, reduxForm, formValueSelector } from "redux-form";
import renderField from "../../../components/renderField";
import renderSelectField from "../../../components/renderSelectField";
import renderFileField from "../../../components/renderFileField";
import renderDateTimeField from "../../../components/renderDateTimeField";
import renderDateOnlyField from "../../../components/renderDateOnlyField";
import { Button, Form, Message, Grid } from "semantic-ui-react";
import SecondButton from "../../../components/secondbutton";
import * as config from "../../../config";
import { connect } from "react-redux";
import {
  Header,
  Image,
  Segment,
  Breadcrumb,
  Loader,
  Dimmer,
  Tab,
  Table,
  Icon,
  Label,
  FormGroup,
} from "semantic-ui-react";
import renderDateField from "../../../components/renderDateOnlyField";
import { withRouter } from "react-router-dom";
import ModalBasic from "../../../components/modal";

const validate = (values) => {
  const errors = {};
  if (!values.idnumber) errors.idnumber = true;
  if (!values.dateOfBirth) errors.dateOfBirth = true;
  if (!values.firstName) errors.firstName = true;
  if (!values.lastName) errors.lastName = true;
  if (!values.phone) errors.phone = true;
  if (!values.relationship) errors.relationship = true;
  return errors;
};

let FormGuardian2 = (props) => {
  const {
    loading,
    pristine,
    submitting,
    onSubmit,
    handleSubmit,
    initialValue,
    initialValues2,
    onClose,
    actMessage,
    errormsg,
  } = props;

  //   const [open, setOpen] = useState(false);

  //   const popUpOpen = () => {
  //     setOpen(true);
  //   };

  //   const popUpClose = () => {
  //     setOpen(false);
  //     window.location.reload();
  //     window.close();
  //   };
  // console.log(props)

  return (
    <Segment>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div style={{ paddingLeft: "20px" }}>
          <Grid stackable>
            <Grid.Row>
              <Grid.Column>
                <Header as="h4">Guardian 2</Header>
                <Form.Group>
                  <Field
                    // width="5"
                    name="idnumber"
                    type="text"
                    required={true}
                    component={renderField}
                    label="Passport or ID No."
                  />
                  <Field
                    // width="3"
                    name="dateOfBirth"
                    required={true}
                    component={renderDateOnlyField}
                    label="Date Of Birth"
                  />
                </Form.Group>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <Form.Group unstackable>
                  <Field
                    // width="3"
                    name="firstName"
                    type="text"
                    required={true}
                    component={renderField}
                    label="firstname"
                  />
                  <Field
                    // width="3"
                    name="lastName"
                    type="text"
                    required={true}
                    component={renderField}
                    label="lastname"
                  />
                  <Field
                    // width="3"
                    name="nationality"
                    type="text"
                    required={false}
                    component={renderSelectField}
                    options={config.options_nationality}
                    label="Nationality"
                  />
                  <Field
                    // width="3"
                    name="nativelanguage"
                    type="text"
                    required={false}
                    component={renderField}
                    label="Native Language"
                  />
                </Form.Group>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <Form.Group unstackable>
                  <Field
                    // width="3"
                    name="firstName_th"
                    type="text"
                    required={false}
                    component={renderField}
                    label="ชื่อ"
                  />
                  <Field
                    // width="3"
                    name="lastName_th"
                    type="text"
                    required={false}
                    component={renderField}
                    label="นามสกุล"
                  />
                  <Field
                    // width="3"
                    name="phone"
                    type="number"
                    required={true}
                    component={renderField}
                    label="Mobile"
                  />
                  <Field
                    // width="3"
                    name="email"
                    type="text"
                    required={false}
                    component={renderField}
                    label="E-mail"
                  />
                </Form.Group>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <Form.Group unstackable>
                  <Field
                    // width="6"
                    name="placeOfWork"
                    type="text"
                    required={false}
                    component={renderField}
                    label="Place of work"
                  />
                  <Field
                    // width="3"
                    name="position"
                    type="text"
                    required={false}
                    component={renderField}
                    label="Position"
                  />
                  <Field
                    // width="3"
                    name="officePhone"
                    type="text"
                    required={false}
                    component={renderField}
                    label="Office Tel"
                  />
                </Form.Group>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={8}>
                <Form.Group unstackable>
                  <Field
                    // width="6"
                    name="relationship"
                    type="text"
                    required={true}
                    component={renderSelectField}
                    label="Relationship"
                    options={config.options_relationship}
                  />
                </Form.Group>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <br />
          {/* Success message */}
          {actMessage && (
            <Message positive>
              <Message.Content>{actMessage}</Message.Content>
            </Message>
          )}
          {/* Error message */}
          {errormsg && (
            <Message negative>
              <Message.Content>{errormsg}</Message.Content>
            </Message>
          )}

          <br />
          <Button
            floated="left"
            content="Save"
            style={{
              backgroundColor: config.colorTheme,
              color: "#ffffff",
              minWidth: "30%",
            }}
            loading={loading}
            disabled={pristine || submitting}
            // onClick={() => popUpOpen()}
          />
          <br></br>
          <br></br>
          <br></br>
        </div>
      </Form>

      {/* <ModalBasic open={open} size="big" close={popUpClose}>
        <Header as="h2" style={{ textAlign: "center" }}>
          ทำการบันทึกข้อมูลแล้ว
        </Header>
        <center>
          <Button
            content="OK"
            style={{
              backgroundColor: config.colorTheme,
              color: "#ffffff",
              minWidth: "5%",
            }}
            onClick={() => popUpClose()}
          />
        </center>
      </ModalBasic> */}
    </Segment>
  );
};

FormGuardian2 = reduxForm({
  // a unique name for the form
  form: "FormGuardian2",
  validate,
  enableReinitialize: true,
})(FormGuardian2);

// const selector = formValueSelector("class");
// FormGuardian2 = connect((state) => {
//   const start_card = selector(state, "start_card");
//   const end_card = selector(state, "end_card");
//   return {
//     start_card: start_card,
//     end_card: end_card,
//   };
// })(FormGuardian2);

export default withRouter(FormGuardian2);
