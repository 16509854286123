import React, { useState, useEffect } from "react";
import { Field, reduxForm, formValueSelector } from "redux-form";
import renderField from "../../../components/renderField";
import renderSelectField from "../../../components/renderSelectField";
import renderFileField from "../../../components/renderFileField";
import renderDateTimeField from "../../../components/renderDateTimeField";
import renderDateOnlyField from "../../../components/renderDateOnlyField";
import renderCheckboxGroupField from "../../../components/renderCheckboxGroupField";
import renderCheckbox from "../../../components/renderCheckboxField";
import { Button, Form, Message, Grid, Card } from "semantic-ui-react";
import SecondButton from "../../../components/secondbutton";
import * as config from "../../../config";
import * as config2 from "../../../components/config";
import { connect } from "react-redux";
import {
  Header,
  Image,
  Segment,
  Breadcrumb,
  Loader,
  Dimmer,
  Tab,
  Table,
  Icon,
  Label,
  FormGroup,
} from "semantic-ui-react";
import renderDateField from "../../../components/renderDateOnlyField";
import { withRouter } from "react-router-dom";
import Wrap from "../../../components/wrap";
import ModalBasic from "../../../components/modal";

const validate = (values) => {
  const errors = {};
  if (!values.information_care) errors.information_care = true;
  return errors;
};

let FormHealth = (props) => {
  // console.log(props.dataVaccine);

  const {
    loading,
    pristine,
    submitting,
    onSubmit,
    handleSubmit,
    initialValue,
    onClose,
    errormsg,
    start_card,
    end_card,
    dataVaccine,
  } = props;
  //   const [open, setOpen] = useState(false);

  //   const showPopup = () => {
  //     setOpen(true);
  //   };
  //   const popUpClose = () => {
  //     setOpen(false);
  //     window.location.reload();
  //     window.close();
  //   };

  return (
    <Segment basic textAlign="left" style={{ backgroundColor: "#eee" }}>
      <Segment>
        <Image
          src="../storage/imgs/KV-logo.png"
          size="tiny"
          spaced
          floated="right"
        />

        <Form onSubmit={handleSubmit(onSubmit)}>
          <Header as="h4">
            <u>Part IV</u> : Health and Medical Record
          </Header>
          <div style={{ paddingLeft: "20px" }}>
            <Grid stackable>
              <br></br>
              <Grid.Row>
                <Grid.Column width={6}>
                  <Header as="h4">
                    Health information and special medical care
                  </Header>
                  information_care<span style={{ color: "red" }}>*</span>
                  <Field
                    // width="3"
                    component={renderSelectField}
                    required={true}
                    name="information_care"
                    options={[
                      { key: "Normal", text: "Normal", value: "Normal" },
                      {
                        key: "Need special care",
                        text: "Need special care",
                        value: "Need special care",
                      },
                    ]}
                  />
                </Grid.Column>
              </Grid.Row>

              <Grid.Row>
                <Grid.Column>
                  <Header as="h4">
                    ประวัติการรับวัคซีน (CERTIFICATE of IMMUIZATION)
                  </Header>
                  <Table
                    celled
                    padded
                    style={{ borderRadius: 0, fontSize: "95%" }}
                  >
                    <Table.Header
                      style={{
                        boxShadow: "0 0.5px 5px 0 rgb(0 0 0 / 15%)",
                        textAlign: "center",
                      }}
                    >
                      <Table.Row>
                        <Table.HeaderCell
                          style={{
                            borderRadius: 0,
                            backgroundColor: "#ddd",
                            paddingTop: "5px",
                            paddingBottom: "5px",
                            width: "30%",
                          }}
                        >
                          IMMUIZATION
                        </Table.HeaderCell>
                        <Table.HeaderCell
                          style={{
                            borderRadius: 0,
                            backgroundColor: "#ddd",
                            paddingTop: "5px",
                            paddingBottom: "5px",
                            width: "70%",
                          }}
                        >
                          RECECORD COMPLETE DATES OF VACCINE DOSES GIVEN
                        </Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      <Table.Row style={{ textAlign: "center" }}>
                        <Table.HeaderCell
                          style={{
                            borderRadius: 0,
                            backgroundColor: "#ddd",
                            paddingTop: "5px",
                            paddingBottom: "5px",
                          }}
                        ></Table.HeaderCell>
                        <Table.HeaderCell
                          style={{
                            borderRadius: 0,
                            backgroundColor: "#ddd",
                            paddingTop: "5px",
                            paddingBottom: "5px",
                          }}
                        >
                          <Table style={{ textAlign: "center" }}>
                            <Table.HeaderCell
                              style={{
                                borderRadius: 0,
                                backgroundColor: "#ddd",
                                paddingTop: "5px",
                                paddingBottom: "5px",
                                width: "20%",
                              }}
                            >
                              1
                            </Table.HeaderCell>
                            <Table.HeaderCell
                              style={{
                                borderRadius: 0,
                                backgroundColor: "#ddd",
                                paddingTop: "5px",
                                paddingBottom: "5px",
                                width: "20%",
                              }}
                            >
                              2
                            </Table.HeaderCell>
                            <Table.HeaderCell
                              style={{
                                borderRadius: 0,
                                backgroundColor: "#ddd",
                                paddingTop: "5px",
                                paddingBottom: "5px",
                                width: "20%",
                              }}
                            >
                              3
                            </Table.HeaderCell>
                            <Table.HeaderCell
                              style={{
                                borderRadius: 0,
                                backgroundColor: "#ddd",
                                paddingTop: "5px",
                                paddingBottom: "5px",
                                width: "20%",
                              }}
                            >
                              4
                            </Table.HeaderCell>
                            <Table.HeaderCell
                              style={{
                                borderRadius: 0,
                                backgroundColor: "#ddd",
                                paddingTop: "5px",
                                paddingBottom: "5px",
                                width: "20%",
                              }}
                            >
                              5
                            </Table.HeaderCell>
                          </Table>
                        </Table.HeaderCell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell
                          style={{
                            borderRadius: 0,
                            backgroundColor: "#eee",
                            paddingTop: "5px",
                            paddingBottom: "5px",
                            width: "20%",
                          }}
                        >
                          1.Diphtheria Tetanus Pertussis(DTP)
                        </Table.Cell>
                        <Table.Cell>
                          <Table style={{ textAlign: "center" }}>
                            <Table.Cell style={{ width: "20%" }}>
                              <Field
                                name="DTP1"
                                required={false}
                                component={renderDateOnlyField}
                                label="Date"
                              />
                            </Table.Cell>
                            <Table.Cell style={{ width: "20%" }}>
                              <Field
                                name="DTP2"
                                required={false}
                                component={renderDateOnlyField}
                                label="Date"
                              />
                            </Table.Cell>
                            <Table.Cell style={{ width: "20%" }}>
                              <Field
                                name="DTP3"
                                required={false}
                                component={renderDateOnlyField}
                                label="Date"
                              />
                            </Table.Cell>
                            <Table.Cell style={{ width: "20%" }}>
                              <Field
                                name="DTP4"
                                required={false}
                                component={renderDateOnlyField}
                                label="Date"
                              />
                            </Table.Cell>
                            <Table.Cell style={{ width: "20%" }}>
                              <Field
                                name="DTP5"
                                required={false}
                                component={renderDateOnlyField}
                                label="Date"
                              />
                            </Table.Cell>
                          </Table>
                        </Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell
                          style={{
                            borderRadius: 0,
                            backgroundColor: "#eee",
                            paddingTop: "5px",
                            paddingBottom: "5px",
                            width: "20%",
                          }}
                        >
                          2.Diphtheria Tetanus(Td)
                        </Table.Cell>
                        <Table.Cell>
                          <Table style={{ textAlign: "center" }}>
                            <Table.Cell style={{ width: "20%" }}>
                              <Field
                                name="Td1"
                                required={false}
                                label="Date"
                                component={renderDateOnlyField}
                              />
                            </Table.Cell>
                            <Table.Cell style={{ width: "20%" }}>
                              <Field
                                name="Td2"
                                required={false}
                                label="Date"
                                component={renderDateOnlyField}
                              />
                            </Table.Cell>
                            <Table.Cell style={{ width: "20%" }}>
                              <Field
                                name="Td3"
                                required={false}
                                label="Date"
                                component={renderDateOnlyField}
                              />
                            </Table.Cell>
                            <Table.Cell style={{ width: "20%" }}>
                              <Field
                                name="Td4"
                                required={false}
                                label="Date"
                                component={renderDateOnlyField}
                              />
                            </Table.Cell>
                            <Table.Cell style={{ width: "20%" }}>
                              <Field
                                name="Td5"
                                required={false}
                                label="Date"
                                component={renderDateOnlyField}
                              />
                            </Table.Cell>
                          </Table>
                        </Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell
                          style={{
                            borderRadius: 0,
                            backgroundColor: "#eee",
                            paddingTop: "5px",
                            paddingBottom: "5px",
                            width: "20%",
                          }}
                        >
                          3.Poliomyelitis(OPV)
                        </Table.Cell>
                        <Table.Cell>
                          <Table style={{ textAlign: "center" }}>
                            <Table.Cell style={{ width: "20%" }}>
                              <Field
                                name="OPV1"
                                required={false}
                                label="Date"
                                component={renderDateOnlyField}
                              />
                            </Table.Cell>
                            <Table.Cell style={{ width: "20%" }}>
                              <Field
                                name="OPV2"
                                required={false}
                                label="Date"
                                component={renderDateOnlyField}
                              />
                            </Table.Cell>
                            <Table.Cell style={{ width: "20%" }}>
                              <Field
                                name="OPV3"
                                required={false}
                                label="Date"
                                component={renderDateOnlyField}
                              />
                            </Table.Cell>
                            <Table.Cell style={{ width: "20%" }}>
                              <Field
                                name="OPV4"
                                required={false}
                                label="Date"
                                component={renderDateOnlyField}
                              />
                            </Table.Cell>
                            <Table.Cell style={{ width: "20%" }}>
                              <Field
                                name="OPV5"
                                required={false}
                                label="Date"
                                component={renderDateOnlyField}
                              />
                            </Table.Cell>
                          </Table>
                        </Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell
                          style={{
                            borderRadius: 0,
                            backgroundColor: "#eee",
                            paddingTop: "5px",
                            paddingBottom: "5px",
                            width: "20%",
                          }}
                        >
                          4.Measles Booster
                        </Table.Cell>
                        <Table.Cell>
                          <Table style={{ textAlign: "center" }}>
                            <Table.Cell style={{ width: "20%" }}>
                              <Field
                                name="Measles_Booster1"
                                required={false}
                                label="Date"
                                component={renderDateOnlyField}
                              />
                            </Table.Cell>
                            <Table.Cell style={{ width: "20%" }}>
                              <Field
                                name="Measles_Booster2"
                                required={false}
                                label="Date"
                                component={renderDateOnlyField}
                              />
                            </Table.Cell>
                            <Table.Cell
                              style={{ width: "20%", backgroundColor: "#eee" }}
                            ></Table.Cell>
                            <Table.Cell
                              style={{ width: "20%", backgroundColor: "#eee" }}
                            ></Table.Cell>
                            <Table.Cell
                              style={{ width: "20%", backgroundColor: "#eee" }}
                            ></Table.Cell>
                          </Table>
                        </Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell
                          style={{
                            borderRadius: 0,
                            backgroundColor: "#eee",
                            paddingTop: "5px",
                            paddingBottom: "5px",
                            width: "20%",
                          }}
                        >
                          5.Rubella
                        </Table.Cell>
                        <Table.Cell>
                          <Table style={{ textAlign: "center" }}>
                            <Table.Cell style={{ width: "20%" }}>
                              <Field
                                name="Rubella"
                                required={false}
                                label="Date"
                                component={renderDateOnlyField}
                              />
                            </Table.Cell>
                            <Table.Cell
                              style={{ width: "20%", backgroundColor: "#eee" }}
                            ></Table.Cell>
                            <Table.Cell
                              style={{ width: "20%", backgroundColor: "#eee" }}
                            ></Table.Cell>
                            <Table.Cell
                              style={{ width: "20%", backgroundColor: "#eee" }}
                            ></Table.Cell>
                            <Table.Cell
                              style={{ width: "20%", backgroundColor: "#eee" }}
                            ></Table.Cell>
                          </Table>
                        </Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell
                          style={{
                            borderRadius: 0,
                            backgroundColor: "#eee",
                            paddingTop: "5px",
                            paddingBottom: "5px",
                            width: "20%",
                          }}
                        >
                          6.Mumps
                        </Table.Cell>
                        <Table.Cell>
                          <Table style={{ textAlign: "center" }}>
                            <Table.Cell style={{ width: "20%" }}>
                              <Field
                                name="vaccineMumps"
                                required={false}
                                label="Date"
                                component={renderDateOnlyField}
                              />
                            </Table.Cell>
                            <Table.Cell
                              style={{ width: "20%", backgroundColor: "#eee" }}
                            ></Table.Cell>
                            <Table.Cell
                              style={{ width: "20%", backgroundColor: "#eee" }}
                            ></Table.Cell>
                            <Table.Cell
                              style={{ width: "20%", backgroundColor: "#eee" }}
                            ></Table.Cell>
                            <Table.Cell
                              style={{ width: "20%", backgroundColor: "#eee" }}
                            ></Table.Cell>
                          </Table>
                        </Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell
                          style={{
                            borderRadius: 0,
                            backgroundColor: "#eee",
                            paddingTop: "5px",
                            paddingBottom: "5px",
                            width: "20%",
                          }}
                        >
                          7.Measles,Mumps,Rubella(MMR)
                        </Table.Cell>
                        <Table.Cell>
                          <Table style={{ textAlign: "center" }}>
                            <Table.Cell style={{ width: "20%" }}>
                              <Field
                                name="MMR"
                                required={false}
                                label="Date"
                                component={renderDateOnlyField}
                              />
                            </Table.Cell>
                            <Table.Cell
                              style={{ width: "20%", backgroundColor: "#eee" }}
                            ></Table.Cell>
                            <Table.Cell
                              style={{ width: "20%", backgroundColor: "#eee" }}
                            ></Table.Cell>
                            <Table.Cell
                              style={{ width: "20%", backgroundColor: "#eee" }}
                            ></Table.Cell>
                            <Table.Cell
                              style={{ width: "20%", backgroundColor: "#eee" }}
                            ></Table.Cell>
                          </Table>
                        </Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </Grid.Column>
              </Grid.Row>

              <Grid.Row>
                <Grid.Column>
                  <u>ข้อมูลเกี่ยวกับสุขภาพ</u>
                  <p>Please check / any that this child has had</p>
                  <Table
                    celled
                    padded
                    style={{ borderRadius: 0, fontSize: "90%", width: "80%" }}
                  >
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell
                          style={{
                            borderRadius: 0,
                            backgroundColor: "#ddd",
                            paddingTop: "5px",
                            paddingBottom: "5px",
                          }}
                        >
                          Conditions
                        </Table.HeaderCell>
                        <Table.HeaderCell
                          style={{
                            borderRadius: 0,
                            backgroundColor: "#ddd",
                            paddingTop: "5px",
                            paddingBottom: "5px",
                            textAlign: "center",
                          }}
                        >
                          Yes
                        </Table.HeaderCell>
                        <Table.HeaderCell
                          style={{
                            borderRadius: 0,
                            backgroundColor: "#ddd",
                            paddingTop: "5px",
                            paddingBottom: "5px",
                          }}
                        >
                          Conditions
                        </Table.HeaderCell>
                        <Table.HeaderCell
                          style={{
                            borderRadius: 0,
                            backgroundColor: "#ddd",
                            paddingTop: "5px",
                            paddingBottom: "5px",
                            textAlign: "center",
                          }}
                        >
                          Yes
                        </Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      <Table.Row>
                        <Table.Cell
                          style={{
                            borderRadius: 0,
                            paddingTop: "5px",
                            paddingBottom: "5px",
                            width: "30%",
                          }}
                        >
                          1.Abnormal spinal curvature (scoliosis, etc.)
                        </Table.Cell>
                        <Table.Cell
                          style={{
                            borderRadius: 0,
                            paddingTop: "5px",
                            paddingBottom: "5px",
                            width: "10%",
                            textAlign: "center",
                          }}
                        >
                          <Field
                            name="Abnormal_spinal"
                            required={false}
                            component={renderCheckbox}
                          />
                        </Table.Cell>
                        <Table.Cell
                          style={{
                            borderRadius: 0,
                            paddingTop: "5px",
                            paddingBottom: "5px",
                            width: "30%",
                          }}
                        >
                          19.Hepatitis
                        </Table.Cell>
                        <Table.Cell
                          style={{
                            borderRadius: 0,
                            paddingTop: "5px",
                            paddingBottom: "5px",
                            width: "10%",
                            textAlign: "center",
                          }}
                        >
                          <Field
                            name="Hepatitis"
                            required={false}
                            component={renderCheckbox}
                          />
                        </Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell
                          style={{
                            borderRadius: 0,
                            paddingTop: "5px",
                            paddingBottom: "5px",
                            width: "30%",
                          }}
                        >
                          2.Anemia
                        </Table.Cell>
                        <Table.Cell
                          style={{
                            borderRadius: 0,
                            paddingTop: "5px",
                            paddingBottom: "5px",
                            width: "10%",
                            textAlign: "center",
                          }}
                        >
                          <Field
                            name="Anemia"
                            required={false}
                            component={renderCheckbox}
                          />
                        </Table.Cell>
                        <Table.Cell
                          style={{
                            borderRadius: 0,
                            paddingTop: "5px",
                            paddingBottom: "5px",
                            width: "30%",
                          }}
                        >
                          20.Measles (old fashioned or "ten day")
                        </Table.Cell>
                        <Table.Cell
                          style={{
                            borderRadius: 0,
                            paddingTop: "5px",
                            paddingBottom: "5px",
                            width: "10%",
                            textAlign: "center",
                          }}
                        >
                          <Field
                            name="Measles"
                            required={false}
                            component={renderCheckbox}
                          />
                        </Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell
                          style={{
                            borderRadius: 0,
                            paddingTop: "5px",
                            paddingBottom: "5px",
                            width: "30%",
                          }}
                        >
                          3.Asthma or wheezing
                        </Table.Cell>
                        <Table.Cell
                          style={{
                            borderRadius: 0,
                            paddingTop: "5px",
                            paddingBottom: "5px",
                            width: "10%",
                            textAlign: "center",
                          }}
                        >
                          <Field
                            name="Asthma_or_wheezing"
                            required={false}
                            component={renderCheckbox}
                          />
                        </Table.Cell>
                        <Table.Cell
                          style={{
                            borderRadius: 0,
                            paddingTop: "5px",
                            paddingBottom: "5px",
                            width: "30%",
                          }}
                        >
                          21.Meninggitis or encephalitis
                        </Table.Cell>
                        <Table.Cell
                          style={{
                            borderRadius: 0,
                            paddingTop: "5px",
                            paddingBottom: "5px",
                            width: "10%",
                            textAlign: "center",
                          }}
                        >
                          <Field
                            name="Meninggitis"
                            required={false}
                            component={renderCheckbox}
                          />
                        </Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell
                          style={{
                            borderRadius: 0,
                            paddingTop: "5px",
                            paddingBottom: "5px",
                            width: "30%",
                          }}
                        >
                          4.Bedwetting at night
                        </Table.Cell>
                        <Table.Cell
                          style={{
                            borderRadius: 0,
                            paddingTop: "5px",
                            paddingBottom: "5px",
                            width: "10%",
                            textAlign: "center",
                          }}
                        >
                          <Field
                            name="Bedwetting_at_night"
                            required={false}
                            component={renderCheckbox}
                          />
                        </Table.Cell>
                        <Table.Cell
                          style={{
                            borderRadius: 0,
                            paddingTop: "5px",
                            paddingBottom: "5px",
                            width: "30%",
                          }}
                        >
                          22.Multiple ear infections (3 or more)
                        </Table.Cell>
                        <Table.Cell
                          style={{
                            borderRadius: 0,
                            paddingTop: "5px",
                            paddingBottom: "5px",
                            width: "10%",
                            textAlign: "center",
                          }}
                        >
                          <Field
                            name="Multiple_ear"
                            required={false}
                            component={renderCheckbox}
                          />
                        </Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell
                          style={{
                            borderRadius: 0,
                            paddingTop: "5px",
                            paddingBottom: "5px",
                            width: "30%",
                          }}
                        >
                          5.Behavior problem
                        </Table.Cell>
                        <Table.Cell
                          style={{
                            borderRadius: 0,
                            paddingTop: "5px",
                            paddingBottom: "5px",
                            width: "10%",
                            textAlign: "center",
                          }}
                        >
                          <Field
                            name="Behavior_problem"
                            required={false}
                            component={renderCheckbox}
                          />
                        </Table.Cell>
                        <Table.Cell
                          style={{
                            borderRadius: 0,
                            paddingTop: "5px",
                            paddingBottom: "5px",
                            width: "30%",
                          }}
                        >
                          23.Wetting during day
                        </Table.Cell>
                        <Table.Cell
                          style={{
                            borderRadius: 0,
                            paddingTop: "5px",
                            paddingBottom: "5px",
                            width: "10%",
                            textAlign: "center",
                          }}
                        >
                          <Field
                            name="Wetting_during_day"
                            required={false}
                            component={renderCheckbox}
                          />
                        </Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell
                          style={{
                            borderRadius: 0,
                            paddingTop: "5px",
                            paddingBottom: "5px",
                            width: "30%",
                          }}
                        >
                          6.Near-drowning or near-suffocation
                        </Table.Cell>
                        <Table.Cell
                          style={{
                            borderRadius: 0,
                            paddingTop: "5px",
                            paddingBottom: "5px",
                            width: "10%",
                            textAlign: "center",
                          }}
                        >
                          <Field
                            name="Neardrowning"
                            required={false}
                            component={renderCheckbox}
                          />
                        </Table.Cell>
                        <Table.Cell
                          style={{
                            borderRadius: 0,
                            paddingTop: "5px",
                            paddingBottom: "5px",
                            width: "30%",
                          }}
                        >
                          24.Urinary tract infections
                        </Table.Cell>
                        <Table.Cell
                          style={{
                            borderRadius: 0,
                            paddingTop: "5px",
                            paddingBottom: "5px",
                            width: "10%",
                            textAlign: "center",
                          }}
                        >
                          <Field
                            name="Urinary_tract_infections"
                            required={false}
                            component={renderCheckbox}
                          />
                        </Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell
                          style={{
                            borderRadius: 0,
                            paddingTop: "5px",
                            paddingBottom: "5px",
                            width: "30%",
                          }}
                        >
                          7.Nervous twitches or tics
                        </Table.Cell>
                        <Table.Cell
                          style={{
                            borderRadius: 0,
                            paddingTop: "5px",
                            paddingBottom: "5px",
                            width: "10%",
                            textAlign: "center",
                          }}
                        >
                          <Field
                            name="Nervous_twitches"
                            required={false}
                            component={renderCheckbox}
                          />
                        </Table.Cell>
                        <Table.Cell
                          style={{
                            borderRadius: 0,
                            paddingTop: "5px",
                            paddingBottom: "5px",
                            width: "30%",
                          }}
                        >
                          25.Birth or congenital malformation
                        </Table.Cell>
                        <Table.Cell
                          style={{
                            borderRadius: 0,
                            paddingTop: "5px",
                            paddingBottom: "5px",
                            width: "10%",
                            textAlign: "center",
                          }}
                        >
                          <Field
                            name="Birth_or_congenital"
                            required={false}
                            component={renderCheckbox}
                          />
                        </Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell
                          style={{
                            borderRadius: 0,
                            paddingTop: "5px",
                            paddingBottom: "5px",
                            width: "30%",
                          }}
                        >
                          8.Chronic diarrhea or constipation
                        </Table.Cell>
                        <Table.Cell
                          style={{
                            borderRadius: 0,
                            paddingTop: "5px",
                            paddingBottom: "5px",
                            width: "10%",
                            textAlign: "center",
                          }}
                        >
                          <Field
                            name="Chronic_diarrhea"
                            required={false}
                            component={renderCheckbox}
                          />
                        </Table.Cell>
                        <Table.Cell
                          style={{
                            borderRadius: 0,
                            paddingTop: "5px",
                            paddingBottom: "5px",
                            width: "30%",
                          }}
                        >
                          26.Chicken pox
                        </Table.Cell>
                        <Table.Cell
                          style={{
                            borderRadius: 0,
                            paddingTop: "5px",
                            paddingBottom: "5px",
                            width: "10%",
                            textAlign: "center",
                          }}
                        >
                          <Field
                            name="Chicken_pox"
                            required={false}
                            component={renderCheckbox}
                          />
                        </Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell
                          style={{
                            borderRadius: 0,
                            paddingTop: "5px",
                            paddingBottom: "5px",
                            width: "30%",
                          }}
                        >
                          9.Concern about relationship with sibling or friends
                        </Table.Cell>
                        <Table.Cell
                          style={{
                            borderRadius: 0,
                            paddingTop: "5px",
                            paddingBottom: "5px",
                            width: "10%",
                            textAlign: "center",
                          }}
                        >
                          <Field
                            name="Concern_about_relationship"
                            required={false}
                            component={renderCheckbox}
                          />
                        </Table.Cell>
                        <Table.Cell
                          style={{
                            borderRadius: 0,
                            paddingTop: "5px",
                            paddingBottom: "5px",
                            width: "30%",
                          }}
                        >
                          27.Diabetes
                        </Table.Cell>
                        <Table.Cell
                          style={{
                            borderRadius: 0,
                            paddingTop: "5px",
                            paddingBottom: "5px",
                            width: "10%",
                            textAlign: "center",
                          }}
                        >
                          <Field
                            name="Diabetes"
                            required={false}
                            component={renderCheckbox}
                          />
                        </Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell
                          style={{
                            borderRadius: 0,
                            paddingTop: "5px",
                            paddingBottom: "5px",
                            width: "30%",
                          }}
                        >
                          10.Cystic fibrosis
                        </Table.Cell>
                        <Table.Cell
                          style={{
                            borderRadius: 0,
                            paddingTop: "5px",
                            paddingBottom: "5px",
                            width: "10%",
                            textAlign: "center",
                          }}
                        >
                          <Field
                            name="Cystic_fibrosis"
                            required={false}
                            component={renderCheckbox}
                          />
                        </Table.Cell>
                        <Table.Cell
                          style={{
                            borderRadius: 0,
                            paddingTop: "5px",
                            paddingBottom: "5px",
                            width: "30%",
                          }}
                        >
                          28.Eczema
                        </Table.Cell>
                        <Table.Cell
                          style={{
                            borderRadius: 0,
                            paddingTop: "5px",
                            paddingBottom: "5px",
                            width: "10%",
                            textAlign: "center",
                          }}
                        >
                          <Field
                            name="Eczema"
                            required={false}
                            component={renderCheckbox}
                          />
                        </Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell
                          style={{
                            borderRadius: 0,
                            paddingTop: "5px",
                            paddingBottom: "5px",
                            width: "30%",
                          }}
                        >
                          11.Eye problems, poor vision
                        </Table.Cell>
                        <Table.Cell
                          style={{
                            borderRadius: 0,
                            paddingTop: "5px",
                            paddingBottom: "5px",
                            width: "10%",
                            textAlign: "center",
                          }}
                        >
                          <Field
                            name="Eye_problems"
                            required={false}
                            component={renderCheckbox}
                          />
                        </Table.Cell>
                        <Table.Cell
                          style={{
                            borderRadius: 0,
                            paddingTop: "5px",
                            paddingBottom: "5px",
                            width: "30%",
                          }}
                        >
                          29.Emotional Problems
                        </Table.Cell>
                        <Table.Cell
                          style={{
                            borderRadius: 0,
                            paddingTop: "5px",
                            paddingBottom: "5px",
                            width: "10%",
                            textAlign: "center",
                          }}
                        >
                          <Field
                            name="Emotional_Problems"
                            required={false}
                            component={renderCheckbox}
                          />
                        </Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell
                          style={{
                            borderRadius: 0,
                            paddingTop: "5px",
                            paddingBottom: "5px",
                            width: "30%",
                          }}
                        >
                          12.Frequent skin infections
                        </Table.Cell>
                        <Table.Cell
                          style={{
                            borderRadius: 0,
                            paddingTop: "5px",
                            paddingBottom: "5px",
                            width: "10%",
                            textAlign: "center",
                          }}
                        >
                          <Field
                            name="Frequent_skin"
                            required={false}
                            component={renderCheckbox}
                          />
                        </Table.Cell>
                        <Table.Cell
                          style={{
                            borderRadius: 0,
                            paddingTop: "5px",
                            paddingBottom: "5px",
                            width: "30%",
                          }}
                        >
                          30.Frequent headaches
                        </Table.Cell>
                        <Table.Cell
                          style={{
                            borderRadius: 0,
                            paddingTop: "5px",
                            paddingBottom: "5px",
                            width: "10%",
                            textAlign: "center",
                          }}
                        >
                          <Field
                            name="Frequent_headaches"
                            required={false}
                            component={renderCheckbox}
                          />
                        </Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell
                          style={{
                            borderRadius: 0,
                            paddingTop: "5px",
                            paddingBottom: "5px",
                            width: "30%",
                          }}
                        >
                          13.Heart disease, type
                        </Table.Cell>
                        <Table.Cell
                          style={{
                            borderRadius: 0,
                            paddingTop: "5px",
                            paddingBottom: "5px",
                            width: "10%",
                            textAlign: "center",
                          }}
                        >
                          <Field
                            name="Heart_disease"
                            required={false}
                            component={renderCheckbox}
                          />
                        </Table.Cell>
                        <Table.Cell
                          style={{
                            borderRadius: 0,
                            paddingTop: "5px",
                            paddingBottom: "5px",
                            width: "30%",
                          }}
                        >
                          31.Frequent sore throat infections
                        </Table.Cell>
                        <Table.Cell
                          style={{
                            borderRadius: 0,
                            paddingTop: "5px",
                            paddingBottom: "5px",
                            width: "10%",
                            textAlign: "center",
                          }}
                        >
                          <Field
                            name="Frequent_sore_throat"
                            required={false}
                            component={renderCheckbox}
                          />
                        </Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell
                          style={{
                            borderRadius: 0,
                            paddingTop: "5px",
                            paddingBottom: "5px",
                            width: "30%",
                          }}
                        >
                          14.Poisoning
                        </Table.Cell>
                        <Table.Cell
                          style={{
                            borderRadius: 0,
                            paddingTop: "5px",
                            paddingBottom: "5px",
                            width: "10%",
                            textAlign: "center",
                          }}
                        >
                          <Field
                            name="Poisoning"
                            required={false}
                            component={renderCheckbox}
                          />
                        </Table.Cell>
                        <Table.Cell
                          style={{
                            borderRadius: 0,
                            paddingTop: "5px",
                            paddingBottom: "5px",
                            width: "30%",
                          }}
                        >
                          32.Mumps
                        </Table.Cell>
                        <Table.Cell
                          style={{
                            borderRadius: 0,
                            paddingTop: "5px",
                            paddingBottom: "5px",
                            width: "10%",
                            textAlign: "center",
                          }}
                        >
                          <Field
                            name="Mumps"
                            required={false}
                            component={renderCheckbox}
                          />
                        </Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell
                          style={{
                            borderRadius: 0,
                            paddingTop: "5px",
                            paddingBottom: "5px",
                            width: "30%",
                          }}
                        >
                          15.Rheumatic fever
                        </Table.Cell>
                        <Table.Cell
                          style={{
                            borderRadius: 0,
                            paddingTop: "5px",
                            paddingBottom: "5px",
                            width: "10%",
                            textAlign: "center",
                          }}
                        >
                          <Field
                            name="Rheumatic_fever"
                            required={false}
                            component={renderCheckbox}
                          />
                        </Table.Cell>
                        <Table.Cell
                          style={{
                            borderRadius: 0,
                            paddingTop: "5px",
                            paddingBottom: "5px",
                            width: "30%",
                          }}
                        >
                          33.Poor hearing
                        </Table.Cell>
                        <Table.Cell
                          style={{
                            borderRadius: 0,
                            paddingTop: "5px",
                            paddingBottom: "5px",
                            width: "10%",
                            textAlign: "center",
                          }}
                        >
                          <Field
                            name="Poor_hearing"
                            required={false}
                            component={renderCheckbox}
                          />
                        </Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell
                          style={{
                            borderRadius: 0,
                            paddingTop: "5px",
                            paddingBottom: "5px",
                            width: "30%",
                          }}
                        >
                          16.Sickle cell disease
                        </Table.Cell>
                        <Table.Cell
                          style={{
                            borderRadius: 0,
                            paddingTop: "5px",
                            paddingBottom: "5px",
                            width: "10%",
                            textAlign: "center",
                          }}
                        >
                          <Field
                            name="Sickle_cell"
                            required={false}
                            component={renderCheckbox}
                          />
                        </Table.Cell>
                        <Table.Cell
                          style={{
                            borderRadius: 0,
                            paddingTop: "5px",
                            paddingBottom: "5px",
                            width: "30%",
                          }}
                        >
                          34.Seizures or epilepsy
                        </Table.Cell>
                        <Table.Cell
                          style={{
                            borderRadius: 0,
                            paddingTop: "5px",
                            paddingBottom: "5px",
                            width: "10%",
                            textAlign: "center",
                          }}
                        >
                          <Field
                            name="Seizures"
                            required={false}
                            component={renderCheckbox}
                          />
                        </Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell
                          style={{
                            borderRadius: 0,
                            paddingTop: "5px",
                            paddingBottom: "5px",
                            width: "30%",
                          }}
                        >
                          17.Kidney disease, type
                        </Table.Cell>
                        <Table.Cell
                          style={{
                            borderRadius: 0,
                            paddingTop: "5px",
                            paddingBottom: "5px",
                            width: "10%",
                            textAlign: "center",
                          }}
                        >
                          <Field
                            name="Kidney_disease"
                            required={false}
                            component={renderCheckbox}
                          />
                        </Table.Cell>
                        <Table.Cell
                          style={{
                            borderRadius: 0,
                            paddingTop: "5px",
                            paddingBottom: "5px",
                            width: "30%",
                          }}
                        >
                          35.Stool soiling
                        </Table.Cell>
                        <Table.Cell
                          style={{
                            borderRadius: 0,
                            paddingTop: "5px",
                            paddingBottom: "5px",
                            width: "10%",
                            textAlign: "center",
                          }}
                        >
                          <Field
                            name="Stool"
                            required={false}
                            component={renderCheckbox}
                          />
                        </Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell
                          style={{
                            borderRadius: 0,
                            paddingTop: "5px",
                            paddingBottom: "5px",
                            width: "30%",
                          }}
                        >
                          18.Allergies or hay fever
                        </Table.Cell>
                        <Table.Cell
                          style={{
                            borderRadius: 0,
                            paddingTop: "5px",
                            paddingBottom: "5px",
                            width: "10%",
                            textAlign: "center",
                          }}
                        >
                          <Field
                            name="Allergies"
                            required={false}
                            component={renderCheckbox}
                          />
                        </Table.Cell>
                        <Table.Cell
                          style={{
                            borderRadius: 0,
                            paddingTop: "5px",
                            paddingBottom: "5px",
                            width: "30%",
                          }}
                        >
                          36.Toothaches or dental infections
                        </Table.Cell>
                        <Table.Cell
                          style={{
                            borderRadius: 0,
                            paddingTop: "5px",
                            paddingBottom: "5px",
                            width: "10%",
                            textAlign: "center",
                          }}
                        >
                          <Field
                            name="Toothaches"
                            required={false}
                            component={renderCheckbox}
                          />
                        </Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </Grid.Column>
              </Grid.Row>

              <Grid.Row>
                <Grid.Column>
                  <b>What medication are grien frequently/daily</b>
                  <FormGroup>
                    <Field
                      width="4"
                      name="medication"
                      type="text"
                      required={false}
                      component={renderField}
                      label="ข้อมูลการใช้ยาเป็นประจำ"
                    />
                    <Field
                      width="4"
                      name="allergy"
                      type="text"
                      required={false}
                      component={renderField}
                      label="Allergy information"
                    />
                  </FormGroup>
                  <FormGroup>
                    <Field
                      width="4"
                      name="reaction"
                      type="text"
                      required={false}
                      component={renderField}
                      label="Allergy reaction"
                    />
                    <Field
                      width="4"
                      name="treatment"
                      type="text"
                      required={false}
                      component={renderField}
                      label="Allergy treatment"
                    />
                  </FormGroup>
                  <b>ข้อมูลโรงพยาบาลและแพทย์ที่ดูแลเป็นประจำ</b>
                  <FormGroup>
                    <Field
                      width="4"
                      name="hospital_name"
                      type="text"
                      required={false}
                      component={renderField}
                      label="Hospital Name"
                    />
                    <Field
                      width="4"
                      name="h_n_no"
                      type="text"
                      required={false}
                      component={renderField}
                      label="H/N No."
                    />
                  </FormGroup>
                  <FormGroup>
                    <Field
                      width="4"
                      name="doctor_name"
                      type="text"
                      required={false}
                      component={renderField}
                      label="Doctor's name"
                    />
                    <Field
                      width="4"
                      name="doctor_phone"
                      type="number"
                      required={false}
                      component={renderField}
                      label="Doctor's Phone"
                    />
                  </FormGroup>

                  {errormsg && (
                    <Message negative>
                      <Message.Content>{errormsg}</Message.Content>
                    </Message>
                  )}
                  <Button
                    floated="right"
                    content="Save"
                    style={{
                      backgroundColor: config.colorTheme,
                      color: "#ffffff",
                      minWidth: "20%",
                      marginRight: "10%",
                    }}
                    // onClick={() => showPopup()}
                    loading={loading}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </div>
        </Form>
      </Segment>

      {/* <ModalBasic open={open} size="big" close={popUpClose}>
        <Header as="h2" style={{ textAlign: "center" }}>
          ทำการบันทึกข้อมูลแล้ว
        </Header>
        <center>
          <Button
            content="OK"
            style={{
              backgroundColor: config.colorTheme,
              color: "#ffffff",
              minWidth: "5%",
            }}
            onClick={() => popUpClose()}
          />
        </center>
      </ModalBasic> */}
    </Segment>
  );
};

FormHealth = reduxForm({
  // a unique name for the form
  form: "FormHealth",
  validate,
  enableReinitialize: true,
})(FormHealth);

const selector = formValueSelector("class");
FormHealth = connect((state) => {
  const start_card = selector(state, "start_card");
  const end_card = selector(state, "end_card");
  return {
    start_card: start_card,
    end_card: end_card,
  };
})(FormHealth);

export default withRouter(FormHealth);
