// const prod = "http://35.240.175.255:8080/api/v1/web";
// const dev = "http://35.247.176.96:8080/api/v1/web";
// export const urlAPI = "http://localhost:8000/api/v1/web";
// export const urlAPI = 'https://kidz.loxleyorbit.com:8080/api/v1/web';

//UAT 1
// export const urlAPI = "https://api-kidz-dot-kidz-village.as.r.appspot.com/api/v1/web";

//PRODUCTION
export const urlAPI =
  "https://api-kidz-dot-kidz-village-ii.as.r.appspot.com/api/v1/web";

//---------------------------------------------------------------------------------------------------------
// Visit request link
// >> localhost
// export const urlVisitReqform = "https://localhost:3001";
// >> UAT
// export const urlVisitReqform = "https://kidzweb-public-dot-kidz-village.as.r.appspot.com";
// >> PRODUCTION
export const urlVisitReqform = "https://enroll.kidz-village.ac.th";
//---------------------------------------------------------------------------------------------------------

// {process.env.NODE_ENV === 'development' ?
//                         "http://35.247.176.96:8080/api/v1/web"
//                       : process.env.NODE_ENV === 'production' ?
//                         "http://35.240.175.255:8080/api/v1/web"
//                       };
// export const urlAPI = "https:///api-bac.firebaseapp.com/api";
// export const urlAPI = "https://api-json-bac.firebaseapp.com/api";
// 13: 50 NEUNG superadmin
// 13: 50 NEUNG OrbitOnly1
// p.aaaa@stu.bac.ac.th  14051994 BAC57

export const headerCallAPI = {
  headers: {
    Authorization: "Bearer " + sessionStorage.getItem("kidz_access_token"),
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  },
};
export const headerFileCallAPI = {
  headers: {
    Authorization: "Bearer " + sessionStorage.getItem("kidz_access_token"),
    Accept: "application/json",
    // "Content-Type": "multipart/form-data"
  },
};

export const colorTheme = "#7AC142";
export const colorTheme2 = "#80561B";

export const options_religion = [
  { key: "b", text: "Buddhism", value: "Buddhism" },
  { key: "c", text: "Christian", value: "Christian" },
  { key: "i", text: "Islam", value: "Islam" },
  { key: "n", text: "None", value: "None" },
];
export const options_degree = [
  { key: "s", text: "Senior High School", value: "Senior High School" },
  { key: "b", text: "Bachelor Degree", value: "Bachelor Degree" },
  { key: "m", text: "Master Degree", value: "Master Degre" },
  { key: "d", text: "Doctor Degree", value: "Doctor Degree" },
];
export const options_regisstatus = [
  { key: "r", text: "Registered", value: "Registered" },
  { key: "u", text: "Unregistered", value: "Unregistered" },
  // ,
  // { key: "g", text: "Graduated", value: "Graduated" }
];

export const options_studentstatus = [
  { key: "a", text: "Active", value: "Active" },
  { key: "i", text: "Inactive", value: "Inactive" },
  // { key: "g", text: "Graduated", value: "Graduated" },
  { key: "d", text: "Drop", value: "Drop" },
  { key: "l", text: "Leave", value: "Leave" },
  // { key: "t", text: "Terminated", value: "Terminated" },
];

export const options_studenttype = [
  { key: "a", text: "Full-time", value: "Full-time" },
  { key: "i", text: "Part-time", value: "Part-time" },
];

export const options_active = [
  { key: "a", text: "Active", value: "Active" },
  { key: "i", text: "Inactive", value: "Inactive" },
];

export const options_gender = [
  { key: "a", text: "Male", value: "Male" },
  { key: "i", text: "Female", value: "Female" },
];

export const options_nationality = [
  { key: "Australian", text: "Australian", value: "Australian" },
  { key: "Belgian", text: "Belgian", value: "Belgian" },
  { key: "Brazilian", text: "Brazilian", value: "Brazilian" },
  { key: "Cambodian", text: "Cambodian", value: "Cambodian" },
  { key: "Canadian", text: "Canadian", value: "Canadian" },
  { key: "Chinese", text: "Chinese", value: "Chinese" },
  { key: "Danish", text: "Danish", value: "Danish" },
  { key: "Egyptian", text: "Egyptian", value: "Egyptian" },
  { key: "English", text: "English", value: "English" },
  { key: "Finnish", text: "Finnish", value: "Finnish" },
  { key: "French", text: "French", value: "French" },
  { key: "German", text: "German", value: "German" },
  { key: "Greek", text: "Greek", value: "Greek" },
  { key: "Indonesian", text: "Indonesian", value: "Indonesian" },
  { key: "Iranian", text: "Iranian", value: "Iranian" },
  { key: "Irish", text: "Irish", value: "Irish" },
  { key: "Israeli", text: "Israeli", value: "Israeli" },
  { key: "Italian", text: "Italian", value: "Italian" },
  { key: "Japanese", text: "Japanese", value: "Japanese" },
  { key: "Latian", text: "Latian", value: "Latian" },
  { key: "Malaysian", text: "Malaysian", value: "Malaysian" },
  { key: "Mexican", text: "Mexican", value: "Mexican" },
  { key: "New Zealander", text: "New Zealander", value: "New Zealander" },
  { key: "Norwegian", text: "Norwegian", value: "Norwegian" },
  { key: "Filipino", text: "Filipino", value: "Filipino" },
  { key: "Portuguese", text: "Portuguese", value: "Portuguese" },
  { key: "Romanian", text: "Romanian", value: "Romanian" },
  { key: "Russian", text: "Russian", value: "Russian" },
  { key: "Korean", text: "Korean", value: "Korean" },
  { key: "Spanish", text: "Spanish", value: "Spanish" },
  { key: "Swedish", text: "Swedish", value: "Swedish" },
  { key: "Swiss", text: "Swiss", value: "Swiss" },
  { key: "Thai", text: "Thai", value: "Thai" },
  { key: "British", text: "British", value: "British" },
  { key: "American", text: "American", value: "American" },
  { key: "Vietnamese", text: "Vietnamese", value: "Vietnamese" },
  { key: "Dutch", text: "Dutch", value: "Dutch" },
  { key: "Other", text: "Other", value: "Other" },
];

export const options_campus = [
  { key: 1, text: "Kidz Village (KV)", value: 1 },
  { key: 2, text: "Kidz Learning Centre (KLC)", value: 2 },
];

export const options_program = [
  { key: 1, text: "International Program", value: 1 },
  { key: 2, text: "After School", value: 2 },
  { key: 3, text: "Special class", value: 3 },
  { key: 4, text: "Summer Program", value: 4 },
  { key: 5, text: "Regular Program (Per Month)", value: 5 },
  { key: 6, text: "Playgroup", value: 6 },
  { key: 7, text: "Day care", value: 7 },
];

export const options_title_eng = [
  { key: "1", value: "Mr.", text: "Mr." },
  { key: "2", value: "Mrs.", text: "Mrs." },
  { key: "3", value: "Miss", text: "Miss" },
];
export const options_title_th = [
  { key: "1", value: "นาย", text: "นาย" },
  { key: "2", value: "นาง", text: "นาง" },
  { key: "3", value: "นางสาว", text: "นางสาว" },
  { key: "4", value: "เด็กหญิง", text: "เด็กหญิง" },
  { key: "5", value: "เด็กชาย", text: "เด็กชาย" },
];

export const options_classstatus = [
  { key: "0", value: "All", text: "All" },
  { key: "1", value: "Attended", text: "Attended" },
  { key: "2", value: "Leave", text: "Leave" },
  { key: "3", value: "Late", text: "Late" },
  { key: "4", value: "Absent", text: "Absent" },
];
export const options_schoolstatus = [
  { key: "0", value: "All", text: "All" },
  { key: "1", value: "Present", text: "Present" },
  { key: "2", value: "Absent", text: "Absent" },
  // { key: "4", value: "Absent", text: "Absent" }
];

export const options_guardian = [
  { key: "Father", value: "Father", text: "Father" },
  { key: "Mother", value: "Mother", text: "Mother" },
  { key: "Other", value: "Other", text: "Other" },
];
export const options_guardianfather = [
  { key: "Father", value: "Father", text: "Father" },
  { key: "Other", value: "Other", text: "Other" },
];
export const options_guardianmother = [
  { key: "Mother", value: "Mother", text: "Mother" },
  { key: "Other", value: "Other", text: "Other" },
];
export const options_relationship = [
  { key: "GrandFather", value: "GrandFather", text: "GrandFather" },
  { key: "GrandMother", value: "GrandMother", text: "GrandMother" },
  { key: "Uncle", value: "Uncle", text: "Uncle" },
  { key: "Aunt", value: "Aunt", text: "Aunt" },
  { key: "Other", value: "Other", text: "Other" },
];
export const options_pdpa = [
  { key: "I GRANT", value: "I GRANT", text: "I GRANT" },
  { key: "I DO NOT GRANT", value: "I DO NOT GRANT", text: "I DO NOT GRANT" },
];

export const permission = [
  {
    group: "Personnel",
    permission: [
      {
        label: "Manage Teacher",
        name: "manage-teacher",
      },
      {
        label: "View Teacher",
        name: "view-teacher",
      },
      {
        label: "Manage Student",
        name: "manage-student",
      },
      {
        label: "View Student",
        name: "view-student",
      },
      {
        label: "Manage Staff",
        name: "manage-staff",
      },
      {
        label: "View Staff",
        name: "view-staff",
      },
      {
        label: "Manage Parent",
        name: "manage-parent",
      },
      {
        label: "View Parent",
        name: "view-parent",
      },
      {
        label: "Import Student",
        name: "import-student",
      },
      //  {
      //   label: "Register",
      //   name: "register-student",
      // },
    ],
  },
  {
    group: "Academic",
    permission: [
      {
        label: "Manage Registration (Promotion)",
        name: "manage-registration",
      },
      {
        label: "Approve Registration",
        name: "approve-registration",
      },
      {
        label: "Manage Course",
        name: "manage-course",
      },
      {
        label: "Manage Class",
        name: "manage-class",
      },
    ],
  },
  {
    group: "Management",
    permission: [
      {
        label: "Manage Term",
        name: "manage-term",
      },
      {
        label: "Manage Role & Permission",
        name: "manage-role",
      },
      {
        label: "Manage User",
        name: "manage-user",
      },
    ],
  },
  {
    group: "Report",
    permission: [
      {
        label: "Attendance Report",
        name: "attendance-report",
      },
      {
        label: "Absent Report",
        name: "absent-report",
      },
      {
        label: "Kitchen Report",
        name: "kitchen-report",
      },
      {
        label: "Login Report",
        name: "login-report",
      },
      {
        label: "Application Report",
        name: "application-report",
      },
      {
        label: "Consent Report",
        name: "consent-report",
      },
    ],
  },
  {
    group: "Attendance",
    permission: [
      {
        label: "School Attendances",
        name: "scan-school",
      },
      {
        label: "Scan QR",
        name: "scan-qr",
      },
      {
        label: "Class Attendance",
        name: "class-attendance",
      },
      {
        label: "Class Attendance (Admin)",
        name: "class-attendance-admin",
      },
    ],
  },
];
