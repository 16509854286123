import React, { useState, useEffect } from "react";
import { Field, reduxForm, formValueSelector } from "redux-form";
import renderField from "../../../components/renderField";
import renderSelectField from "../../../components/renderSelectField";
import renderFileField from "../../../components/renderFileField";
import renderDateTimeField from "../../../components/renderDateTimeField";
import renderDateOnlyField from "../../../components/renderDateOnlyField";
import renderDateField from "../../../components/renderDateOnlyField";
import { Button, Form, Message, Grid } from "semantic-ui-react";
import SecondButton from "../../../components/secondbutton";
import * as config from "../../../config";
import { connect } from "react-redux";
import {
  Header,
  Image,
  Segment,
  Breadcrumb,
  Loader,
  Dimmer,
  Tab,
  Table,
  Icon,
  Label,
  FormGroup,
  Step,
} from "semantic-ui-react";
import ModalBasic from "../../../components/modal";
import Wrap from "../../../components/wrap";
import FormStudent from "./formstudent";
import StudentFather from "./studentfather";
import StudentMother from "./studentmother";
import PDPA from "./pdpa";
// import FormImg from "./formimg";
import {
  getvisitProfile,
  getstudentdetailProfile,
  //   getinterview,
  addapplication,
  consentpdpa,
  updateImageProfile,
} from "./service";

const windowUrl = window.location.search;
const Id = windowUrl.split("=");
// console.log(Id[1]);

let StudentRegister = (props) => {
  const [loading, setLoading] = useState(true);
  const [loadingPDPA, setLoadingPDPA] = useState(false);
  const [showPDPA, setShowPDPA] = useState(false);
  const [showVisitLink, setShowVisitLink] = useState(false);
  const [currentData, setCurrentData] = useState(null);
  const [open, setOpen] = useState(false);
  const [openPDPA, setOpenPDPA] = useState(true);
  const [visitProfiles] = useState(Id[1]);
  const [data, setData] = useState([]);
  const [visitData, setVisitData] = useState([]);
  const [showdata, setShowData] = useState(false);
  const [pdpa, setPDPA] = useState([]);
  const [openImg, setOpenImg] = useState(false);
  const [errormsg, setErrormsg] = useState(null);
  const [photo, setPhoto] = useState(null);

  useEffect(() => {
    setLoading(true);
    if (visitProfiles) {
      getData();
    }
  }, [visitProfiles]);

  const getData = () => {
    getstudentdetailProfile(Id[1]).then((res) => {
      if (res && res.status === 200) {
        setData(res.data);
        // console.log(res.data);
        if (res.data.id == " " || res.data.id == null) {
          setShowData(true);
        } else if (res.data.id != " " || res.data.id != null) {
          setShowData(false);
        }
      }
      setLoading(false);
    });

    getvisitProfile(Id[1]).then((res) => {
      if (res && res.status === 200) {
        setVisitData(res.data);
        setPDPA(res.data.pdpa);

        //found
        if (res.data != "") {
          //Verify PDPA popup for submit
          if (
            res.data.pdpa == null ||
            res.data.pdpa == "" ||
            res.data.pdpa != "I GRANT"
          ) {
            setShowPDPA(true);
          } else {
            setShowPDPA(false);
          }
        } //Not found
        else {
          // Show popup link for visit register first
          setShowVisitLink(true);
        }
      }
      setLoading(false);
    });

    // getinterview(Id[1]).then((res) => {
    //   if (res && res.status === 200) {
    //     // console.log(res.data);
    //   }
    //   setLoading(false);
    // });
  };

  const consent = (values) => {
    setLoadingPDPA(true);
    let data = values;
    consentpdpa(values, Id[1]).then((res) => {
      // console.log(res);
      if (res && res.status === 200) {
        getData();
        setOpenPDPA(false);
        // setOpen(true);
      }
      setCurrentData(null);
      setLoadingPDPA(false);
    });
  };

  const postApplicationData = (values) => {
    setErrormsg(null);
    setLoading(true);
    let data = values;

    // Protect human error
    // >> Verify phone No. must be 10 digits
    if (
      (values.father_phone && values.father_phone.length != 10) ||
      (values.mother_phone && values.mother_phone.length != 10)
    ) {
      setErrormsg("Invalid phone number, must be 10 digits");
      setLoading(false);
      return;
    }
    // >> Verify not allow (-) at first digit
    if (
      (values.father_phone && parseInt(values.father_phone) < 0) ||
      (values.mother_phone && parseInt(values.mother_phone) < 0)
    ) {
      setErrormsg("Invalid phone number, do not allow (-) at first digit.");
      setLoading(false);
      return;
    }

    // console.log(`postData`, data);
    addapplication(values).then((res) => {
      // console.log(`addapplication`, res);
      if (res && res.status === 201) {
        // console.log(`insert`, res);
        // setData(res.data.request);
        // console.log(`visitno`, res.data.visitno);
        setLoading(false);
        setOpen(true);
      } else if (res && res.status === 409) {
        setLoading(false);

        // console.log(`Duplicate`, res);
        // setErrormsg(
        //   "Duplicated student. Please check [ID No.] or [firstName] or [lastName]"
        // );
        setErrormsg("Duplicated student, already registered!!");
        return;
      }
      setErrormsg(res.data.message);
    });
  };

  const popUpClose = () => {
    setOpen(false);
    window.location.reload();
  };

  // Todo
  const updateImage = () => {
    setLoading(true);
    var formData = new FormData();
    if (photo) {
      if (typeof photo === "object") formData.append("photo", photo[0]);
      else formData.append("photo", photo);
    }
    updateImageProfile(formData, data.id).then((res) => {
      if (res && res.status === 200) {
        getData();
        setOpenImg(false);
      }
      setLoading(false);
    });
  };

  const onEditImg = (values) => {
    setOpenImg(true);
    setCurrentData(values);
  };
  const onCloseImg = () => {
    setOpenImg(false);
    setCurrentData(null);
  };

  const updateDataImg = (values) => {
    setLoading(true);
    let data = values;
  };

  const nextPage = () => {
    props.history.push({
      pathname: "/formregister/student2" + props.location.search,
    });
    window.location.reload();
    // window.close();
  };

  const goPagestudent = () => {
    props.history.push({
      pathname: "/formregister/student" + props.location.search,
    });
    window.location.reload();
    // window.close();
  };

  const goPagestudent2 = () => {
    props.history.push({
      pathname: "/formregister/student2" + props.location.search,
    });
    window.location.reload();
    // window.close();
  };

  const goPageguardian = () => {
    props.history.push({
      pathname: "/formregister/guardian" + props.location.search,
    });
    window.location.reload();
    // window.close();
  };

  const goPagehealth = () => {
    props.history.push({
      pathname: "/formregister/health" + props.location.search,
    });
    window.location.reload();
    // window.close();
  };

  const goPagedocument = () => {
    props.history.push({
      pathname: "/formregister/document" + props.location.search,
    });
    window.location.reload();
    // window.close();
  };

  const goPagepreviewregister = () => {
    props.history.push({
      pathname: "/formregister/previewregister" + props.location.search,
    });
    window.location.reload();
    // window.close();
  };

  return (
    <Segment basic textAlign="left">
      <Segment style={{ width: "100%" }}>
        <Grid columns="7">
          <Grid.Column
            style={{
              color: "#eee",
              fontSize: "13px",
              width: "25%",
              backgroundColor: "#769393",
            }}
          >
            <b style={{ textAlign: "center" }}>Application Form</b>
          </Grid.Column>
          <Grid.Column
            style={{ color: "#8e8d8d", fontSize: "15px", width: "20%" }}
          >
            <b
              style={{ textAlign: "center", cursor: "pointer" }}
              onClick={() => nextPage()}
            >
              1. Student Details
            </b>
            <b style={{ paddingLeft: "20%" }}>/</b>
          </Grid.Column>
          <Grid.Column
            style={{ color: "#aaa", fontSize: "13px", width: "10%" }}
          >
            <b style={{ cursor: "pointer" }} onClick={() => goPagestudent2()}>
              2.
            </b>
            <b style={{ paddingLeft: "50%" }}>/</b>
          </Grid.Column>
          <Grid.Column
            style={{ color: "#aaa", fontSize: "13px", width: "10%" }}
          >
            <b style={{ cursor: "pointer" }} onClick={() => goPageguardian()}>
              3.
            </b>
            <b style={{ paddingLeft: "50%" }}>/</b>
          </Grid.Column>
          <Grid.Column
            style={{ color: "#aaa", fontSize: "13px", width: "10%" }}
          >
            <b style={{ cursor: "pointer" }} onClick={() => goPagehealth()}>
              4.
            </b>
            <b style={{ paddingLeft: "50%" }}>/</b>
          </Grid.Column>
          <Grid.Column
            style={{ color: "#aaa", fontSize: "13px", width: "10%" }}
          >
            <b style={{ cursor: "pointer" }} onClick={() => goPagedocument()}>
              5.
            </b>
            <b style={{ paddingLeft: "50%" }}>/</b>
          </Grid.Column>
          <Grid.Column
            style={{ color: "#aaa", fontSize: "13px", width: "10%" }}
          >
            <b
              style={{ textAlign: "center", cursor: "pointer" }}
              onClick={() => goPagepreviewregister()}
            >
              6.
            </b>
            <b style={{ paddingLeft: "50%" }}>/</b>
          </Grid.Column>
        </Grid>
      </Segment>

      {showdata ? (
        // <FormStudent onSubmit={addapplication} initialValues={visitData} />
        <FormStudent
          onSubmit={postApplicationData}
          initialValues={visitData}
          errormsg={errormsg}
          loading={loading}
        />
      ) : (
        <div style={{ paddingLeft: "12px", paddingRight: "12px" }}>
          <Segment>
            <Image
              src="../storage/imgs/KV-logo.png"
              size="tiny"
              spaced
              floated="right"
            />

            <div style={{ paddingLeft: "20px" }}>
              <Header as="h4">Bio Data</Header>
              <Header as="h4">Student</Header>
              <Segment style={{ margin: "20px" }}>
                {/* <Grid columns="2" style={{ width: "70%" }}> */}
                <Grid columns="3" stackable>
                  {data.imageURL && (
                    <>
                      <Grid.Column style={{ paddingLeft: "10px" }}>
                        <Image
                          width={"25%"}
                          verticalAlign="middle"
                          src={
                            data.imageURL
                              ? data.imageURL + "?time=" + new Date()
                              : "../storage/imgs/dfkid.png"
                          }
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = "../storage/imgs/dfkid.png";
                          }}
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <Button
                          content="Image"
                          // onClick={() => onEditImg(data)}
                          onClick={() => setOpenImg(true)}
                          style={{
                            backgroundColor: config.colorTheme,
                            color: "#ffffff",
                            minWidth: "10%",
                            // marginLeft: "10%",
                          }}
                        ></Button>
                      </Grid.Column>
                    </>
                  )}
                  {!data.imageURL && (
                    <>
                      <Grid.Column style={{ paddingLeft: "10px" }}>
                        <Image
                          width={"25%"}
                          verticalAlign="middle"
                          src={
                            data.imageURL
                              ? data.imageURL + "?time=" + new Date()
                              : "../storage/imgs/dfkid.png"
                          }
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = "../storage/imgs/dfkid.png";
                          }}
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <Button
                          content="Image"
                          // onClick={() => onEditImg(data)}
                          onClick={() => setOpenImg(true)}
                          style={{
                            backgroundColor: config.colorTheme,
                            color: "#ffffff",
                            minWidth: "10%",
                            // marginLeft: "10%",
                          }}
                        ></Button>
                      </Grid.Column>
                      <Grid.Column>
                        <p style={{ color: "red", marginTop: "8%" }}>
                          Please Upload Image
                        </p>
                      </Grid.Column>
                    </>
                  )}
                </Grid>
              </Segment>

              <Grid>
                <Grid.Row>
                  <Grid stackable columns="2" style={{ width: "100%" }}>
                    <Grid.Column style={{ paddingLeft: "40px" }}>
                      Passport or ID No. : <b>{data.idnumber}</b>
                    </Grid.Column>
                    <Grid.Column>
                      Date Of Birth : <b>{data.dateOfBirth}</b>
                    </Grid.Column>
                  </Grid>
                </Grid.Row>

                <Grid.Row>
                  <Grid stackable columns="4" style={{ width: "100%" }}>
                    <Grid.Column style={{ paddingLeft: "40px" }}>
                      firstname: <b>{data.firstName}</b>
                    </Grid.Column>
                    <Grid.Column>
                      lastname : <b>{data.lastName}</b>
                    </Grid.Column>
                    <Grid.Column>
                      Nickname: <b>{data.nickname}</b>
                    </Grid.Column>
                  </Grid>
                </Grid.Row>
                <Grid.Row>
                  <Grid stackable columns="4" style={{ width: "100%" }}>
                    <Grid.Column style={{ paddingLeft: "40px" }}>
                      ชื่อ: <b>{data.firstName_th}</b>
                    </Grid.Column>
                    <Grid.Column>
                      นามสกุล : <b>{data.lastName_th}</b>
                    </Grid.Column>
                    <Grid.Column>
                      ชื่อเล่น : <b>{data.nickname_th}</b>
                    </Grid.Column>
                  </Grid>
                </Grid.Row>
                <Grid.Row>
                  <Grid stackable columns="4" style={{ width: "100%" }}>
                    <Grid.Column style={{ paddingLeft: "40px" }}>
                      Gender : <b>{data.gender}</b>
                    </Grid.Column>
                    <Grid.Column>
                      Nationality : <b>{data.nationality}</b>
                    </Grid.Column>
                    <Grid.Column>
                      Native Language :<b>{data.nativelanguage}</b>
                    </Grid.Column>

                    <Grid.Column>
                      Native Language (Use at home) :
                      <b>{data.nativelanguageAtHome}</b>
                    </Grid.Column>
                  </Grid>
                </Grid.Row>
                <Grid.Row>
                  <Grid stackable columns="4" style={{ width: "100%" }}>
                    <Grid.Column style={{ paddingLeft: "40px" }}>
                      {/* โรคประจำตัว: <b>{data.allergy}</b> */}
                      {/* replace allergy */}
                      โรคประจำตัว: <b>{data.healthConditions}</b>
                    </Grid.Column>
                    <Grid.Column>
                      แพ้อาหาร : <b>{data.food_allergy}</b>
                    </Grid.Column>
                    <Grid.Column>
                      bloodtype : <b>{data.bloodtype}</b>
                    </Grid.Column>
                    <Grid.Column>
                      race :<b>{data.race}</b>
                    </Grid.Column>
                  </Grid>
                </Grid.Row>
              </Grid>
              <StudentFather />
              <StudentMother />
              <br></br>
            </div>
          </Segment>
          <Button
            floated="right"
            content="Next Page"
            style={{
              backgroundColor: "#4682B4",
              color: "#ffffff",
              minWidth: "20%",
              marginRight: "10%",
            }}
            onClick={() => nextPage()}
          />
        </div>
      )}

      <br></br>
      <br></br>
      {/* <Button
        floated="right"
        content="Next Page"
        style={{
          backgroundColor: "#4682B4",
          color: "#ffffff",
          minWidth: "20%",
          marginRight: "10%",
        }}
        onClick={() => nextPage()}
      /> */}
      <br></br>
      <br></br>

      <ModalBasic
        closeIcon={true}
        open={openImg}
        // size="large"
        // close={() => onCloseImg()}
        close={() => setOpenImg(false)}
      >
        <Grid>
          <Grid.Row>
            <Grid.Column verticalAlign="middle">
              <Form.Input
                type="file"
                name="photo"
                onChange={(e) => setPhoto(e.target.files)}
              />
              <br />
              <br />
              <Button
                floated="left"
                content="Upload"
                fluid
                style={{
                  backgroundColor: config.colorTheme,
                  color: "#ffffff",
                  minWidth: "30%",
                }}
                loading={loading}
                onClick={updateImage}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <br />
        <br />
        {/* <FormImg
          loading={loading}
          initialValues={currentData}
          onClose={() => onCloseImg()}
          onSubmit={updateDataImg}
        /> */}
      </ModalBasic>

      {showPDPA ? (
        <ModalBasic open={openPDPA} size="large">
          <PDPA loading={loadingPDPA} onSubmit={consent} />
        </ModalBasic>
      ) : (
        ""
      )}

      <ModalBasic open={showVisitLink} size="large">
        <p style={{ color: "red", textAlign: "center" }}>
          คุณยังไม่ได้ทำการลงทะเบียนเยี่ยมชมโรงเรียน
        </p>
        <p style={{ textAlign: "center" }}>
          Click Link เพื่อลงทะเบียน:
          <a href={config.urlVisitReqform}>{config.urlVisitReqform}</a>
        </p>
      </ModalBasic>

      <ModalBasic open={open} size="large" close={popUpClose}>
        <Header as="h2" style={{ textAlign: "center" }}>
          บัททึกข้อมูลเรียบร้อย
        </Header>
        <center>
          <Button
            content="OK"
            style={{
              backgroundColor: config.colorTheme,
              color: "#ffffff",
              minWidth: "5%",
            }}
            onClick={() => popUpClose()}
          />
        </center>
      </ModalBasic>
    </Segment>
  );
};

export default StudentRegister;
