import React, { useState, useEffect } from "react";
import { Field, reduxForm, formValueSelector } from "redux-form";
import renderField from "../../../components/renderField";
import renderSelectField from "../../../components/renderSelectField";
import renderFileField from "../../../components/renderFileField";
import renderDateTimeField from "../../../components/renderDateTimeField";
import renderDateOnlyField from "../../../components/renderDateOnlyField";
import renderDateField from "../../../components/renderDateOnlyField";
import { Button, Form, Message, Grid, GridRow } from "semantic-ui-react";
import * as config from "../../../config";
import { connect } from "react-redux";
import {
  Header,
  Image,
  Segment,
  Breadcrumb,
  Loader,
  Dimmer,
  Tab,
  Table,
  Icon,
  Label,
  FormGroup,
  Card,
  Step,
} from "semantic-ui-react";
import ModalBasic from "../../../components/modal";
import Wrap from "../../../components/wrap";
import { withRouter } from "react-router-dom";

import {
  getvisitProfile,
  getstudentdetailProfile,
  getParentVisits,
  getdocumentbyvisit_id,
  submitApplication,
  getSubmitApplication,
} from "./service";
import FormPreviewApplication from "./frompreviewapplication";

const windowUrl = window.location.search;
const Id = windowUrl.split("=");
// console.log(Id[1]);

let PreviewStudent = (props) => {
  const [loading, setLoading] = useState(true);
  const [visitProfiles] = useState(Id[1]);
  const [visitData, setVisitData] = useState([]);
  // const [visitDataTime, setVisitDataTime] = useState([]);
  const [data, setData] = useState([]);
  const [dataFather, setFather] = useState([]);
  const [dataMother, setMother] = useState([]);
  const [document, setDocument] = useState([]);
  const [dataSubmitApplication, setGetSubmitApplication] = useState([]);

  // console.log(props);
  useEffect(() => {
    setLoading(true);
    if (visitProfiles) {
      getData();
    }
  }, [visitProfiles]);

  const getData = () => {
    getstudentdetailProfile(Id[1]).then((res) => {
      if (res && res.status === 200) {
        setData(res.data);
        // console.log(res.data);
      }
      setLoading(false);
    });

    getvisitProfile(Id[1]).then((res) => {
      if (res && res.status === 200) {
        setVisitData(res.data);
        // setVisitDataTime(res.data.interview_date.split(" ")[1]);
        // console.log(res.data.interview_date.split(' '));
      }
      setLoading(false);
    });

    getParentVisits(Id[1]).then((res) => {
      if (res && res.status === 200) {
        setFather(res.data[0]);
        setMother(res.data[1]);
      }
      setLoading(false);
    });

    getdocumentbyvisit_id(Id[1]).then((res) => {
      if (res && res.status === 200) {
        setDocument(res.data);
      }
      setLoading(false);
    });

    getSubmitApplication(Id[1]).then((res) => {
      if (res && res.status === 200) {
        setGetSubmitApplication(res.data);
      }
      setLoading(false);
    });
  };

  return (
    <Segment basic textAlign="left">
      <Segment>
        <Image
          src="../storage/imgs/SP-logo.png"
          size="tiny"
          spaced
          floated="right"
        />

        <Header as="h4">
          <u>Student Detail</u>
        </Header>
        <Header as="h4">Student</Header>
        <div style={{ paddingLeft: "20px" }}>
          <Grid>
            <Grid.Row>
              <Grid.Column>
                <Image
                  width={"15%"}
                  verticalAlign="middle"
                  src={
                    data.imageURL
                      ? data.imageURL + "?time=" + new Date()
                      : "../storage/imgs/dfkid.png"
                  }
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = "../storage/imgs/dfkid.png";
                  }}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid stackable columns="2" style={{ width: "100%" }}>
                <Grid.Column style={{ paddingLeft: "40px" }}>
                  Passport or ID No.:
                  <b> {data.idnumber}</b>
                </Grid.Column>
                <Grid.Column>
                  Date Of Birth:
                  <b> {data.dateOfBirth}</b>
                </Grid.Column>
              </Grid>
              <Grid stackable columns="4" style={{ width: "100%" }}>
                <Grid.Column style={{ paddingLeft: "40px" }}>
                  firstname:
                  <b> {data.firstName}</b>
                </Grid.Column>
                <Grid.Column>
                  lastname:
                  <b> {data.lastName}</b>
                </Grid.Column>
                <Grid.Column>
                  Nickname:
                  <b> {data.nickname}</b>
                </Grid.Column>
              </Grid>
              <Grid stackable columns="4" style={{ width: "100%" }}>
                <Grid.Column style={{ paddingLeft: "40px" }}>
                  ชื่อ:
                  <b> {data.firstName_th}</b>
                </Grid.Column>
                <Grid.Column>
                  นามสกุล:
                  <b> {data.lasttName_th}</b>
                </Grid.Column>
                <Grid.Column>
                  ชื่อเล่น:
                  <b> {data.nickname_th}</b>
                </Grid.Column>
              </Grid>
              <Grid stackable columns="4" style={{ width: "100%" }}>
                <Grid.Column style={{ paddingLeft: "40px" }}>
                  Gender:
                  <b> {data.gender}</b>
                </Grid.Column>
                <Grid.Column>
                  Nationality:
                  <b> {data.nationality}</b>
                </Grid.Column>
                <Grid.Column>
                  Native Language:
                  <b> {data.nativelanguage}</b>
                </Grid.Column>
                <Grid.Column>
                  Native Language (Use at home):
                  <b> {data.nativelanguageAtHome}</b>
                </Grid.Column>
              </Grid>
              <Grid stackable columns="4" style={{ width: "100%" }}>
                <Grid.Column style={{ paddingLeft: "40px" }}>
                  โรคประจำตัว: <b>{data.allergy}</b>
                </Grid.Column>
                <Grid.Column>
                  แพ้อาหาร : <b>{data.food_allergy}</b>
                </Grid.Column>
                <Grid.Column>
                  bloodtype : <b>{data.bloodtype}</b>
                </Grid.Column>
                <Grid.Column>
                  Race:
                  <b> {data.race}</b>
                </Grid.Column>
              </Grid>
            </Grid.Row>
          </Grid>
          <Grid>
            <Header as="h4">FATHER</Header>
            <Grid.Row>
              <Grid.Column style={{ paddingLeft: "40px" }}>
                <Image
                  width={"15%"}
                  verticalAlign="middle"
                  src={
                    dataFather.GimageURL
                      ? dataFather.GimageURL + "?time=" + new Date()
                      : "../storage/imgs/dfkid.png"
                  }
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = "../storage/imgs/dfkid.png";
                  }}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid stackable columns="2" style={{ width: "100%" }}>
                <Grid.Column style={{ paddingLeft: "40px" }}>
                  Passport or ID No.:
                  <b> {dataFather.idnumber}</b>
                </Grid.Column>
                <Grid.Column>
                  Date Of Birth:
                  <b> {dataFather.dateOfBirth}</b>
                </Grid.Column>
              </Grid>
              <Grid stackable columns="4" style={{ width: "100%" }}>
                <Grid.Column style={{ paddingLeft: "40px" }}>
                  firstname:
                  <b> {dataFather.firstName}</b>
                </Grid.Column>
                <Grid.Column>
                  lastname:
                  <b> {dataFather.firstName}</b>
                </Grid.Column>
                <Grid.Column>
                  Nationality:
                  <b> {dataFather.nationality}</b>
                </Grid.Column>
                <Grid.Column>
                  Native Language:
                  <b> {dataFather.nativelanguage}</b>
                </Grid.Column>
              </Grid>
              <Grid stackable columns="4" style={{ width: "100%" }}>
                <Grid.Column style={{ paddingLeft: "40px" }}>
                  ชื่อ:
                  <b> {dataFather.firstName_th}</b>
                </Grid.Column>
                <Grid.Column>
                  นามสกุล:
                  <b> {dataFather.lastName_th}</b>
                </Grid.Column>
                <Grid.Column>
                  Mobile:
                  <b> {dataFather.phone}</b>
                </Grid.Column>
                <Grid.Column>
                  Email:
                  <b> {dataFather.email}</b>
                </Grid.Column>
              </Grid>
              <Grid stackable columns="4" style={{ width: "100%" }}>
                <Grid.Column style={{ paddingLeft: "40px" }}>
                  Place of work:
                  <b> {dataFather.placeOfWork}</b>
                </Grid.Column>
                <Grid.Column>
                  Position:
                  <b> {dataFather.position}</b>
                </Grid.Column>
                <Grid.Column>
                  Office Tel:
                  <b> {dataFather.officePhone}</b>
                </Grid.Column>
              </Grid>
            </Grid.Row>
          </Grid>
          <Grid>
            <Header as="h4">MOTHER</Header>
            <Grid.Row>
              <Grid.Column style={{ paddingLeft: "40px" }}>
                <Image
                  width={"15%"}
                  verticalAlign="middle"
                  src={
                    dataMother.GimageURL
                      ? dataMother.GimageURL + "?time=" + new Date()
                      : "../storage/imgs/dfkid.png"
                  }
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = "../storage/imgs/dfkid.png";
                  }}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid stackable columns="2" style={{ width: "100%" }}>
              <Grid.Column style={{ paddingLeft: "40px" }}>
                Passport or ID No.:
                <b> {dataMother.idnumber}</b>
              </Grid.Column>
              <Grid.Column>
                Date Of Birth:
                <b> {dataMother.dateOfBirth}</b>
              </Grid.Column>
            </Grid>
            <Grid stackable columns="4" style={{ width: "100%" }}>
              <Grid.Column style={{ paddingLeft: "40px" }}>
                firstname:
                <b> {dataMother.firstName}</b>
              </Grid.Column>
              <Grid.Column>
                lastname:
                <b> {dataMother.firstName}</b>
              </Grid.Column>
              <Grid.Column>
                Nationality:
                <b> {dataMother.nationality}</b>
              </Grid.Column>
              <Grid.Column>
                Native Language:
                <b> {dataMother.nativelanguage}</b>
              </Grid.Column>
            </Grid>
            <Grid stackable columns="4" style={{ width: "100%" }}>
              <Grid.Column style={{ paddingLeft: "40px" }}>
                ชื่อ:
                <b> {dataMother.firstName_th}</b>
              </Grid.Column>
              <Grid.Column>
                นามสกุล:
                <b> {dataMother.lastName_th}</b>
              </Grid.Column>
              <Grid.Column>
                Mobile:
                <b> {dataMother.phone}</b>
              </Grid.Column>
              <Grid.Column>
                Email:
                <b> {dataMother.email}</b>
              </Grid.Column>
            </Grid>
            <Grid stackable columns="4" style={{ width: "100%" }}>
              <Grid.Column style={{ paddingLeft: "40px" }}>
                Place of work:
                <b> {dataMother.placeOfWork}</b>
              </Grid.Column>
              <Grid.Column>
                Position:
                <b> {dataMother.position}</b>
              </Grid.Column>
              <Grid.Column>
                Office Tel:
                <b> {dataMother.officePhone}</b>
              </Grid.Column>
            </Grid>
          </Grid>
        </div>

        <br></br>
        <br></br>
        <br></br>
        {dataSubmitApplication["submit_application"] == "yes" && (
          <p style={{ color: "#33CC33", textAlign: "center" }}>
            คุณได้ทำการกด Submit เรียบร้อยครับ
          </p>
        )}
        {dataSubmitApplication["submit_application"] != "yes" && (
          <FormPreviewApplication
            onSubmit={submitApplication}
            initialValues={data}
          />
        )}
        <br></br>
        <br></br>
        <br></br>
      </Segment>
    </Segment>
  );
};

export default withRouter(PreviewStudent);
