import axios from "axios";
import * as config from "../../../config";

export function getstudentdetailProfile(Id) {
    return axios
        .get(config.urlAPI + "/getstudentdetailProfile/" + Id, config.headerCallAPI)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            return err.response;
        });
}

export function getSibling(sibling) {
    return axios
        .get(config.urlAPI + "/getsibling/" + sibling)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            return err.response;
        });
}

export function updatesibling(data) {
    return axios
        .post(config.urlAPI + "/updatesibling/"+ data.id, data)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            return err.response;
        });
}

export function getemergency(data) {
    return axios
        .get(config.urlAPI + "/getemergency/" + data)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            return err.response;
        });   
}

export function updateemergency(data) {
    return axios
        .post(config.urlAPI + "/updateemergency", data)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            return err.response;
        });
}