import React, { useState, useEffect } from "react";
import { Field, reduxForm, formValueSelector } from "redux-form";
import renderField from "../../../components/renderField";
import renderSelectField from "../../../components/renderSelectField";
import renderFileField from "../../../components/renderFileField";
import renderDateTimeField from "../../../components/renderDateTimeField";
import renderDateOnlyField from "../../../components/renderDateOnlyField";
import { Button, Form, Message, Grid, Card } from "semantic-ui-react";
import SecondButton from "../../../components/secondbutton";
import * as config from '../../../config';
import { connect } from "react-redux";
import {
    Header,
    Image,
    Segment,
    Breadcrumb,
    Loader,
    Dimmer,
    Tab,
    Table,
    Icon,
    Label,
    Step,
    FormGroup
} from 'semantic-ui-react';
import renderDateField from "../../../components/renderDateOnlyField";
import { withRouter } from "react-router-dom";
import Wrap from "../../../components/wrap";
import ModalBasic from "../../../components/modal";
import { getvisitProfile,consentpdpa } from "./service";
import PDPA from "./pdpa";

let DoNotGrant = (props) => {
    const { loading, pristine, submitting, onSubmit, handleSubmit, initialValue, onClose, errormsg, start_card, end_card, studentId } = props;


    return (
        <Segment>
            <Header as='h1'>ไม่สามารถดำเนินการต่อได้เนื่องจากคุณเลือก Do Not Grant</Header>
        </Segment>

    );

}




DoNotGrant = reduxForm({
    // a unique name for the form
    form: "PDPA",
    enableReinitialize: true,
})(DoNotGrant);

const selector = formValueSelector("class");
DoNotGrant = connect(state => {
    const start_card = selector(state, "start_card");
    const end_card = selector(state, "end_card");
    return {
        start_card: start_card,
        end_card: end_card
    };
})(DoNotGrant);

export default withRouter(DoNotGrant);



