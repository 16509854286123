import axios from "axios";
import * as config from "../../../config";

export function getstudentdetailProfile(Id) {
    return axios
        .get(config.urlAPI + "/getstudentdetailProfile/" + Id, config.headerCallAPI)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            return err.response;
        });
}

export function getvisitProfile(Id) {
    return axios
        .get(config.urlAPI + "/visit/" + Id, config.headerCallAPI)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            return err.response;
        });
}

export function getParentVisits(Id) {
    return axios
        .get(config.urlAPI + "/getparentvisits/" + Id)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            return err.response;
        });
}

export function getdocumentbyvisit_id(Id) {
    return axios
        .get(config.urlAPI + "/getdocumentbyvisit_id/"+Id)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            return err.response;
        });
}

export function submitApplication(data) {
    return axios
        .post(config.urlAPI + "/submitapplication", data)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            return err.response;
        });
}

export function getSubmitApplication(Id) {
    return axios
        .get(config.urlAPI + "/getinterview/"+Id)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            return err.response;
        });
}
