import React, { useState, useEffect } from "react";
import { Field, reduxForm, formValueSelector } from "redux-form";
import renderField from "../../../components/renderField";
import renderSelectField from "../../../components/renderSelectField";
import renderFileField from "../../../components/renderFileField";
import renderDateTimeField from "../../../components/renderDateTimeField";
import renderDateOnlyField from "../../../components/renderDateOnlyField";
import renderDateField from "../../../components/renderDateOnlyField";
import { Button, Form, Message, Grid } from "semantic-ui-react";
import * as config from "../../../config";
import { connect } from "react-redux";
import {
  Header,
  Image,
  Segment,
  Breadcrumb,
  Loader,
  Dimmer,
  Tab,
  Table,
  Icon,
  Label,
  FormGroup,
  Step,
} from "semantic-ui-react";
import ModalBasic from "../../../components/modal";
import FormDocument from "./formdocument";
import Wrap from "../../../components/wrap";
import { withRouter } from "react-router-dom";
// import PreviewApplication from "../previewapplication/previewapplication";
import {
  getvisitProfile,
  getstudentdetailProfile,
  addapplicationstudent,
  updateapplicationstudent,
  getinterview,
} from "./service";

const windowUrl = window.location.search;
const Id = windowUrl.split("=");
// console.log(Id[1]);

let Document = (props) => {
  const [loading, setLoading] = useState(true);
  const [visitProfiles] = useState(Id[1]);
  const [data, setData] = useState([]);
  const [currentData, setCurrentData] = useState(null);
  const [open, setOpen] = useState(false);
  const [showInterview, setShowInterview] = useState(false);

  useEffect(() => {
    setLoading(true);
    if (visitProfiles) {
      getData();
    }
  }, [visitProfiles]);

  const getData = () => {
    getstudentdetailProfile(Id[1]).then((res) => {
      if (res && res.status === 200) {
        setData(res.data);
        // console.log(res.data);
      }
      setLoading(false);
    });

    getinterview(Id[1]).then((res) => {
      if (res && res.status === 200) {
        console.log(res.data);
        if (res.data) {
          setShowInterview(true);
        } else if (!res.data) {
          setShowInterview(false);
        }
      }
      setLoading(false);
    });
  };

  // console.log(data);

  const nextPage = () => {
    props.history.push({
      pathname: "/formregister/previewregister" + props.location.search,
    });
    window.location.reload();
    // window.close();
  };

  const goPagestudent = () => {
    props.history.push({
      pathname: "/formregister/student" + props.location.search,
    });
    window.location.reload();
    // window.close();
  };

  const goPagestudent2 = () => {
    props.history.push({
      pathname: "/formregister/student2" + props.location.search,
    });
    window.location.reload();
    // window.close();
  };

  const goPageguardian = () => {
    props.history.push({
      pathname: "/formregister/guardian" + props.location.search,
    });
    window.location.reload();
    // window.close();
  };

  const goPagehealth = () => {
    props.history.push({
      pathname: "/formregister/health" + props.location.search,
    });
    window.location.reload();
    // window.close();
  };

  const goPagedocument = () => {
    props.history.push({
      pathname: "/formregister/document" + props.location.search,
    });
    window.location.reload();
    // window.close();
  };

  const goPagepreviewregister = () => {
    props.history.push({
      pathname: "/formregister/previewregister" + props.location.search,
    });
    window.location.reload();
    // window.close();
  };

  // console.log(props.location.search);

  return (
    <Segment basic textAlign="left">
      <Segment style={{ width: "100%" }}>
        <Grid columns="7">
          <Grid.Column
            style={{
              color: "#eee",
              fontSize: "13px",
              width: "25%",
              backgroundColor: "#769393",
            }}
          >
            <b style={{ textAlign: "center" }}>Application Form</b>
          </Grid.Column>
          <Grid.Column
            style={{ color: "#aaa", fontSize: "15px", width: "10%" }}
          >
            <b
              style={{ textAlign: "center", cursor: "pointer" }}
              onClick={() => goPagestudent()}
            >
              1.
            </b>
            <b style={{ paddingLeft: "20%" }}>/</b>
          </Grid.Column>
          <Grid.Column
            style={{ color: "#aaa", fontSize: "13px", width: "10%" }}
          >
            <b style={{ cursor: "pointer" }} onClick={() => goPagestudent2()}>
              2.
            </b>
            <b style={{ paddingLeft: "50%" }}>/</b>
          </Grid.Column>
          <Grid.Column
            style={{ color: "#aaa", fontSize: "13px", width: "10%" }}
          >
            <b style={{ cursor: "pointer" }} onClick={() => goPageguardian()}>
              3.
            </b>
            <b style={{ paddingLeft: "50%" }}>/</b>
          </Grid.Column>
          <Grid.Column
            style={{ color: "#aaa", fontSize: "13px", width: "10%" }}
          >
            <b style={{ cursor: "pointer" }} onClick={() => goPagehealth()}>
              4.
            </b>
            <b style={{ paddingLeft: "50%" }}>/</b>
          </Grid.Column>
          <Grid.Column
            style={{ color: "#8e8d8d", fontSize: "13px", width: "20%" }}
          >
            <b style={{ cursor: "pointer" }} onClick={() => goPagedocument()}>
              5.document
            </b>
            <b style={{ paddingLeft: "50%" }}>/</b>
          </Grid.Column>
          <Grid.Column
            style={{ color: "#aaa", fontSize: "13px", width: "10%" }}
          >
            <b
              style={{ textAlign: "center", cursor: "pointer" }}
              onClick={() => goPagepreviewregister()}
            >
              6.
            </b>
            <b style={{ paddingLeft: "50%" }}>/</b>
          </Grid.Column>
        </Grid>
      </Segment>

      <FormDocument loading={loading} initialValues={data} />

      <br></br>
      <br></br>
      <Button
        floated="right"
        content="Next Page"
        style={{
          backgroundColor: "#4682B4",
          color: "#ffffff",
          minWidth: "20%",
          marginRight: "10%",
        }}
        onClick={() => nextPage()}
      />
      <Button
        floated="left"
        content="Back"
        style={{
          backgroundColor: "#4682B4",
          color: "#ffffff",
          minWidth: "20%",
          marginLeft: "10%",
        }}
        onClick={() => goPagehealth()}
      />
      <br></br>
      <br></br>
    </Segment>
  );
};

export default withRouter(Document);
