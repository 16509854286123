import React, { useState, useEffect } from "react";
import { Field, reduxForm, formValueSelector } from "redux-form";
import renderField from "../../../components/renderField";
import renderSelectField from "../../../components/renderSelectField";
import renderFileField from "../../../components/renderFileField";
import renderDateTimeField from "../../../components/renderDateTimeField";
import renderDateOnlyField from "../../../components/renderDateOnlyField";
import renderCheckboxGroupField from "../../../components/renderCheckboxGroupField";
import renderCheckbox from "../../../components/renderCheckboxField";
import { Button, Form, Message, Grid, Card } from "semantic-ui-react";
import SecondButton from "../../../components/secondbutton";
import * as config from "../../../config";
import * as config2 from "../../../components/config";
import { connect } from "react-redux";
import {
  Header,
  Image,
  Segment,
  Breadcrumb,
  Loader,
  Dimmer,
  Tab,
  Table,
  Icon,
  Label,
  FormGroup,
} from "semantic-ui-react";
import renderDateField from "../../../components/renderDateOnlyField";
import { withRouter } from "react-router-dom";
import Wrap from "../../../components/wrap";
import ModalBasic from "../../../components/modal";
import FileBirthCertificate from "./filebirthcertification";
import FileHomeRegistation from "./filehomeregistation";
import FileFather from "./filefather";
import FileMother from "./filemother";
import FileGuardian1 from "./fileguardian1";
import FileGuardian2 from "./fileguardian2";
import FileHealth from "./filehealth";
import { getdocument } from "./service";

const windowUrl = window.location.search;
const Id = windowUrl.split("=");
// console.log(Id[1]);

let FormDocument = (props) => {
  // console.log(props);
  const [loading, setLoading] = useState(true);
  const [currentData, setCurrentData] = useState(null);
  const [openType1, setOpenType1] = useState(false);
  const [openType2, setOpenType2] = useState(false);
  const [openType3, setOpenType3] = useState(false);
  const [openType4, setOpenType4] = useState(false);
  const [openType5, setOpenType5] = useState(false);
  const [openType6, setOpenType6] = useState(false);
  const [openType7, setOpenType7] = useState(false);
  const [document, setDocument] = useState([]);

  useEffect(() => {
    setLoading(true);
    if (Id[1]) {
      getData();
    }
  }, [Id[1]]);

  const getData = () => {
    getdocument(Id[1]).then((res) => {
      console.log("doc: " + res.data);
      if (res && res.status === 200) {
        setDocument(res.data);
      }
      setLoading(false);
    });
  };

  // doc1
  const onEditType1 = (values) => {
    setOpenType1(true);
    setCurrentData(values);
  };
  const onCloseType1 = () => {
    setOpenType1(false);
    setCurrentData(null);
  };
  const updateDataType1 = (values) => {
    setLoading(true);
    let document = values;
  };

  // doc2
  const onEditType2 = (values) => {
    setOpenType2(true);
    setCurrentData(values);
  };
  const onCloseType2 = () => {
    setOpenType2(false);
    setCurrentData(null);
  };
  const updateDataType2 = (values) => {
    setLoading(true);
    let document = values;
  };

  // doc3
  const onEditType3 = (values) => {
    setOpenType3(true);
    setCurrentData(values);
  };
  const onCloseType3 = () => {
    setOpenType3(false);
    setCurrentData(null);
  };
  const updateDataType3 = (values) => {
    setLoading(true);
    let document = values;
  };

  // doc4
  const onEditType4 = (values) => {
    setOpenType4(true);
    setCurrentData(values);
  };
  const onCloseType4 = () => {
    setOpenType4(false);
    setCurrentData(null);
  };
  const updateDataType4 = (values) => {
    setLoading(true);
    let document = values;
  };

  // doc5
  const onEditType5 = (values) => {
    setOpenType5(true);
    setCurrentData(values);
  };
  const onCloseType5 = () => {
    setOpenType5(false);
    setCurrentData(null);
  };
  const updateDataType5 = (values) => {
    setLoading(true);
    let document = values;
  };

  // doc6
  const onEditType6 = (values) => {
    setOpenType6(true);
    setCurrentData(values);
  };
  const onCloseType6 = () => {
    setOpenType6(false);
    setCurrentData(null);
  };
  const updateDataType6 = (values) => {
    setLoading(true);
    let document = values;
  };

  // doc7
  const onEditType7 = (values) => {
    setOpenType7(true);
    setCurrentData(values);
  };
  const onCloseType7 = () => {
    setOpenType7(false);
    setCurrentData(null);
  };
  const updateDataType7 = (values) => {
    setLoading(true);
    let document = values;
  };
  // console.log(props);
  console.log(document);

  return (
    <Segment basic textAlign="left" style={{ backgroundColor: "#eee" }}>
      <Segment>
        <Image
          src="../storage/imgs/KV-logo.png"
          size="tiny"
          spaced
          floated="right"
        />

        <Header as="h4">
          <u>Part V</u> : Sumitted Document (Digital File)
        </Header>
        <div style={{ paddingLeft: "20px" }}>
          <Grid divided stackable>
            <Grid.Row>
              <Grid.Column>
                <Segment style={{ margin: "10px" }}>
                  <Header as="h4">Student's Birth Certificate</Header>
                  <Grid columns="2" style={{ width: "70%" }}>
                    <Grid.Column style={{ paddingLeft: "40px" }}>
                      {document.docType1 != null && (
                        <>
                          <a href={document.docType1 + "?time=" + new Date()}>
                            <Image
                              width={"25%"}
                              verticalAlign="middle"
                              src={
                                document.docType1
                                  ? document.docType1 + "?time=" + new Date()
                                  : "../storage/imgs/doc.png"
                              }
                              onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = "../storage/imgs/doc.png";
                              }}
                            />
                          </a>
                          <Button
                            content="Edit File"
                            onClick={() => onEditType1(document)}
                            style={{
                              backgroundColor: config.colorTheme,
                              color: "#ffffff",
                              minWidth: "10%",
                              marginLeft: "10%",
                            }}
                          ></Button>
                        </>
                      )}
                      {document.docType1 == null && (
                        <>
                          <p style={{ color: "red" }}>File is not uploaded</p>
                          <Button
                            content="Upload File"
                            onClick={() => onEditType1(document)}
                            style={{
                              backgroundColor: config.colorTheme,
                              color: "#ffffff",
                              minWidth: "10%",
                              marginLeft: "10%",
                            }}
                          ></Button>
                        </>
                      )}
                    </Grid.Column>
                  </Grid>
                </Segment>

                <Segment style={{ margin: "10px" }}>
                  <Header as="h4">Home Registation</Header>
                  <Grid columns="2" style={{ width: "70%" }}>
                    <Grid.Column style={{ paddingLeft: "40px" }}>
                      {document.docType2 != null && (
                        <>
                          <a href={document.docType2 + "?time=" + new Date()}>
                            <Image
                              width={"25%"}
                              verticalAlign="middle"
                              src={
                                document.docType2
                                  ? document.docType2 + "?time=" + new Date()
                                  : "../storage/imgs/doc.png"
                              }
                              onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = "../storage/imgs/doc.png";
                              }}
                            />
                          </a>
                          <Button
                            content="Edit File"
                            onClick={() => onEditType2(document)}
                            style={{
                              backgroundColor: config.colorTheme,
                              color: "#ffffff",
                              minWidth: "10%",
                              marginLeft: "10%",
                            }}
                          ></Button>
                        </>
                      )}
                      {document.docType2 == null && (
                        <>
                          <p style={{ color: "red" }}>File is not uploaded</p>
                          <Button
                            content="Upload File"
                            onClick={() => onEditType2(document)}
                            style={{
                              backgroundColor: config.colorTheme,
                              color: "#ffffff",
                              minWidth: "10%",
                              marginLeft: "10%",
                            }}
                          ></Button>
                        </>
                      )}
                    </Grid.Column>
                  </Grid>
                </Segment>

                <Segment style={{ margin: "10px" }}>
                  <Header as="h4">Father ID / Passport</Header>
                  <Grid columns="2" style={{ width: "70%" }}>
                    <Grid.Column style={{ paddingLeft: "40px" }}>
                      {document.docType3 != null && (
                        <>
                          <a href={document.docType3 + "?time=" + new Date()}>
                            <Image
                              width={"25%"}
                              verticalAlign="middle"
                              src={
                                document.docType3
                                  ? document.docType3 + "?time=" + new Date()
                                  : "../storage/imgs/doc.png"
                              }
                              onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = "../storage/imgs/doc.png";
                              }}
                            />
                          </a>
                          <Button
                            content="Edit File"
                            onClick={() => onEditType3(document)}
                            style={{
                              backgroundColor: config.colorTheme,
                              color: "#ffffff",
                              minWidth: "10%",
                              marginLeft: "10%",
                            }}
                          ></Button>
                        </>
                      )}
                      {document.docType3 == null && (
                        <>
                          <p style={{ color: "red" }}>File is not uploaded</p>
                          <Button
                            content="Upload File"
                            onClick={() => onEditType3(document)}
                            style={{
                              backgroundColor: config.colorTheme,
                              color: "#ffffff",
                              minWidth: "10%",
                              marginLeft: "10%",
                            }}
                          ></Button>
                        </>
                      )}
                    </Grid.Column>
                  </Grid>
                </Segment>

                <Segment style={{ margin: "10px" }}>
                  <Header as="h4">Mother ID / Passport</Header>
                  <Grid columns="2" style={{ width: "70%" }}>
                    <Grid.Column style={{ paddingLeft: "40px" }}>
                      {document.docType4 != null && (
                        <>
                          <a href={document.docType4 + "?time=" + new Date()}>
                            <Image
                              width={"25%"}
                              verticalAlign="middle"
                              src={
                                document.docType4
                                  ? document.docType4 + "?time=" + new Date()
                                  : "../storage/imgs/doc.png"
                              }
                              onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = "../storage/imgs/doc.png";
                              }}
                            />
                          </a>
                          <Button
                            content="Edit File"
                            onClick={() => onEditType4(document)}
                            style={{
                              backgroundColor: config.colorTheme,
                              color: "#ffffff",
                              minWidth: "10%",
                              marginLeft: "10%",
                            }}
                          ></Button>
                        </>
                      )}
                      {document.docType4 == null && (
                        <>
                          <p style={{ color: "red" }}>File is not uploaded</p>
                          <Button
                            content="Upload File"
                            onClick={() => onEditType4(document)}
                            style={{
                              backgroundColor: config.colorTheme,
                              color: "#ffffff",
                              minWidth: "10%",
                              marginLeft: "10%",
                            }}
                          ></Button>
                        </>
                      )}
                    </Grid.Column>
                  </Grid>
                </Segment>

                <Segment style={{ margin: "10px" }}>
                  <Header as="h4">Guardian 1 ID / Passport</Header>
                  <Grid columns="2" style={{ width: "70%" }}>
                    <Grid.Column style={{ paddingLeft: "40px" }}>
                      {document.docType5 != null && (
                        <>
                          <a href={document.docType5 + "?time=" + new Date()}>
                            <Image
                              width={"25%"}
                              verticalAlign="middle"
                              src={
                                document.docType5
                                  ? document.docType5 + "?time=" + new Date()
                                  : "../storage/imgs/doc.png"
                              }
                              onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = "../storage/imgs/doc.png";
                              }}
                            />
                          </a>
                          <Button
                            content="Edit File"
                            onClick={() => onEditType5(document)}
                            style={{
                              backgroundColor: config.colorTheme,
                              color: "#ffffff",
                              minWidth: "10%",
                              marginLeft: "10%",
                            }}
                          ></Button>
                        </>
                      )}
                      {document.docType5 == null && (
                        <>
                          <p style={{ color: "red" }}>File is not uploaded</p>
                          <Button
                            content="Upload File"
                            onClick={() => onEditType5(document)}
                            style={{
                              backgroundColor: config.colorTheme,
                              color: "#ffffff",
                              minWidth: "10%",
                              marginLeft: "10%",
                            }}
                          ></Button>
                        </>
                      )}
                    </Grid.Column>
                  </Grid>
                </Segment>

                <Segment style={{ margin: "10px" }}>
                  <Header as="h4">Guardian 2 ID / Passport</Header>
                  <Grid columns="2" style={{ width: "70%" }}>
                    <Grid.Column style={{ paddingLeft: "40px" }}>
                      {document.docType6 != null && (
                        <>
                          <a href={document.docType6 + "?time=" + new Date()}>
                            <Image
                              width={"25%"}
                              verticalAlign="middle"
                              src={
                                document.docType6
                                  ? document.docType6 + "?time=" + new Date()
                                  : "../storage/imgs/doc.png"
                              }
                              onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = "../storage/imgs/doc.png";
                              }}
                            />
                          </a>
                          <Button
                            content="Edit File"
                            onClick={() => onEditType6(document)}
                            style={{
                              backgroundColor: config.colorTheme,
                              color: "#ffffff",
                              minWidth: "10%",
                              marginLeft: "10%",
                            }}
                          ></Button>
                        </>
                      )}
                      {document.docType6 == null && (
                        <>
                          <p style={{ color: "red" }}>File is not uploaded</p>
                          <Button
                            content="Upload File"
                            onClick={() => onEditType6(document)}
                            style={{
                              backgroundColor: config.colorTheme,
                              color: "#ffffff",
                              minWidth: "10%",
                              marginLeft: "10%",
                            }}
                          ></Button>
                        </>
                      )}
                    </Grid.Column>
                  </Grid>
                </Segment>

                <Segment style={{ margin: "10px" }}>
                  <Header as="h4">Health Report Signed</Header>
                  <Grid columns="2" style={{ width: "70%" }}>
                    <Grid.Column style={{ paddingLeft: "40px" }}>
                      {document.docType7 != null && (
                        <>
                          <a href={document.docType7 + "?time=" + new Date()}>
                            <Image
                              width={"25%"}
                              verticalAlign="middle"
                              src={
                                document.docType7
                                  ? document.docType7 + "?time=" + new Date()
                                  : "../storage/imgs/doc.png"
                              }
                              onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = "../storage/imgs/doc.png";
                              }}
                            />
                          </a>
                          <Button
                            content="Edit File"
                            onClick={() => onEditType7(document)}
                            style={{
                              backgroundColor: config.colorTheme,
                              color: "#ffffff",
                              minWidth: "10%",
                              marginLeft: "10%",
                            }}
                          ></Button>
                        </>
                      )}
                      {document.docType7 == null && (
                        <>
                          <p style={{ color: "red" }}>File is not uploaded</p>
                          <Button
                            content="Upload File"
                            onClick={() => onEditType7(document)}
                            style={{
                              backgroundColor: config.colorTheme,
                              color: "#ffffff",
                              minWidth: "10%",
                              marginLeft: "10%",
                            }}
                          ></Button>
                        </>
                      )}
                    </Grid.Column>
                  </Grid>
                </Segment>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>

        <ModalBasic
          closeIcon={true}
          open={openType1}
          size="big"
          close={() => onCloseType1()}
        >
          <FileBirthCertificate
            loading={loading}
            initialValues={currentData}
            onClose={() => onCloseType1()}
            onSubmit={updateDataType1}
          />
        </ModalBasic>

        <ModalBasic
          closeIcon={true}
          open={openType2}
          size="big"
          close={() => onCloseType2()}
        >
          <FileHomeRegistation
            loading={loading}
            initialValues={currentData}
            onClose={() => onCloseType2()}
            onSubmit={updateDataType2}
          />
        </ModalBasic>

        <ModalBasic
          closeIcon={true}
          open={openType3}
          size="big"
          close={() => onCloseType3()}
        >
          <FileFather
            loading={loading}
            initialValues={currentData}
            onClose={() => onCloseType3()}
            onSubmit={updateDataType3}
          />
        </ModalBasic>

        <ModalBasic
          closeIcon={true}
          open={openType4}
          size="big"
          close={() => onCloseType4()}
        >
          <FileMother
            loading={loading}
            initialValues={currentData}
            onClose={() => onCloseType4()}
            onSubmit={updateDataType4}
          />
        </ModalBasic>

        <ModalBasic
          closeIcon={true}
          open={openType5}
          size="big"
          close={() => onCloseType5()}
        >
          <FileGuardian1
            loading={loading}
            initialValues={currentData}
            onClose={() => onCloseType5()}
            onSubmit={updateDataType5}
          />
        </ModalBasic>

        <ModalBasic
          closeIcon={true}
          open={openType6}
          size="big"
          close={() => onCloseType6()}
        >
          <FileGuardian2
            loading={loading}
            initialValues={currentData}
            onClose={() => onCloseType6()}
            onSubmit={updateDataType6}
          />
        </ModalBasic>

        <ModalBasic
          closeIcon={true}
          open={openType7}
          size="big"
          close={() => onCloseType7()}
        >
          <FileHealth
            loading={loading}
            initialValues={currentData}
            onClose={() => onCloseType7()}
            onSubmit={updateDataType7}
          />
        </ModalBasic>
      </Segment>
    </Segment>
  );
};

FormDocument = reduxForm({
  // a unique name for the form
  form: "FormStudentDetail2",
  enableReinitialize: true,
})(FormDocument);

const selector = formValueSelector("class");
FormDocument = connect((state) => {
  const start_card = selector(state, "start_card");
  const end_card = selector(state, "end_card");
  return {
    start_card: start_card,
    end_card: end_card,
  };
})(FormDocument);

export default withRouter(FormDocument);
