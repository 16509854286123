import React, { useState, useEffect } from "react";
import { Field, reduxForm, formValueSelector } from "redux-form";
import renderField from "../../../components/renderField";
import renderSelectField from "../../../components/renderSelectField";
import renderFileField from "../../../components/renderFileField";
import renderDateTimeField from "../../../components/renderDateTimeField";
import renderDateOnlyField from "../../../components/renderDateOnlyField";
import { Button, Form, Message, Grid, Card } from "semantic-ui-react";
import SecondButton from "../../../components/secondbutton";
import * as config from "../../../config";
import { connect } from "react-redux";
import {
  Header,
  Image,
  Segment,
  Breadcrumb,
  Loader,
  Dimmer,
  Tab,
  Table,
  Icon,
  Label,
  FormGroup,
} from "semantic-ui-react";
import renderDateField from "../../../components/renderDateOnlyField";
import { withRouter } from "react-router-dom";
import Wrap from "../../../components/wrap";
import {
  getSibling,
  addsibling,
  updatesibling,
  getstudentdetailProfile,
} from "./service";
import ModalBasic from "../../../components/modal";
import FormEmergency from "./formemergency";
import FormEditSibling from "./formeditsibling";
import Emergency from "./emergency";

const windowUrl = window.location.search;
const Id = windowUrl.split("=");
// console.log(Id[1]);

let Sibling = (props) => {
  const [Sibling] = useState(Id[1]);
  const [loading, setLoading] = useState(true);
  const [dataStudent, setDataStudent] = useState([]);
  const [studentSibling2, setStudentSibling2] = useState([]);
  const [showdata, setShowData] = useState(false);
  const [currentData, setCurrentData] = useState(null);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setLoading(true);
    if (Sibling) {
      getData();
    }
  }, [Sibling]);

  const getData = () => {
    getstudentdetailProfile(Sibling).then((res) => {
      if (res && res.status === 200) {
        setDataStudent(res.data);
        console.log(res.data);
      }
      setLoading(false);
    });

    getSibling(Sibling).then((res) => {
      if (res && res.status === 200) {
        setStudentSibling2(res.data);
        if (res.data.length == 0) {
          setShowData(true);
        }
        // console.log(res.data);
      }
      setLoading(false);
    });
  };

  const onEdit = (values) => {
    setOpen(true);
    setCurrentData(values);
  };
  const onClose = () => {
    setOpen(false);
    setCurrentData(null);
  };

  const updateData = (values) => {
    setLoading(true);
    let studentSibling = values;
    updatesibling(values).then((res) => {
      console.log(res);
      if (res && res.status === 200) {
        getData();
        setOpen(false);
      }
      setCurrentData(null);
      setLoading(false);
    });
  };
  console.log(studentSibling2);
  // console.log(data.emergency_name);

  return (
    <>
      <Grid>
        {studentSibling2 &&
          studentSibling2.map((item) => (
            <Grid.Row>
              <Grid columns="5">
                <Grid.Column columns="3">
                  <b>Name</b>
                </Grid.Column>
                <Grid.Column columns="2">
                  <b>Age</b>
                </Grid.Column>
                <br />
                <Grid.Column columns="3">{item.name1}</Grid.Column>
                <Grid.Column columns="1">{item.age1}</Grid.Column>
                <br />
                <Grid.Column columns="3">{item.name2}</Grid.Column>
                <Grid.Column columns="1">{item.age2}</Grid.Column>
                <br />
                <Grid.Column columns="3">{item.name3}</Grid.Column>
                <Grid.Column columns="1">{item.age3}</Grid.Column>
                <br />
                <Grid.Column columns="3">{item.name4}</Grid.Column>
                <Grid.Column columns="1">{item.age4}</Grid.Column>
                <br />
                <br />
                <Grid.Column columns="1">
                  <Button
                    floated="left"
                    content="Sibling"
                    onClick={() => onEdit(item)}
                    style={{
                      backgroundColor: config.colorTheme,
                      color: "#ffff",
                      minWidth: "5%",
                    }}
                  />
                </Grid.Column>
              </Grid>
            </Grid.Row>
          ))}
      </Grid>
      <Emergency />

      <ModalBasic
        closeIcon={true}
        open={open}
        size="big"
        close={() => onClose()}
      >
        <FormEditSibling
          loading={loading}
          initialValues={currentData}
          onClose={() => onClose()}
          onSubmit={updateData}
        />
      </ModalBasic>
    </>
  );
};

Sibling = reduxForm({
  // a unique name for the form
  form: "Sibling",
  enableReinitialize: true,
})(Sibling);

const selector = formValueSelector("class");
Sibling = connect((state) => {
  const start_card = selector(state, "start_card");
  const end_card = selector(state, "end_card");
  return {
    start_card: start_card,
    end_card: end_card,
  };
})(Sibling);

export default withRouter(Sibling);
