import React, { useState, useEffect } from "react";
import { Field, reduxForm, formValueSelector } from "redux-form";
import renderField from "../../../components/renderField";
import renderSelectField from "../../../components/renderSelectField";
import renderFileField from "../../../components/renderFileField";
import renderDateTimeField from "../../../components/renderDateTimeField";
import renderDateOnlyField from "../../../components/renderDateOnlyField";
import renderDateField from "../../../components/renderDateOnlyField";
import { Button, Form, Message, Grid } from "semantic-ui-react";
import * as config from "../../../config";
import { connect } from "react-redux";
import {
  Header,
  Image,
  Segment,
  Breadcrumb,
  Loader,
  Dimmer,
  Tab,
  Table,
  Icon,
  Label,
  FormGroup,
  Step,
} from "semantic-ui-react";
import ModalBasic from "../../../components/modal";
import FormDocument from "./formdocument";
import Wrap from "../../../components/wrap";
import { withRouter } from "react-router-dom";
import { getstudentdetailProfile } from "./service";

const windowUrl = window.location.search;
const Id = windowUrl.split("=");

let DocmentApplication = (props) => {
  const [loading, setLoading] = useState(true);
  const [visitProfiles] = useState(Id[1]);
  const [data, setData] = useState([]);

  useEffect(() => {
    setLoading(true);
    if (visitProfiles) {
      getData();
    }
  }, [visitProfiles]);

  const getData = () => {
    getstudentdetailProfile(Id[1]).then((res) => {
      if (res && res.status === 200) {
        setData(res.data);
        // console.log(res.data);
      }
      setLoading(false);
    });
  };

  const nextPage = () => {
    props.history.push({
      pathname: "/formapplication/previewapplication" + props.location.search,
    });
    window.location.reload();
    // window.close();
  };

  return (
    <Segment basic textAlign="left">
      <FormDocument loading={loading} initialValues={data} />
      <Button
        floated="right"
        content="Next Page"
        style={{
          backgroundColor: "#4682B4",
          color: "#ffffff",
          minWidth: "20%",
          marginRight: "10%",
        }}
        onClick={() => nextPage()}
      />
      <br></br>
      <br></br>
      <br></br>
      <br></br>
    </Segment>
  );
};

export default withRouter(DocmentApplication);
