import React, { useState, useEffect } from "react";
import { Field, reduxForm, formValueSelector } from "redux-form";
import renderField from "../../../components/renderField";
import renderSelectField from "../../../components/renderSelectField";
import renderFileField from "../../../components/renderFileField";
import renderDateTimeField from "../../../components/renderDateTimeField";
import renderDateOnlyField from "../../../components/renderDateOnlyField";
import renderDateTimeField4 from "../../../components/renderDateTimeField4.js";
import { Button, Form, Message, Grid, Card } from "semantic-ui-react";
import SecondButton from "../../../components/secondbutton";
import * as config from "../../../config";
import { connect } from "react-redux";
import {
  Header,
  Image,
  Segment,
  Breadcrumb,
  Loader,
  Dimmer,
  Tab,
  Table,
  Icon,
  Label,
  FormGroup,
} from "semantic-ui-react";
import renderDateField from "../../../components/renderDateOnlyField";
import { withRouter } from "react-router-dom";
import Wrap from "../../../components/wrap";
import ModalBasic from "../../../components/modal";
import moment from "moment";

const validate = (values) => {
  const errors = {};

  if (!values.interview_date) errors.interview_date = true;
  return errors;
};

let FormPreviewRegister = (props) => {
  // const Id = props.location.search.split("=");

  const {
    loading,
    pristine,
    submitting,
    onSubmit,
    handleSubmit,
    initialValue,
    onClose,
    errormsg,
    start_card,
    end_card,
    studentId,
  } = props;
  //   const [open, setOpen] = useState(false);

  //   const showPopup = () => {
  //     setOpen(true);
  //   };
  //   const popUpClose = () => {
  //     setOpen(false);
  //     window.location.reload();
  //     window.close();
  //   };

  return (
    <center>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Header as="h3">
          <u>Appointment to Interview (นัดหมายสัมภาษณ์)</u>
        </Header>
        {/* <b>
          Appointment to Interview (นัดหมายสัมภาษณ์)
          <span style={{ color: "red" }}>*</span>
        </b> */}
        <Segment>
          <b>
            interview date<span style={{ color: "red" }}> *</span>
          </b>
          <b style={{ paddingLeft: "45%" }}>
            interview time<span style={{ color: "red" }}> *</span>
          </b>
          <Form.Group unstackable>
            <Field
              width="14"
              name="interview_date"
              required={true}
              component={renderDateTimeField4}
              minDate={moment().format("DD-MM-YYYY")}
            />
          </Form.Group>
        </Segment>
        {errormsg && (
          <Message negative>
            <Message.Content>{errormsg}</Message.Content>
          </Message>
        )}
        <Button
          floated="center"
          content="Submit"
          style={{
            backgroundColor: config.colorTheme,
            color: "#ffff",
            minWidth: "10%",
          }}
          //   onClick={() => showPopup()}
          loading={loading}
        />
      </Form>

      {/* <ModalBasic open={open} size="big" close={popUpClose}>
        <Header as="h2" style={{ textAlign: "center" }}>
          Want to confirm the submission of registration form?
        </Header>
        <center>
          <Button
            content="OK"
            style={{
              backgroundColor: config.colorTheme,
              color: "#ffffff",
              minWidth: "5%",
            }}
            onClick={() => popUpClose()}
          />
        </center>
      </ModalBasic> */}
    </center>
  );
};

FormPreviewRegister = reduxForm({
  // a unique name for the form
  form: "FormPreviewRegister",
  validate,
  enableReinitialize: true,
})(FormPreviewRegister);

// const selector = formValueSelector("class");
// FormPreviewRegister = connect(state => {
//     const start_card = selector(state, "start_card");
//     const end_card = selector(state, "end_card");
//     return {
//         start_card: start_card,
//         end_card: end_card
//     };
// })(FormPreviewRegister);

export default withRouter(FormPreviewRegister);
