import React, { Component } from "react";
// import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { Switch, Route } from "react-router-dom";

import VisitRequest from "./containers/visitrequest";

import Student from "./containers/formapplication/student/student";
import DocmentApplication from "./containers/formapplication/document/document";
import PreviewApplication from "./containers/formapplication/previewapplication/previewapplication";

import StudentRegister from "./containers/formregister/student/student";
import Student2 from "./containers/formregister/student2/student2";
import Guardian from "./containers/formregister/guardian/guardian";
import Health from "./containers/formregister/health/health";
import Document from "./containers/formregister/document/document";
import PreviewRegister from "./containers/formregister/previewRegister/previewregister";

import StudentOther from "./containers/formapplicationother/student/student";
import PreviewApplicationOther from "./containers/formapplicationother/previewapplication/previewapplication";

class Routes extends Component {
  componentDidUpdate() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div>
        <Switch>
          {/* <Route exact path="/xxx/visitrequest" component={VisitRequest} /> */}
          {/* <Route exact path="/visitrequest" component={VisitRequest} /> */}
          <Route exact path="/" component={VisitRequest} />

          {/* Form type 2 for KLC */}
          <Route exact path="/formapplication/student" component={Student} />
          <Route
            exact
            path="/formapplication/document"
            component={DocmentApplication}
          />
          <Route
            exact
            path="/formapplication/previewApplication"
            component={PreviewApplication}
          />

          {/* Form type 3 for other course */}
          <Route
            exact
            path="/formapplicationother/student"
            component={StudentOther}
          />
          <Route
            exact
            path="/formapplicationother/previewApplication"
            component={PreviewApplicationOther}
          />

          {/* Form type 1 for KV */}
          <Route
            exact
            path="/formregister/student"
            component={StudentRegister}
          />
          <Route exact path="/formregister/student2" component={Student2} />
          <Route exact path="/formregister/guardian" component={Guardian} />
          <Route exact path="/formregister/health" component={Health} />
          <Route exact path="/formregister/document" component={Document} />
          <Route
            exact
            path="/formregister/previewregister"
            component={PreviewRegister}
          />
        </Switch>
      </div>
    );
  }
}

export default Routes;
