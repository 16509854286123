import React, { useState, useEffect } from "react";
import { Field, reduxForm, formValueSelector } from "redux-form";
import renderField from "../../../components/renderField";
import renderSelectField from "../../../components/renderSelectField";
import renderFileField from "../../../components/renderFileField";
import renderDateTimeField from "../../../components/renderDateTimeField";
import renderDateOnlyField from "../../../components/renderDateOnlyField";
import renderDateField from "../../../components/renderDateOnlyField";
import { Button, Form, Message, Grid } from "semantic-ui-react";
import SecondButton from "../../../components/secondbutton";
import * as config from "../../../config";
import { connect } from "react-redux";
import {
  Header,
  Image,
  Segment,
  Breadcrumb,
  Loader,
  Dimmer,
  Tab,
  Table,
  Icon,
  Label,
  FormGroup,
  Step,
} from "semantic-ui-react";
import ModalBasic from "../../../components/modal";
import Wrap from "../../../components/wrap";

import { getParentVisits, updateImageProfile2 } from "./service";
// import FormImgParent from "./formimgparent";

const windowUrl = window.location.search;
const Id = windowUrl.split("=");
// console.log(Id[1]);

let StudentFather = (props) => {
  const [loading, setLoading] = useState(true);
  const [student_Id, setStudent_Id] = useState(Id[1]);
  const [data, setData] = useState([]);
  const [currentData, setCurrentData] = useState(null);
  const [open, setOpen] = useState(false);
  const [openImg, setOpenImg] = useState(false);
  const [photo, setPhoto] = useState(null);

  useEffect(() => {
    setLoading(true);
    getData();
  }, [student_Id]);

  const getData = () => {
    getParentVisits(Id[1]).then((res) => {
      // console.log(`getParentVisits`, res);
      if (res && res.status === 200 && res.data.length > 0) {
        setData(res.data[0]);
      }
      setLoading(false);
    });
  };

  const onEdit = (values) => {
    setOpen(true);
    setCurrentData(values);
  };
  const onClose = () => {
    setOpen(false);
    setCurrentData(null);
  };

  // Todo
  const updateImage = () => {
    setLoading(true);
    var formData = new FormData();
    if (photo) {
      if (typeof photo === "object") formData.append("photo", photo[0]);
      else formData.append("photo", photo);
    }
    updateImageProfile2(formData, data.guardian_id).then((res) => {
      if (res && res.status === 200) {
        getData();
        setOpenImg(false);
      }
      setLoading(false);
    });
  };

  const onEditImg = (values) => {
    setOpenImg(true);
    setCurrentData(values);
  };
  const onCloseImg = () => {
    setOpenImg(false);
    setCurrentData(null);
  };

  const updateDataImg = (values) => {
    setLoading(true);
    let data = values;
  };

  // console.log(data)

  return (
    <Segment basic textAlign="left">
      <Segment>
        <Header as="h4">Father</Header>
        {/* <Grid> */}
        {/* <Grid.Row> */}
        <Segment style={{ margin: "20px" }}>
          {/* <Grid columns="2" style={{ width: "70%" }}> */}
          <Grid columns="3" stackable>
            {data && data.GimageURL && (
              <>
                <Grid.Column style={{ paddingLeft: "10px" }}>
                  <Image
                    width={"25%"}
                    verticalAlign="middle"
                    src={
                      data.GimageURL
                        ? data.GimageURL + "?time=" + new Date()
                        : "../storage/imgs/dfkid.png"
                    }
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = "../storage/imgs/dfkid.png";
                    }}
                  />
                </Grid.Column>
                <Grid.Column>
                  <Button
                    content="Image"
                    // onClick={() => onEditImg(data)}
                    onClick={() => setOpenImg(true)}
                    style={{
                      backgroundColor: config.colorTheme,
                      color: "#ffffff",
                      minWidth: "10%",
                      // marginLeft: "10%",
                    }}
                  ></Button>
                </Grid.Column>
              </>
            )}
            {!data.GimageURL && (
              <>
                <Grid.Column style={{ paddingLeft: "10px" }}>
                  <Image
                    width={"25%"}
                    verticalAlign="middle"
                    src={
                      data.GimageURL
                        ? data.GimageURL + "?time=" + new Date()
                        : "../storage/imgs/dfkid.png"
                    }
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = "../storage/imgs/dfkid.png";
                    }}
                  />
                </Grid.Column>
                <Grid.Column>
                  <Button
                    content="Image"
                    // onClick={() => onEditImg(data)}
                    onClick={() => setOpenImg(true)}
                    style={{
                      backgroundColor: config.colorTheme,
                      color: "#ffffff",
                      minWidth: "10%",
                      // marginLeft: "10%",
                    }}
                  ></Button>
                </Grid.Column>
                <Grid.Column>
                  <p style={{ color: "red", marginTop: "8%" }}>
                    Please Upload Image
                  </p>
                </Grid.Column>
              </>
            )}
          </Grid>
        </Segment>
        <Grid>
          <Grid.Row>
            <Grid stackable columns="2" style={{ width: "100%" }}>
              <Grid.Column style={{ paddingLeft: "40px" }}>
                Passport or ID No. : <b>{data.idnumber}</b>
              </Grid.Column>
              <Grid.Column>
                Date Of Birth : <b>{data.dateOfBirth}</b>
              </Grid.Column>
            </Grid>
          </Grid.Row>
          <Grid.Row>
            <Grid stackable columns="4" style={{ width: "100%" }}>
              <Grid.Column style={{ paddingLeft: "40px" }}>
                firstname: <b>{data.firstName}</b>
              </Grid.Column>
              <Grid.Column>
                lastname : <b>{data.lastName}</b>
              </Grid.Column>
              <Grid.Column>
                Nationality : <b>{data.nationality}</b>
              </Grid.Column>
              <Grid.Column>
                Native Language :<b>{data.nativelanguage}</b>
              </Grid.Column>
            </Grid>
          </Grid.Row>
          <Grid.Row>
            <Grid stackable columns="4" style={{ width: "100%" }}>
              <Grid.Column style={{ paddingLeft: "40px" }}>
                ชื่อ: <b>{data.firstName_th}</b>
              </Grid.Column>
              <Grid.Column>
                นามสกุล : <b>{data.lastName_th}</b>
              </Grid.Column>
              <Grid.Column>
                mobile : <b>{data.phone}</b>
              </Grid.Column>
              <Grid.Column>
                email :<b>{data.email}</b>
              </Grid.Column>
            </Grid>
          </Grid.Row>
          <Grid.Row>
            <Grid stackable columns="4" style={{ width: "100%" }}>
              <Grid.Column style={{ paddingLeft: "40px" }}>
                Place of work: <b>{data.placeOfWork}</b>
              </Grid.Column>
              <Grid.Column>
                Position : <b>{data.position}</b>
              </Grid.Column>
              <Grid.Column>
                Office Tel : <b>{data.officePhone}</b>
              </Grid.Column>
            </Grid>
          </Grid.Row>
        </Grid>
        <br></br>
      </Segment>

      <ModalBasic
        open={openImg}
        // size="large"
        // close={() => onCloseImg()}
        close={() => setOpenImg(false)}
        closeIcon
      >
        <Grid>
          <Grid.Row>
            <Grid.Column verticalAlign="middle">
              <Form.Input
                type="file"
                name="photo"
                onChange={(e) => setPhoto(e.target.files)}
              />
              <br />
              <br />
              <Button
                floated="left"
                content="Upload"
                fluid
                style={{
                  backgroundColor: config.colorTheme,
                  color: "#ffffff",
                  minWidth: "30%",
                }}
                loading={loading}
                onClick={updateImage}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <br />
        <br />
        {/* <FormImgParent
          loading={loading}
          initialValues={currentData}
          onClose={() => onCloseImg()}
          onSubmit={updateDataImg}
        /> */}
      </ModalBasic>
    </Segment>
  );
};

export default StudentFather;
