import React, { useState, useEffect } from "react";
import { Field, reduxForm, formValueSelector } from "redux-form";
import renderField from "../../../components/renderField";
import renderSelectField from "../../../components/renderSelectField";
import renderFileField from "../../../components/renderFileField";
import renderDateTimeField from "../../../components/renderDateTimeField";
import renderDateOnlyField from "../../../components/renderDateOnlyField";
import { Button, Form, Message, Grid } from "semantic-ui-react";
import SecondButton from "../../../components/secondbutton";
import * as config from "../../../config";
import { connect } from "react-redux";
import {
  Header,
  Image,
  Segment,
  Breadcrumb,
  Loader,
  Dimmer,
  Tab,
  Table,
  Icon,
  Label,
  FormGroup,
  Card,
  Step,
} from "semantic-ui-react";
import renderDateField from "../../../components/renderDateOnlyField";
import ModalBasic from "../../../components/modal";
import Wrap from "../../../components/wrap";

import { getemergency, updateemergency } from "./service";
import FormEmergency from "./formemergency";

const windowUrl = window.location.search;
const Id = windowUrl.split("=");
// console.log(Id[1]);

let Emergency = (props) => {
  const [loading, setLoading] = useState(true);
  const [student_Id, setStudent_Id] = useState(Id[1]);
  const [data, setData] = useState([]);
  const [actMessage, setActMessage] = useState("");
  const [errormsg, setErrormsg] = useState(null);

  useEffect(() => {
    setLoading(true);
    getData();
  }, [student_Id]);

  const getData = () => {
    getemergency(student_Id).then((res) => {
      console.log(res.data);
      if (res && res.status === 200) {
        setData(res.data);
      }
      setLoading(false);
    });
  };

  const onUpdateEmergency = (values) => {
    setErrormsg(null);
    setLoading(true);
    let data = values;

    // Protect human error
    // >> Verify phone No. must be 10 digits
    if (values.emergency_phone && values.emergency_phone.length != 10) {
      setErrormsg("Invalid phone number, must be 10 digits");
      setLoading(false);
      return;
    }
    // >> Verify not allow (-) at first digit
    if (values.emergency_phone && parseInt(values.emergency_phone) < 0) {
      setErrormsg("Invalid phone number, do not allow (-) at first digit.");
      setLoading(false);
      return;
    }

    updateemergency(values).then((res) => {
      console.log(`updateemergency`, res);
      if (res && res.status === 200) {
        setActMessage("บันทึกข้อมูล Emergency Contact สำเร็จ");
        window.location.reload();
        setLoading(false);
      }
    });
  };

  return (
    <Segment>
      <Header as="h4">Emergency Contact</Header>

      {data.emergency_relation &&
        data.emergency_relation != "Father" &&
        data.emergency_relation != "Mother" && (
          <>
            <br></br>
            Name: <b>{data.emergency_name}</b>
            <br></br>
            Phone: <b>{data.emergency_phone}</b>
            <br></br>
            Relationship: <b>{data.emergency_relation}</b>
            <br></br>
          </>
        )}
      <br></br>
      {!data.emergency_relation && (
        <FormEmergency
          loading={loading}
          initialValues={Id[1]}
          onSubmit={onUpdateEmergency}
          errormsg={errormsg}
          actMessage={actMessage}
        />
      )}
    </Segment>
  );
};

export default Emergency;
