import React, { useState, useEffect, useRef } from "react";
import { Field, reduxForm, formValueSelector } from "redux-form";
import renderField from "../components/renderField";
import renderSelectField from "../components/renderSelectField";
import renderFileField from "../components/renderFileField";
import renderDateTimeField3 from "../components/renderDateTimeField3";
import renderDateOnlyField from "../components/renderDateOnlyField";
import { Button, Form, Message, Grid } from "semantic-ui-react";
import SecondButton from "../components/secondbutton";
import * as config from "../config";
import { Header, Segment } from "semantic-ui-react";
import renderDateField from "../components/renderDateOnlyField";
import ModalBasic from "../components/modal";
import { getdatavisitrequest } from "./service";
import moment from "moment";

const validate = (values) => {
  const errors = {};
  if (!values.id_campus) errors.id_campus = true;
  //   if (!values.id_program) errors.id_program = true;
  if (!values.parent_firstName) errors.parent_firstName = true;
  // if (!values.parent_lastName) errors.parent_lastName = true;
  if (!values.mobile) errors.mobile = true;
  // if (!values.child_firstname) errors.child_firstname = true;
  // if (!values.child_lastname) errors.child_lastname = true;
  if (!values.nickname) errors.nickname = true;
  // if (!values.dateOfBirth) errors.dateOfBirth = true;
  // if (!values.gender) errors.gender = true;
  if (!values.dateTimeOfVisit) errors.dateTimeOfVisit = true;

  return errors;
};

// const maxLength = (max) => (value) =>
//   value && value.length > max ? `Must be ${max} characters or less` : undefined;
// const maxLength30 = maxLength(30);
// const maxLength15 = maxLength(15);
// // const maxLength10 = maxLength(10);
// const phoneNumber = (value) =>
//   // value && !/^(0|[1-9][0-9]{9})$/i.test(value)
//   value && !/^(0|[0-9][0-9]{9})$/i.test(value)
//     ? "Invalid phone number, must be 10 digits"
//     : undefined;

let FormVisitRequest = (props) => {
  const {
    loading,
    pristine,
    submitting,
    handleSubmit,
    onSubmit,
    start,
    end,
    onClose,
    errormsg,
    initialValue,
    emptyForm,
    visitNo,
  } = props;

  // const [open, setOpen] = useState(false);
  const [visit_no, setvisit_no] = useState([]);
  const [loadVisitNo, setLoadVisitNo] = useState(false);

  // const showPopup = () => {
  //   setOpen(true);
  // };
  // const popUpClose = () => {
  //   setOpen(false);
  //   window.location.reload();
  //   window.close();
  // };

  useEffect(() => {
    if (visitNo.length > 0 && !loadVisitNo) {
      setvisit_no(visitNo);
      setLoadVisitNo(true);
      console.log(`visit`, visitNo);
    }
  });

  // useEffect(() => {
  //   if (1) {
  //     getData();
  //   }
  // }, 1);

  // const getData = () => {
  //   getdatavisitrequest().then((res) => {
  //     if (res && res.status === 200) {
  //       setvisit_no(res.data.visit);
  //     }
  //   });
  // };
  console.log(`form`, visit_no.Id);

  return (
    <Form
      style={{ width: "100%", marginBottom: "10%" }}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Header
        as="h2"
        style={{ backgroundColor: "#80561B", color: "#fff", padding: "1%" }}
      >
        School Visit Request (นัดหมายเยี่ยมชมโรงเรียน)
      </Header>
      <br></br>
      <Segment>
        <Header as="h4" style={{ paddingLeft: "10px", textAlign: "left" }}>
          {emptyForm ? "" : "Number of visitor :" + visitNo}
          {/* Number of visitor : V0000{visit_no.Id + 1} */}
        </Header>
        <div style={{ paddingLeft: "20px", textAlign: "left" }}>
          <Grid divided stackable>
            <Grid.Row>
              <Grid.Column>
                {errormsg && (
                  <Message negative>
                    <Message.Content>{errormsg}</Message.Content>
                  </Message>
                )}
                <u>
                  <b>Parent's Information</b>
                </u>
                <Form.Group unstackable>
                  <Field
                    width="16"
                    name="parent_firstName"
                    type="text"
                    required={true}
                    component={renderField}
                    label="firstname(ชื่อผู้ปกครอง)"
                    // validate={[maxLength30]}
                    readOnly={!emptyForm}
                  />
                </Form.Group>
                <Form.Group unstackable>
                  <Field
                    width="16"
                    name="mobile"
                    type="number"
                    required={true}
                    component={renderField}
                    label="Phone (มือถือ)"
                    // validate={[phoneNumber]}
                    // validate={[maxLength10]}
                    readOnly={!emptyForm}
                  />
                </Form.Group>
                <br></br>
                <u>
                  <b>Child's Information</b>
                </u>
                <Form.Group unstackable>
                  <Field
                    required={true}
                    width="16"
                    name="id_campus"
                    component={renderSelectField}
                    label="Campus (ชื่อโรงเรียน)"
                    options={config.options_campus}
                    readOnly={!emptyForm}
                  />
                </Form.Group>
                <Form.Group unstackable>
                  <Field
                    required={false}
                    width="16"
                    name="id_program"
                    component={renderSelectField}
                    label="Program (ชื่อหลักสูตร)"
                    options={config.options_program}
                    readOnly={!emptyForm}
                  />
                </Form.Group>
                <Form.Group unstackable>
                  <Field
                    width="16"
                    name="nickname"
                    type="text"
                    required={true}
                    component={renderField}
                    label="Nickname(ชื่อเล่นนักเรียน)"
                    // validate={[maxLength15]}
                    readOnly={!emptyForm}
                  />
                </Form.Group>
                <b>
                  Appointment to visit (นัดหมายเยี่ยมชม)
                  <span style={{ color: "red" }}>*</span>
                </b>
                <Form.Group unstackable>
                  <Field
                    width="16"
                    name="dateTimeOfVisit"
                    required={true}
                    component={renderDateTimeField3}
                    minDate={moment().format("DD-MM-YYYY")}
                    readOnly={!emptyForm}
                  />
                </Form.Group>
                <p style={{ color: "red" }}>
                  Convenient day and time for visited Tuesday- Thursday, 09:30
                  or 10:30 (ปกตินัดหมายเยี่ยมชมวันอังคาร - วันพฤหัสบดี เวลา
                  9:30น. หรือ 10:30น.)
                </p>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          {emptyForm ? (
            <Button
              floated="left"
              content="Submit"
              style={{
                backgroundColor: config.colorTheme,
                color: "#ffffff",
                minWidth: "30%",
              }}
              loading={loading}
              // onClick={() => showPopup()}
            />
          ) : (
            ""
          )}
          {/* <Button
            floated="left"
            content="Submit"
            style={{
              backgroundColor: config.colorTheme,
              color: "#ffffff",
              minWidth: "30%",
            }}
            onClick={() => showPopup()}
          /> */}
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
        </div>
      </Segment>

      {/* <ModalBasic open={open} size="big" close={popUpClose}>
        <Header as="h2" style={{ textAlign: "center" }}>
          ทำการลงทะเบียนเสร็จเรียบร้อย
        </Header>
        <center>
          <Button
            content="OK"
            style={{
              backgroundColor: config.colorTheme,
              color: "#ffffff",
              minWidth: "5%",
            }}
            onClick={() => popUpClose()}
          />
        </center>
      </ModalBasic> */}
    </Form>
  );
};

FormVisitRequest = reduxForm({
  form: "visit",
  validate,
  enableReinitialize: true,
})(FormVisitRequest);

export default FormVisitRequest;
