import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { Segment, Header } from "semantic-ui-react";
import FormVisitRequest from "./formvisitrequest";
import { postVisitRequest } from "./service";
import ModalBasic from "../components/modal";
import { Button } from "semantic-ui-react";
import * as config from "../config";

let VisitRequest = (props) => {
  const [errormsg, setErrormsg] = useState(null);
  const [open, setOpen] = useState(false);
  const [data, setData] = useState([]);
  const [visitNo, setVisitNo] = useState("");
  const [emptyForm, setEmptyForm] = useState(true);
  const [loading, setLoading] = useState(false);

  const postData = (values) => {
    setErrormsg(null);
    setLoading(true);
    let data = values;

    // Protect human error
    // >> Verify phone No. must be 10 digits
    if (values.mobile && values.mobile.length != 10) {
      setErrormsg("Invalid phone number, must be 10 digits");
      setLoading(false);
      return;
    }
    // >> Verify not allow (-) at first digit
    if (values.mobile && parseInt(values.mobile) < 0) {
      setErrormsg("Invalid phone number, do not allow (-) at first digit.");
      setLoading(false);
      return;
    }

    // console.log(`postData`, data);
    postVisitRequest(values).then((res) => {
      if (res && res.status === 201) {
        // console.log(`insert`, res);
        setEmptyForm(false);
        setData(res.data.request);
        setVisitNo(res.data.visitno);
        // console.log(`visitno`, res.data.visitno);
        setLoading(false);
        setOpen(true);
        // window.location.reload();
      }
    });
  };

  const popUpClose = () => {
    setOpen(false);
    // window.location.reload();
    // window.close();
  };

  return (
    <Segment style={{ backgroundColor: "#eee" }}>
      <FormVisitRequest
        onSubmit={postData}
        errormsg={errormsg}
        initialValue={data}
        emptyForm={emptyForm}
        visitNo={visitNo}
        loading={loading}
      />

      <ModalBasic open={open} size="big" close={popUpClose}>
        <Header as="h2" style={{ textAlign: "center" }}>
          ทำการลงทะเบียนเสร็จเรียบร้อย
        </Header>
        <center>
          <Button
            content="OK"
            style={{
              backgroundColor: config.colorTheme,
              color: "#ffffff",
              minWidth: "5%",
            }}
            onClick={() => popUpClose()}
          />
        </center>
      </ModalBasic>
    </Segment>
  );
};

export default withRouter(VisitRequest);
