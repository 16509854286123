import React, { Component } from "react";
import "./App.css";
import "semantic-ui-css/semantic.min.css";
import VisitRequest from "./containers/visitrequest";
import Routes from "./routes";

class App extends Component {
  render() {
    return (
      <div className="App">
        <Routes />
      </div>
    );
  }
}

export default App;
