import React, { useState, useEffect } from "react";
import { Field, reduxForm, formValueSelector } from "redux-form";
import renderField from "../../../components/renderField";
import renderSelectField from "../../../components/renderSelectField";
import renderFileField from "../../../components/renderFileField";
import renderDateTimeField from "../../../components/renderDateTimeField";
import renderDateOnlyField from "../../../components/renderDateOnlyField";
import renderDateField from "../../../components/renderDateOnlyField";
import { Button, Form, Message, Grid } from "semantic-ui-react";
import * as config from "../../../config";
import { connect } from "react-redux";
import {
  Header,
  Image,
  Segment,
  Breadcrumb,
  Loader,
  Dimmer,
  Tab,
  Table,
  Icon,
  Label,
  FormGroup,
  Card,
  Step,
} from "semantic-ui-react";
import ModalBasic from "../../../components/modal";
import Wrap from "../../../components/wrap";
import { withRouter } from "react-router-dom";

import {
  getvisitProfile,
  getstudentdetailProfile,
  getfather,
  getmother,
  getSibling2,
  getguardian1,
  getguardian2,
  getimmunization_gethealthCheckStudents_health,
  getdocumentbyvisit_id,
  addinterview,
  getinterview,
} from "./service";
import FormPreviewRegister from "./frompreviewregister";
import PreviewStudent from "./previewstudent";

const windowUrl = window.location.search;
const Id = windowUrl.split("=");
// console.log(Id[1]);

let PreviewRegister = (props) => {
  const [loading, setLoading] = useState(true);
  const [visitProfiles] = useState(Id[1]);
  const [data, setData] = useState([]);
  const [dataFather, setDataFather] = useState([]);
  const [dataMother, setDataMother] = useState([]);
  const [dataGuardian1, setDataGuardian1] = useState([]);
  const [dataGuardian2, setDataGuardian2] = useState([]);
  const [studentSibling2, setStudentSibling2] = useState([]);
  const [
    immunization_gethealthCheckStudents_health,
    setimmunization_gethealthCheckStudents_health,
  ] = useState([]);
  const [document, setDocument] = useState([]);
  const [visitData, setVisitData] = useState([]);
  const [interview, setInterview] = useState([]);
  const [studentYet, setStudentYet] = useState(false);
  const [saveYet, setSaveYet] = useState(false);
  const [currentData, setCurrentData] = useState(null);
  const [open1, setOpen1] = useState(true);

  useEffect(() => {
    setLoading(true);
    if (visitProfiles) {
      getData();
    }
  }, [visitProfiles]);

  const getData = () => {
    getvisitProfile(Id[1]).then((res) => {
      if (res && res.status === 200) {
        setVisitData(res.data);
      }
      setLoading(false);
    });

    getstudentdetailProfile(Id[1]).then((res) => {
      if (res && res.status === 200) {
        setData(res.data);
        if (res.data != "") {
          setStudentYet(true);
        }
      }
      setLoading(false);
    });
  };

  const goPagestudent = () => {
    props.history.push({
      // pathname: "/formapplicationstudent/student" + props.location.search,
      pathname: "/formregister/student" + props.location.search,
    });
    window.location.reload();
    window.close();
  };

  return (
    <Segment basic textAlign="left">
      {!visitData && (
        <>
          <p style={{ color: "red", textAlign: "center" }}>
            คุณยังไม่ได้ทำการลงทะเบียนเยี่ยมชมโรงเรียน
          </p>
          <p style={{ textAlign: "center" }}>
            Click Link เพื่อลงทะเบียน:
            {/* <a href="https://kidzweb-public-dot-kidz-village.as.r.appspot.com/xxx/visitrequest">https://kidzweb-public-dot-kidz-village.as.r.appspot.com/xxx/visitrequest</a> */}
            <a href={config.urlVisitReqform}>{config.urlVisitReqform}</a>
          </p>
        </>
      )}

      {visitData && (
        <>
          {studentYet ? (
            saveYet ? (
              // 'visitมีค่า studentมีค่า submitแล้ว'
              <PreviewStudent initialValues={data} />
            ) : (
              // 'visitมีค่า studentมีค่า ยังไม่submit'
              <PreviewStudent initialValues={data} />
            )
          ) : (
            // 'visitมีค่า studentไม่มีค่า'
            <center>
              <Header as="h3">กรุณาย้อนกลับไปกรอกข้อมูลให้ครบถ้วนครับ</Header>
              <br></br>
              <Button
                floated="center"
                content="OK"
                style={{
                  backgroundColor: "#4682B4",
                  color: "#ffffff",
                  minWidth: "20%",
                }}
                onClick={() => goPagestudent()}
              />
            </center>
          )}
        </>
      )}
    </Segment>
  );
};

export default withRouter(PreviewRegister);
