import React, { useState, useEffect } from "react";
import { Field, reduxForm, formValueSelector } from "redux-form";
import renderField from "../../../components/renderField";
import renderSelectField from "../../../components/renderSelectField";
import renderFileField from "../../../components/renderFileField";
import renderDateTimeField from "../../../components/renderDateTimeField";
import renderDateOnlyField from "../../../components/renderDateOnlyField";
import renderDateTimeField4 from "../../../components/renderDateTimeField4.js";
import { Button, Form, Message, Grid } from "semantic-ui-react";
import SecondButton from "../../../components/secondbutton";
import * as config from "../../../config";
import { connect } from "react-redux";
import {
  Header,
  Image,
  Segment,
  Breadcrumb,
  Loader,
  Dimmer,
  Tab,
  Table,
  Icon,
  Label,
  FormGroup,
} from "semantic-ui-react";
import renderDateField from "../../../components/renderDateOnlyField";
import { withRouter } from "react-router-dom";
import ModalBasic from "../../../components/modal";
import moment from "moment";

const validate = (values) => {
  const errors = {};
  if (!values.idnumber) errors.idnumber = true;
  if (!values.firstName) errors.firstName = true;
  if (!values.lastName) errors.lastName = true;
  if (!values.nickname) errors.nickname = true;
  if (!values.gender) errors.gender = true;
  if (!values.dateOfBirth) errors.dateOfBirth = true;
  if (!values.bloodtype) errors.bloodtype = true;
  if (!values.race) errors.race = true;
  if (!values.allergy) errors.allergy = true;
  if (!values.food_allergy) errors.food_allergy = true;
  if (!values.father_idnumber) errors.father_idnumber = true;
  if (!values.father_dateOfBirth) errors.father_dateOfBirth = true;
  if (!values.father_firstName) errors.father_firstName = true;
  if (!values.father_lastName) errors.father_lastName = true;
  if (!values.father_phone) errors.father_phone = true;
  if (!values.mother_idnumber) errors.mother_idnumber = true;
  if (!values.mother_dateOfBirth) errors.mother_dateOfBirth = true;
  if (!values.mother_firstName) errors.mother_firstName = true;
  if (!values.mother_lastName) errors.mother_lastName = true;
  if (!values.mother_phone) errors.mother_phone = true;
  // if (!values.interview_date) errors.interview_date = true;
  return errors;
};

let FormStudentOther = (props) => {
  const {
    loading,
    pristine,
    submitting,
    onSubmit,
    handleSubmit,
    initialValue,
    onClose,
    errormsg,
  } = props;
  // const [open, setOpen] = useState(false);

  // const showPopup = () => {
  //   setOpen(true);
  // };
  // const popUpClose = () => {
  //   setOpen(false);
  //   window.location.reload();
  //   window.close();
  // };

  return (
    <Segment basic textAlign="left">
      <Segment>
        <Image
          src="../storage/imgs/SP-logo.png"
          size="tiny"
          spaced
          floated="right"
        />

        <div style={{ paddingLeft: "20px" }}>
          <Header as="h4">
            <u>Part I</u> : Bio Data (ข้อมูลส่วนบุคคล)
          </Header>
          <Header as="h4">Student(นักเรียน)</Header>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Grid divided stackable>
              <Grid.Row>
                <Grid.Column>
                  <Field
                    // width="5"
                    name="idnumber"
                    type="text"
                    required={true}
                    component={renderField}
                    label="Passport or ID No. (เลขประจำตัวประชาชน)"
                  />
                  <Form.Group unstackable>
                    <Field
                      // width="3"
                      name="firstName"
                      type="text"
                      required={true}
                      component={renderField}
                      label="firstname"
                    />
                    <Field
                      // width="3"
                      name="lastName"
                      type="text"
                      required={true}
                      component={renderField}
                      label="lastname"
                    />
                    <Field
                      // width="3"
                      name="nickname"
                      type="text"
                      required={true}
                      component={renderField}
                      label="Nickname"
                    />
                  </Form.Group>
                  <Form.Group unstackable>
                    <Field
                      // width="3"
                      name="firstName_th"
                      type="text"
                      required={false}
                      component={renderField}
                      label="ชื่อ"
                    />
                    <Field
                      // width="3"
                      name="lastName_th"
                      type="text"
                      required={false}
                      component={renderField}
                      label="นามสกุล"
                    />
                    <Field
                      // width="3"
                      name="nickname_th"
                      type="text"
                      required={false}
                      component={renderField}
                      label="ชื่อเล่น"
                    />
                  </Form.Group>
                  <Form.Group unstackable>
                    <Field
                      name="gender"
                      // width="2"
                      component={renderSelectField}
                      label="Gender (เพศ)"
                      options={config.options_gender}
                    />
                    <Field
                      // width="3"
                      name="dateOfBirth"
                      required={true}
                      component={renderDateOnlyField}
                      label="Date Of Birth (ว/ด/ป เกิด)"
                    />
                    <Field
                      // width="2"
                      name="nationality"
                      type="text"
                      required={false}
                      component={renderSelectField}
                      options={config.options_nationality}
                      label="nationality (สัญชาติ)"
                    />
                    <Field
                      // width="2"
                      name="nativelanguage"
                      type="text"
                      required={false}
                      component={renderField}
                      label="Native Language (ภาษาประจำชาติ)"
                    />
                  </Form.Group>
                  <Form.Group unstackable>
                    <Field
                      // width="3"
                      name="nativelanguageAtHome"
                      type="text"
                      required={false}
                      component={renderField}
                      label="Native Language use at home (ภาษาใช้ที่บ้าน)"
                    />
                    <Field
                      name="bloodtype"
                      // width="2"
                      component={renderSelectField}
                      required={true}
                      label="BloodType (หมู่เลือด)"
                      options={[
                        { key: "A", text: "A (เอ)", value: "A" },
                        { key: "B", text: "B (บี)", value: "B" },
                        { key: "AB", text: "AB (เอบี)", value: "AB" },
                        { key: "O", text: "O (โอ)", value: "O" },
                      ]}
                    />
                    <Field
                      // width="2"
                      name="race"
                      type="text"
                      required={true}
                      component={renderSelectField}
                      options={config.options_nationality}
                      label="Race (สัญชาติ)"
                    />
                    <Field
                      // width="3"
                      name="allergy"
                      type="text"
                      required={true}
                      component={renderField}
                      options={config.options_nationality}
                      label="Medical Condition (โรคประจำตัว)"
                    />
                    <Field
                      // width="3"
                      name="food_allergy"
                      type="text"
                      required={true}
                      component={renderField}
                      options={config.options_nationality}
                      label="Food Allergy (แพ้อาหาร)"
                    />
                  </Form.Group>
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <Grid divided stackable>
              <Grid.Row>
                <Grid.Column>
                  <Header as="h4">FATHER (บิดา)</Header>
                  <Form.Group>
                    <Field
                      // width="5"
                      name="father_idnumber"
                      type="text"
                      required={true}
                      component={renderField}
                      label="Passport or ID No. (เลขประจำตัวประชาชน)"
                    />
                    <Field
                      // width="3"
                      name="father_dateOfBirth"
                      required={true}
                      component={renderDateOnlyField}
                      label="Date Of Birth (ว/ด/ป เกิด)"
                    />
                  </Form.Group>
                  <Form.Group unstackable>
                    <Field
                      // width="3"
                      name="father_firstName"
                      type="text"
                      required={true}
                      component={renderField}
                      label="firstname"
                    />
                    <Field
                      // width="3"
                      name="father_lastName"
                      type="text"
                      required={true}
                      component={renderField}
                      label="lastname"
                    />
                    <Field
                      // width="3"
                      name="father_nationality"
                      type="text"
                      required={false}
                      component={renderSelectField}
                      options={config.options_nationality}
                      label="nationlity (สัญชาติ)"
                    />
                    <Field
                      // width="3"
                      name="father_nativelanguage"
                      type="text"
                      required={false}
                      component={renderField}
                      label="Native Language (ภาษาประจำชาติ)"
                    />
                  </Form.Group>
                  <Form.Group unstackable>
                    <Field
                      // width="3"
                      name="father_firstName_th"
                      type="text"
                      required={false}
                      component={renderField}
                      label="ชื่อ"
                    />
                    <Field
                      // width="3"
                      name="father_lastName_th"
                      type="text"
                      required={false}
                      component={renderField}
                      label="นามสกุล"
                    />
                    <Field
                      // width="3"
                      name="father_phone"
                      //   type="text"
                      type="number"
                      required={true}
                      component={renderField}
                      label="Mobile (มือถือ)"
                    />
                    <Field
                      // width="3"
                      name="father_email"
                      type="text"
                      required={false}
                      component={renderField}
                      label="E-mail (อีเมล์)"
                    />
                  </Form.Group>
                  <Form.Group unstackable>
                    <Field
                      // width="6"
                      name="father_placeOfWork"
                      type="text"
                      required={false}
                      component={renderField}
                      label="Place of work (สถานที่ทำงาน)"
                    />
                    {/* <Field
                                            width="3"
                                            name="father_position"
                                            type="text"
                                            required={false}
                                            component={renderField}
                                            label="Position"
                                        /> */}
                    <Field
                      // width="3"
                      name="father_officePhone"
                      type="text"
                      required={false}
                      component={renderField}
                      label="Tel (เบอร์โทรศัพท์)"
                    />
                  </Form.Group>
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <Grid divided stackable>
              <Grid.Row>
                <Grid.Column>
                  <Header as="h4">MOTHER (มารดา)</Header>
                  <Form.Group>
                    <Field
                      // width="5"
                      name="mother_idnumber"
                      type="text"
                      required={true}
                      component={renderField}
                      label="Passport or ID No. (เลขประจำตัวประชาชน)"
                    />
                    <Field
                      // width="3"
                      name="mother_dateOfBirth"
                      required={true}
                      component={renderDateOnlyField}
                      label="Date Of Birth (ว/ด/ป เกิด)"
                    />
                  </Form.Group>
                  <Form.Group unstackable>
                    <Field
                      // width="3"
                      name="mother_firstName"
                      type="text"
                      required={true}
                      component={renderField}
                      label="firstname"
                    />
                    <Field
                      // width="3"
                      name="mother_lastName"
                      type="text"
                      required={true}
                      component={renderField}
                      label="lastname"
                    />
                    <Field
                      // width="3"
                      name="mother_nationality"
                      type="text"
                      required={false}
                      label="Nationality (สัญชาติ)"
                      component={renderSelectField}
                      options={config.options_nationality}
                    />
                    <Field
                      // width="3"
                      name="mother_nativelanguage"
                      type="text"
                      required={false}
                      component={renderField}
                      label="Native Language (ภาษาประจำชาติ)"
                    />
                  </Form.Group>
                  <Form.Group unstackable>
                    <Field
                      // width="3"
                      name="mother_firstName_th"
                      type="text"
                      required={false}
                      component={renderField}
                      label="ชื่อ"
                    />
                    <Field
                      // width="3"
                      name="mother_lastName_th"
                      type="text"
                      required={false}
                      component={renderField}
                      label="นามสกุล"
                    />
                    <Field
                      // width="3"
                      name="mother_phone"
                      //   type="text"
                      type="number"
                      required={true}
                      component={renderField}
                      label="Mobile (มือถือ)"
                    />
                    <Field
                      // width="3"
                      name="mother_email"
                      type="text"
                      required={false}
                      component={renderField}
                      label="E-mail (อีเมล์)"
                    />
                  </Form.Group>
                  <Form.Group unstackable>
                    <Field
                      // width="6"
                      name="mother_placeOfWork"
                      type="text"
                      required={false}
                      component={renderField}
                      label="Place of work (สถานที่ทำงาน)"
                    />
                    {/* <Field
                                            width="3"
                                            name="mother_position"
                                            type="text"
                                            required={false}
                                            component={renderField}
                                            label="Position"
                                        /> */}
                    <Field
                      // width="3"
                      name="mother_officePhone"
                      type="text"
                      required={false}
                      component={renderField}
                      label="Office Tel (เบอร์โทรศัพท์)"
                    />
                  </Form.Group>
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <br />
            <br />
            {/* <b>
              Appointment to Interview (นัดหมายสัมภาษณ์)
              <span style={{ color: "red" }}>*</span>
            </b>
            <Segment>
              <b>
                interview date<span style={{ color: "red" }}> *</span>
              </b>
              <b style={{ paddingLeft: "45%" }}>
                interview time<span style={{ color: "red" }}> *</span>
              </b>
              <Form.Group unstackable>
                <Field
                  width="14"
                  name="interview_date"
                  required={true}
                  component={renderDateTimeField4}
                  minDate={moment().format("DD-MM-YYYY")}
                />
              </Form.Group>
            </Segment> */}
            {errormsg && (
              <Message negative>
                <Message.Content>{errormsg}</Message.Content>
              </Message>
            )}
            <Button
              floated="right"
              content="Save"
              style={{
                backgroundColor: config.colorTheme,
                color: "#ffffff",
                minWidth: "20%",
                marginRight: "10%",
              }}
              loading={loading}
              // onClick={() => showPopup()}
            />
            <br></br>
            <br></br>
            <br></br>
          </Form>
        </div>
      </Segment>

      {/* <ModalBasic open={open} size="big" close={popUpClose}>
        <Header as="h2" style={{ textAlign: "center" }}>
          ทำการบันทึกข้อมูลแล้ว
        </Header>
        <center>
          <Button
            content="OK"
            style={{
              backgroundColor: config.colorTheme,
              color: "#ffffff",
              minWidth: "5%",
            }}
            onClick={() => popUpClose()}
          />
        </center>
      </ModalBasic> */}
    </Segment>
  );
};

FormStudentOther = reduxForm({
  // a unique name for the form
  form: "FormStudentOther",
  validate,
  enableReinitialize: true,
})(FormStudentOther);

// const selector = formValueSelector("class");
// FormStudentOther = connect((state) => {
//   const start_card = selector(state, "start_card");
//   const end_card = selector(state, "end_card");
//   return {
//     start_card: start_card,
//     end_card: end_card,
//   };
// })(FormStudentOther);

export default withRouter(FormStudentOther);
