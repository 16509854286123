import React, { useState, useEffect } from "react";
import { Field, reduxForm, formValueSelector } from "redux-form";
import renderField from "../../../components/renderField";
import renderSelectField from "../../../components/renderSelectField";
import renderFileField from "../../../components/renderFileField";
import renderDateTimeField from "../../../components/renderDateTimeField";
import renderDateOnlyField from "../../../components/renderDateOnlyField";
import { Button, Form, Message, Grid, Card } from "semantic-ui-react";
import SecondButton from "../../../components/secondbutton";
import * as config from "../../../config";
import { connect } from "react-redux";
import {
  Header,
  Image,
  Segment,
  Breadcrumb,
  Loader,
  Dimmer,
  Tab,
  Table,
  Icon,
  Label,
  FormGroup,
} from "semantic-ui-react";
import renderDateField from "../../../components/renderDateOnlyField";
import { withRouter } from "react-router-dom";
import Wrap from "../../../components/wrap";
import ModalBasic from "../../../components/modal";

const validate = (values) => {
  const errors = {};
  if (!values.emergency_name) errors.emergency_name = true;
  if (!values.emergency_phone) errors.emergency_phone = true;
  if (!values.emergency_relation) errors.emergency_relation = true;

  return errors;
};

let FormEmergency = (props) => {
  // const Id = props.location.search.split("=");

  const {
    loading,
    pristine,
    submitting,
    onSubmit,
    handleSubmit,
    initialValue,
    onClose,
    errormsg,
    start_card,
    end_card,
    studentId,
    actMessage,
  } = props;
  //   const [open, setOpen] = useState(false);

  //   const showPopup = () => {
  //     setOpen(true);
  //   };
  //   const popUpClose = () => {
  //     setOpen(false);
  //     window.location.reload();
  //     window.close();
  //   };

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Card style={{ width: "100%", backgroundColor: "#efefef" }}>
          <Card.Content>
            <Form.Group unstackable>
              <Field
                width="16"
                name="emergency_name"
                type="text"
                required={true}
                component={renderField}
                label="Emergency person"
              />
            </Form.Group>
            <Form.Group unstackable>
              <Field
                width="16"
                name="emergency_phone"
                type="number"
                required={true}
                component={renderField}
                label="Contact no."
              />
              <Field
                width="16"
                name="emergency_relation"
                type="text"
                required={true}
                options={config.options_relationship}
                component={renderSelectField}
                label="Relationship"
              />
            </Form.Group>
          </Card.Content>
        </Card>
        <br />
        {/* Success message */}
        {actMessage && (
          <Message positive>
            <Message.Content>{actMessage}</Message.Content>
          </Message>
        )}
        {/* Error message */}
        {errormsg && (
          <Message negative>
            <Message.Content>{errormsg}</Message.Content>
          </Message>
        )}
        <br />
        <Button
          floated="left"
          content="Save"
          style={{
            backgroundColor: config.colorTheme,
            color: "#ffff",
            minWidth: "5%",
          }}
          loading={loading}
          disabled={pristine || submitting}
          //   onClick={() => showPopup()}
        />
        <br></br>
        <br></br>
      </Form>

      {/* <ModalBasic open={open} size="big" close={popUpClose}>
        <Header as="h2" style={{ textAlign: "center" }}>
          บันทึกข้อมูลเรียบร้อย
        </Header>
        <center>
          <Button
            content="OK"
            style={{
              backgroundColor: config.colorTheme,
              color: "#ffffff",
              minWidth: "5%",
            }}
            onClick={() => popUpClose()}
          />
        </center>
      </ModalBasic> */}
    </>
  );
};

FormEmergency = reduxForm({
  // a unique name for the form
  form: "FormStudentDetail2",
  validate,
  enableReinitialize: true,
})(FormEmergency);

const selector = formValueSelector("class");
FormEmergency = connect((state) => {
  const start_card = selector(state, "start_card");
  const end_card = selector(state, "end_card");
  return {
    start_card: start_card,
    end_card: end_card,
  };
})(FormEmergency);

export default withRouter(FormEmergency);
