import React from "react";
import { Form } from "semantic-ui-react";
import Wrap from "./wrap";

const renderField = ({
  input,
  label,
  width,
  options,
  required,
  search,
  placeholder,
  multiple,
  disableclear,
  loading,
  readOnly,
  meta: { touched, error, warning },
}) => {
  return (
    <Wrap>
      <Form.Field width={width || 16} required={required}>
        {multiple ? (
          <Form.Select
            clearable
            selection
            multiple
            fluid
            loading={loading}
            {...input}
            label={
              <label>
                {label} {required && <span style={{ color: "red" }}>*</span>}
              </label>
            }
            search={search}
            error={touched && error}
            options={options}
            defaultValue={input.value.id}
            onChange={(e, data) => input.onChange(data.value)}
            onBlur={(e, data) => input.onChange(data.value)}
            placeholder={placeholder}
          />
        ) : disableclear ? (
          <Form.Select
            selection
            {...input}
            label={
              <label>
                {label} {required && <span style={{ color: "red" }}>*</span>}
              </label>
            }
            fluid
            search={search}
            error={touched && error}
            options={options}
            loading={loading}
            defaultValue={input.value.id}
            onChange={(e, data) => input.onChange(data.value)}
            placeholder={placeholder}
            selectOnBlur={false}
          />
        ) : readOnly ? (
          <Form.Select
            selection
            {...input}
            label={
              <label>
                {label} {required && <span style={{ color: "red" }}>*</span>}
              </label>
            }
            fluid
            search={search}
            error={touched && error}
            options={options}
            defaultValue={input.value.id}
            onChange={(e, data) => input.onChange(data.value)}
            placeholder={placeholder}
            selectOnBlur={false}
            disabled
          />
        ) : (
          <Form.Select
            clearable
            selection
            {...input}
            label={
              <label>
                {label} {required && <span style={{ color: "red" }}>*</span>}
              </label>
            }
            fluid
            search={search}
            error={touched && error}
            options={options}
            defaultValue={input.value.id}
            onChange={(e, data) => input.onChange(data.value)}
            placeholder={placeholder}
            selectOnBlur={false}
            // disabled
          />
        )}

        <p style={{ color: "#9f3a38", textAlign: "left", fontSize: "90%" }}>
          {touched &&
            ((error && <span>{error}</span>) ||
              (warning && <span>{warning}</span>))}
        </p>
      </Form.Field>
    </Wrap>
  );
};

export default renderField;
