import React, { useState, useEffect } from "react";
import { Field, reduxForm, formValueSelector } from "redux-form";
import renderField from "../../../components/renderField";
import renderSelectField from "../../../components/renderSelectField";
import renderFileField from "../../../components/renderFileField";
import renderDateTimeField from "../../../components/renderDateTimeField";
import renderDateOnlyField from "../../../components/renderDateOnlyField";
import renderDateField from "../../../components/renderDateOnlyField";
import { Button, Form, Message, Grid } from "semantic-ui-react";
import * as config from '../../../config';
import { connect } from "react-redux";
import {
    Header,
    Image,
    Segment,
    Breadcrumb,
    Loader,
    Dimmer,
    Tab,
    Table,
    Icon,
    Label,
    FormGroup,
    Card,
    Step
} from 'semantic-ui-react';
import ModalBasic from "../../../components/modal";
import Wrap from "../../../components/wrap";
import { withRouter } from "react-router-dom";

import {
    getvisitProfile,
    getstudentdetailProfile,
} from "./service";
import PreviewStudent from './previewstudent';


const windowUrl = window.location.search;
const Id = windowUrl.split("=");

let PreviewApplication = (props) => {

    const [loading, setLoading] = useState(true);
    const [visitProfiles] = useState(Id[1]);
    const [data, setData] = useState([]);
    const [visitData, setVisitData] = useState([]);


    useEffect(() => {

        setLoading(true);
        if (visitProfiles) {
            getData();
        }
    }, [visitProfiles]);

    const getData = () => {
        getvisitProfile(Id[1]).then(res => {
            if (res && res.status === 200) {
                setVisitData(res.data);
            }
            setLoading(false);
        });

        getstudentdetailProfile(Id[1]).then(res => {
            if (res && res.status === 200) {
                setData(res.data);
            }
            setLoading(false);
        });
    };


    return (
        <Segment basic textAlign='left'>

            <PreviewStudent
                initialValues={data}
            />

        </Segment>
    );

}



export default withRouter(PreviewApplication);



