export const timeSchedule = [
    // {
    //     key: "00:00",
    //     text: "00:00",
    //     value: "00:00",
    // },
    // {
    //     key: "00:05",
    //     text: "00:05",
    //     value: "00:05",
    // },
    // {
    //     key: "00:10",
    //     text: "00:10",
    //     value: "00:10",
    // },
    // {
    //     key: "00:15",
    //     text: "00:15",
    //     value: "00:15",
    // },
    // {
    //     key: "00:20",
    //     text: "00:20",
    //     value: "00:20",
    // },
    // {
    //     key: "00:25",
    //     text: "00:25",
    //     value: "00:25",
    // },
    // {
    //     key: "00:30",
    //     text: "00:30",
    //     value: "00:30",
    // },
    // {
    //     key: "00:35",
    //     text: "00:35",
    //     value: "00:35",
    // },
    // {
    //     key: "00:40",
    //     text: "00:40",
    //     value: "00:40",
    // },
    // {
    //     key: "00:45",
    //     text: "00:45",
    //     value: "00:45",
    // },
    // {
    //     key: "00:50",
    //     text: "00:50",
    //     value: "00:50",
    // },
    // {
    //     key: "00:55",
    //     text: "00:55",
    //     value: "00:55",
    // },
    // {
    //     key: "01:00",
    //     text: "01:00",
    //     value: "01:00",
    // },
    // {
    //     key: "01:05",
    //     text: "01:05",
    //     value: "01:05",
    // },
    // {
    //     key: "01:10",
    //     text: "01:10",
    //     value: "01:10",
    // },
    // {
    //     key: "01:15",
    //     text: "01:15",
    //     value: "01:15",
    // },
    // {
    //     key: "01:20",
    //     text: "01:20",
    //     value: "01:20",
    // },
    // {
    //     key: "01:25",
    //     text: "01:25",
    //     value: "01:25",
    // },
    // {
    //     key: "01:30",
    //     text: "01:30",
    //     value: "01:30",
    // },
    // {
    //     key: "01:35",
    //     text: "01:35",
    //     value: "01:35",
    // },
    // {
    //     key: "01:40",
    //     text: "01:40",
    //     value: "01:40",
    // },
    // {
    //     key: "01:45",
    //     text: "01:45",
    //     value: "01:45",
    // },
    // {
    //     key: "01:50",
    //     text: "01:50",
    //     value: "01:50",
    // },
    // {
    //     key: "01:55",
    //     text: "01:55",
    //     value: "01:55",
    // },
    // {
    //     key: "02:00",
    //     text: "02:00",
    //     value: "02:00",
    // },
    // {
    //     key: "02:05",
    //     text: "02:05",
    //     value: "02:05",
    // },
    // {
    //     key: "02:10",
    //     text: "02:10",
    //     value: "02:10",
    // },
    // {
    //     key: "02:15",
    //     text: "02:15",
    //     value: "02:15",
    // },
    // {
    //     key: "02:20",
    //     text: "02:20",
    //     value: "02:20",
    // },
    // {
    //     key: "02:25",
    //     text: "02:25",
    //     value: "02:25",
    // },
    // {
    //     key: "02:30",
    //     text: "02:30",
    //     value: "02:30",
    // },
    // {
    //     key: "02:35",
    //     text: "02:35",
    //     value: "02:35",
    // },
    // {
    //     key: "02:40",
    //     text: "02:40",
    //     value: "02:40",
    // },
    // {
    //     key: "02:45",
    //     text: "02:45",
    //     value: "02:45",
    // },
    // {
    //     key: "02:50",
    //     text: "02:50",
    //     value: "02:50",
    // },
    // {
    //     key: "02:55",
    //     text: "02:55",
    //     value: "02:55",
    // },
    // {
    //     key: "03:00",
    //     text: "03:00",
    //     value: "03:00",
    // },
    // {
    //     key: "03:05",
    //     text: "03:05",
    //     value: "03:05",
    // },
    // {
    //     key: "03:10",
    //     text: "03:10",
    //     value: "03:10",
    // },
    // {
    //     key: "03:15",
    //     text: "03:15",
    //     value: "03:15",
    // },
    // {
    //     key: "03:20",
    //     text: "03:20",
    //     value: "03:20",
    // },
    // {
    //     key: "03:25",
    //     text: "03:25",
    //     value: "03:25",
    // },
    // {
    //     key: "03:30",
    //     text: "03:30",
    //     value: "03:30",
    // },
    // {
    //     key: "03:35",
    //     text: "03:35",
    //     value: "03:35",
    // },
    // {
    //     key: "03:40",
    //     text: "03:40",
    //     value: "03:40",
    // },
    // {
    //     key: "03:45",
    //     text: "03:45",
    //     value: "03:45",
    // },
    // {
    //     key: "03:50",
    //     text: "03:50",
    //     value: "03:50",
    // },
    // {
    //     key: "03:55",
    //     text: "03:55",
    //     value: "03:55",
    // },
    // {
    //     key: "04:00",
    //     text: "04:00",
    //     value: "04:00",
    // },
    // {
    //     key: "04:05",
    //     text: "04:05",
    //     value: "04:05",
    // },
    // {
    //     key: "04:10",
    //     text: "04:10",
    //     value: "04:10",
    // },
    // {
    //     key: "04:15",
    //     text: "04:15",
    //     value: "04:15",
    // },
    // {
    //     key: "04:20",
    //     text: "04:20",
    //     value: "04:20",
    // },
    // {
    //     key: "04:25",
    //     text: "04:25",
    //     value: "04:25",
    // },
    // {
    //     key: "04:30",
    //     text: "04:30",
    //     value: "04:30",
    // },
    // {
    //     key: "04:35",
    //     text: "04:35",
    //     value: "04:35",
    // },
    // {
    //     key: "04:40",
    //     text: "04:40",
    //     value: "04:40",
    // },
    // {
    //     key: "04:45",
    //     text: "04:45",
    //     value: "04:45",
    // },
    // {
    //     key: "04:50",
    //     text: "04:50",
    //     value: "04:50",
    // },
    // {
    //     key: "04:55",
    //     text: "04:55",
    //     value: "04:55",
    // },
    // {
    //     key: "05:00",
    //     text: "05:00",
    //     value: "05:00",
    // },
    // {
    //     key: "05:05",
    //     text: "05:05",
    //     value: "05:05",
    // },
    // {
    //     key: "05:10",
    //     text: "05:10",
    //     value: "05:10",
    // },
    // {
    //     key: "05:15",
    //     text: "05:15",
    //     value: "05:15",
    // },
    // {
    //     key: "05:20",
    //     text: "05:20",
    //     value: "05:20",
    // },
    // {
    //     key: "05:25",
    //     text: "05:25",
    //     value: "05:25",
    // },
    {
        key: "05:30",
        text: "05:30",
        value: "05:30",
    },
    {
        key: "05:35",
        text: "05:35",
        value: "05:35",
    },
    {
        key: "05:40",
        text: "05:40",
        value: "05:40",
    },
    {
        key: "05:45",
        text: "05:45",
        value: "05:45",
    },
    {
        key: "05:50",
        text: "05:50",
        value: "05:50",
    },
    {
        key: "05:55",
        text: "05:55",
        value: "05:55",
    },
    {
        key: "06:00",
        text: "06:00",
        value: "06:00",
    },
    {
        key: "06:05",
        text: "06:05",
        value: "06:05",
    },
    {
        key: "06:10",
        text: "06:10",
        value: "06:10",
    },
    {
        key: "06:15",
        text: "06:15",
        value: "06:15",
    },
    {
        key: "06:20",
        text: "06:20",
        value: "06:20",
    },
    {
        key: "06:25",
        text: "06:25",
        value: "06:25",
    },
    {
        key: "06:30",
        text: "06:30",
        value: "06:30",
    },
    {
        key: "06:35",
        text: "06:35",
        value: "06:35",
    },
    {
        key: "06:40",
        text: "06:40",
        value: "06:40",
    },
    {
        key: "06:45",
        text: "06:45",
        value: "06:45",
    },
    {
        key: "06:50",
        text: "06:50",
        value: "06:50",
    },
    {
        key: "06:55",
        text: "06:55",
        value: "06:55",
    },
    {
        key: "07:00",
        text: "07:00",
        value: "07:00",
    },
    {
        key: "07:05",
        text: "07:05",
        value: "07:05",
    },
    {
        key: "07:10",
        text: "07:10",
        value: "07:10",
    },
    {
        key: "07:15",
        text: "07:15",
        value: "07:15",
    },
    {
        key: "07:20",
        text: "07:20",
        value: "07:20",
    },
    {
        key: "07:25",
        text: "07:25",
        value: "07:25",
    },
    {
        key: "07:30",
        text: "07:30",
        value: "07:30",
    },
    {
        key: "07:35",
        text: "07:35",
        value: "07:35",
    },
    {
        key: "07:40",
        text: "07:40",
        value: "07:40",
    },
    {
        key: "07:45",
        text: "07:45",
        value: "07:45",
    },
    {
        key: "07:50",
        text: "07:50",
        value: "07:50",
    },
    {
        key: "07:55",
        text: "07:55",
        value: "07:55",
    },
    {
        key: "08:00",
        text: "08:00",
        value: "08:00",
    },
    {
        key: "08:05",
        text: "08:05",
        value: "08:05",
    },
    {
        key: "08:10",
        text: "08:10",
        value: "08:10",
    },
    {
        key: "08:15",
        text: "08:15",
        value: "08:15",
    },
    {
        key: "08:20",
        text: "08:20",
        value: "08:20",
    },
    {
        key: "08:25",
        text: "08:25",
        value: "08:25",
    },
    {
        key: "08:30",
        text: "08:30",
        value: "08:30",
    },
    {
        key: "08:35",
        text: "08:35",
        value: "08:35",
    },
    {
        key: "08:40",
        text: "08:40",
        value: "08:40",
    },
    {
        key: "08:45",
        text: "08:45",
        value: "08:45",
    },
    {
        key: "08:50",
        text: "08:50",
        value: "08:50",
    },
    {
        key: "08:55",
        text: "08:55",
        value: "08:55",
    },
    {
        key: "09:00",
        text: "09:00",
        value: "09:00",
    },
    {
        key: "09:05",
        text: "09:05",
        value: "09:05",
    },
    {
        key: "09:10",
        text: "09:10",
        value: "09:10",
    },
    {
        key: "09:15",
        text: "09:15",
        value: "09:15",
    },
    {
        key: "09:20",
        text: "09:20",
        value: "09:20",
    },
    {
        key: "09:25",
        text: "09:25",
        value: "09:25",
    },
    {
        key: "09:30",
        text: "09:30",
        value: "09:30",
    },
    {
        key: "09:35",
        text: "09:35",
        value: "09:35",
    },
    {
        key: "09:40",
        text: "09:40",
        value: "09:40",
    },
    {
        key: "09:45",
        text: "09:45",
        value: "09:45",
    },
    {
        key: "09:50",
        text: "09:50",
        value: "09:50",
    },
    {
        key: "09:55",
        text: "09:55",
        value: "09:55",
    },
    {
        key: "10:00",
        text: "10:00",
        value: "10:00",
    },
    {
        key: "10:05",
        text: "10:05",
        value: "10:05",
    },
    {
        key: "10:10",
        text: "10:10",
        value: "10:10",
    },
    {
        key: "10:15",
        text: "10:15",
        value: "10:15",
    },
    {
        key: "10:20",
        text: "10:20",
        value: "10:20",
    },
    {
        key: "10:25",
        text: "10:25",
        value: "10:25",
    },
    {
        key: "10:30",
        text: "10:30",
        value: "10:30",
    },
    {
        key: "10:35",
        text: "10:35",
        value: "10:35",
    },
    {
        key: "10:40",
        text: "10:40",
        value: "10:40",
    },
    {
        key: "10:45",
        text: "10:45",
        value: "10:45",
    },
    {
        key: "10:50",
        text: "10:50",
        value: "10:50",
    },
    {
        key: "10:55",
        text: "10:55",
        value: "10:55",
    },
    {
        key: "11:00",
        text: "11:00",
        value: "11:00",
    },
    {
        key: "11:05",
        text: "11:05",
        value: "11:05",
    },
    {
        key: "11:10",
        text: "11:10",
        value: "11:10",
    },
    {
        key: "11:15",
        text: "11:15",
        value: "11:15",
    },
    {
        key: "11:20",
        text: "11:20",
        value: "11:20",
    },
    {
        key: "11:25",
        text: "11:25",
        value: "11:25",
    },
    {
        key: "11:30",
        text: "11:30",
        value: "11:30",
    },
    {
        key: "11:35",
        text: "11:35",
        value: "11:35",
    },
    {
        key: "11:40",
        text: "11:40",
        value: "11:40",
    },
    {
        key: "11:45",
        text: "11:45",
        value: "11:45",
    },
    {
        key: "11:50",
        text: "11:50",
        value: "11:50",
    },
    {
        key: "11:55",
        text: "11:55",
        value: "11:55",
    },
    {
        key: "12:00",
        text: "12:00",
        value: "12:00",
    },
    {
        key: "12:05",
        text: "12:05",
        value: "12:05",
    },
    {
        key: "12:10",
        text: "12:10",
        value: "12:10",
    },
    {
        key: "12:15",
        text: "12:15",
        value: "12:15",
    },
    {
        key: "12:20",
        text: "12:20",
        value: "12:20",
    },
    {
        key: "12:25",
        text: "12:25",
        value: "12:25",
    },
    {
        key: "12:30",
        text: "12:30",
        value: "12:30",
    },
    {
        key: "12:35",
        text: "12:35",
        value: "12:35",
    },
    {
        key: "12:40",
        text: "12:40",
        value: "12:40",
    },
    {
        key: "12:45",
        text: "12:45",
        value: "12:45",
    },
    {
        key: "12:50",
        text: "12:50",
        value: "12:50",
    },
    {
        key: "12:55",
        text: "12:55",
        value: "12:55",
    },
    {
        key: "13:00",
        text: "13:00",
        value: "13:00",
    },
    {
        key: "13:05",
        text: "13:05",
        value: "13:05",
    },
    {
        key: "13:10",
        text: "13:10",
        value: "13:10",
    },
    {
        key: "13:15",
        text: "13:15",
        value: "13:15",
    },
    {
        key: "13:20",
        text: "13:20",
        value: "13:20",
    },
    {
        key: "13:25",
        text: "13:25",
        value: "13:25",
    },
    {
        key: "13:30",
        text: "13:30",
        value: "13:30",
    },
    {
        key: "13:35",
        text: "13:35",
        value: "13:35",
    },
    {
        key: "13:40",
        text: "13:40",
        value: "13:40",
    },
    {
        key: "13:45",
        text: "13:45",
        value: "13:45",
    },
    {
        key: "13:50",
        text: "13:50",
        value: "13:50",
    },
    {
        key: "13:55",
        text: "13:55",
        value: "13:55",
    },
    {
        key: "14:00",
        text: "14:00",
        value: "14:00",
    },
    {
        key: "14:05",
        text: "14:05",
        value: "14:05",
    },
    {
        key: "14:10",
        text: "14:10",
        value: "14:10",
    },
    {
        key: "14:15",
        text: "14:15",
        value: "14:15",
    },
    {
        key: "14:20",
        text: "14:20",
        value: "14:20",
    },
    {
        key: "14:25",
        text: "14:25",
        value: "14:25",
    },
    {
        key: "14:30",
        text: "14:30",
        value: "14:30",
    },
    {
        key: "14:35",
        text: "14:35",
        value: "14:35",
    },
    {
        key: "14:40",
        text: "14:40",
        value: "14:40",
    },
    {
        key: "14:45",
        text: "14:45",
        value: "14:45",
    },
    {
        key: "14:50",
        text: "14:50",
        value: "14:50",
    },
    {
        key: "14:55",
        text: "14:55",
        value: "14:55",
    },
    {
        key: "15:00",
        text: "15:00",
        value: "15:00",
    },
    {
        key: "15:05",
        text: "15:05",
        value: "15:05",
    },
    {
        key: "15:10",
        text: "15:10",
        value: "15:10",
    },
    {
        key: "15:15",
        text: "15:15",
        value: "15:15",
    },
    {
        key: "15:20",
        text: "15:20",
        value: "15:20",
    },
    {
        key: "15:25",
        text: "15:25",
        value: "15:25",
    },
    {
        key: "15:30",
        text: "15:30",
        value: "15:30",
    },
    {
        key: "15:35",
        text: "15:35",
        value: "15:35",
    },
    {
        key: "15:40",
        text: "15:40",
        value: "15:40",
    },
    {
        key: "15:45",
        text: "15:45",
        value: "15:45",
    },
    {
        key: "15:50",
        text: "15:50",
        value: "15:50",
    },
    {
        key: "15:55",
        text: "15:55",
        value: "15:55",
    },
    {
        key: "16:00",
        text: "16:00",
        value: "16:00",
    },
    {
        key: "16:05",
        text: "16:05",
        value: "16:05",
    },
    {
        key: "16:10",
        text: "16:10",
        value: "16:10",
    },
    {
        key: "16:15",
        text: "16:15",
        value: "16:15",
    },
    {
        key: "16:20",
        text: "16:20",
        value: "16:20",
    },
    {
        key: "16:25",
        text: "16:25",
        value: "16:25",
    },
    {
        key: "16:30",
        text: "16:30",
        value: "16:30",
    },
    {
        key: "16:35",
        text: "16:35",
        value: "16:35",
    },
    {
        key: "16:40",
        text: "16:40",
        value: "16:40",
    },
    {
        key: "16:45",
        text: "16:45",
        value: "16:45",
    },
    {
        key: "16:50",
        text: "16:50",
        value: "16:50",
    },
    {
        key: "16:55",
        text: "16:55",
        value: "16:55",
    },
    {
        key: "17:00",
        text: "17:00",
        value: "17:00",
    },
    {
        key: "17:05",
        text: "17:05",
        value: "17:05",
    },
    {
        key: "17:10",
        text: "17:10",
        value: "17:10",
    },
    {
        key: "17:15",
        text: "17:15",
        value: "17:15",
    },
    {
        key: "17:20",
        text: "17:20",
        value: "17:20",
    },
    {
        key: "17:25",
        text: "17:25",
        value: "17:25",
    },
    {
        key: "17:30",
        text: "17:30",
        value: "17:30",
    },
    {
        key: "17:35",
        text: "17:35",
        value: "17:35",
    },
    {
        key: "17:40",
        text: "17:40",
        value: "17:40",
    },
    {
        key: "17:45",
        text: "17:45",
        value: "17:45",
    },
    {
        key: "17:50",
        text: "17:50",
        value: "17:50",
    },
    {
        key: "17:55",
        text: "17:55",
        value: "17:55",
    },
    {
        key: "18:00",
        text: "18:00",
        value: "18:00",
    },
    {
        key: "18:05",
        text: "18:05",
        value: "18:05",
    },
    {
        key: "18:10",
        text: "18:10",
        value: "18:10",
    },
    {
        key: "18:15",
        text: "18:15",
        value: "18:15",
    },
    {
        key: "18:20",
        text: "18:20",
        value: "18:20",
    },
    {
        key: "18:25",
        text: "18:25",
        value: "18:25",
    },
    {
        key: "18:30",
        text: "18:30",
        value: "18:30",
    },
    {
        key: "18:35",
        text: "18:35",
        value: "18:35",
    },
    {
        key: "18:40",
        text: "18:40",
        value: "18:40",
    },
    {
        key: "18:45",
        text: "18:45",
        value: "18:45",
    },
    {
        key: "18:50",
        text: "18:50",
        value: "18:50",
    },
    {
        key: "18:55",
        text: "18:55",
        value: "18:55",
    },
    {
        key: "19:00",
        text: "19:00",
        value: "19:00",
    },
    // {
    //     key: "19:05",
    //     text: "19:05",
    //     value: "19:05",
    // },
    // {
    //     key: "19:10",
    //     text: "19:10",
    //     value: "19:10",
    // },
    // {
    //     key: "19:15",
    //     text: "19:15",
    //     value: "19:15",
    // },
    // {
    //     key: "19:20",
    //     text: "19:20",
    //     value: "19:20",
    // },
    // {
    //     key: "19:25",
    //     text: "19:25",
    //     value: "19:25",
    // },
    // {
    //     key: "19:30",
    //     text: "19:30",
    //     value: "19:30",
    // },
    // {
    //     key: "19:35",
    //     text: "19:35",
    //     value: "19:35",
    // },
    // {
    //     key: "19:40",
    //     text: "19:40",
    //     value: "19:40",
    // },
    // {
    //     key: "19:45",
    //     text: "19:45",
    //     value: "19:45",
    // },
    // {
    //     key: "19:50",
    //     text: "19:50",
    //     value: "19:50",
    // },
    // {
    //     key: "19:55",
    //     text: "19:55",
    //     value: "19:55",
    // },
    // {
    //     key: "20:00",
    //     text: "20:00",
    //     value: "20:00",
    // },
    // {
    //     key: "20:05",
    //     text: "20:05",
    //     value: "20:05",
    // },
    // {
    //     key: "20:10",
    //     text: "20:10",
    //     value: "20:10",
    // },
    // {
    //     key: "20:15",
    //     text: "20:15",
    //     value: "20:15",
    // },
    // {
    //     key: "20:20",
    //     text: "20:20",
    //     value: "20:20",
    // },
    // {
    //     key: "20:25",
    //     text: "20:25",
    //     value: "20:25",
    // },
    // {
    //     key: "20:30",
    //     text: "20:30",
    //     value: "20:30",
    // },
    // {
    //     key: "20:35",
    //     text: "20:35",
    //     value: "20:35",
    // },
    // {
    //     key: "20:40",
    //     text: "20:40",
    //     value: "20:40",
    // },
    // {
    //     key: "20:45",
    //     text: "20:45",
    //     value: "20:45",
    // },
    // {
    //     key: "20:50",
    //     text: "20:50",
    //     value: "20:50",
    // },
    // {
    //     key: "20:55",
    //     text: "20:55",
    //     value: "20:55",
    // },
    // {
    //     key: "21:00",
    //     text: "21:00",
    //     value: "21:00",
    // },
    // {
    //     key: "21:05",
    //     text: "21:05",
    //     value: "21:05",
    // },
    // {
    //     key: "21:10",
    //     text: "21:10",
    //     value: "21:10",
    // },
    // {
    //     key: "21:15",
    //     text: "21:15",
    //     value: "21:15",
    // },
    // {
    //     key: "21:20",
    //     text: "21:20",
    //     value: "21:20",
    // },
    // {
    //     key: "21:25",
    //     text: "21:25",
    //     value: "21:25",
    // },
    // {
    //     key: "21:30",
    //     text: "21:30",
    //     value: "21:30",
    // },
    // {
    //     key: "21:35",
    //     text: "21:35",
    //     value: "21:35",
    // },
    // {
    //     key: "21:40",
    //     text: "21:40",
    //     value: "21:40",
    // },
    // {
    //     key: "21:45",
    //     text: "21:45",
    //     value: "21:45",
    // },
    // {
    //     key: "21:50",
    //     text: "21:50",
    //     value: "21:50",
    // },
    // {
    //     key: "21:55",
    //     text: "21:55",
    //     value: "21:55",
    // },
    // {
    //     key: "22:00",
    //     text: "22:00",
    //     value: "22:00",
    // },
    // {
    //     key: "22:05",
    //     text: "22:05",
    //     value: "22:05",
    // },
    // {
    //     key: "22:10",
    //     text: "22:10",
    //     value: "22:10",
    // },
    // {
    //     key: "22:15",
    //     text: "22:15",
    //     value: "22:15",
    // },
    // {
    //     key: "22:20",
    //     text: "22:20",
    //     value: "22:20",
    // },
    // {
    //     key: "22:25",
    //     text: "22:25",
    //     value: "22:25",
    // },
    // {
    //     key: "22:30",
    //     text: "22:30",
    //     value: "22:30",
    // },
    // {
    //     key: "22:35",
    //     text: "22:35",
    //     value: "22:35",
    // },
    // {
    //     key: "22:40",
    //     text: "22:40",
    //     value: "22:40",
    // },
    // {
    //     key: "22:45",
    //     text: "22:45",
    //     value: "22:45",
    // },
    // {
    //     key: "22:50",
    //     text: "22:50",
    //     value: "22:50",
    // },
    // {
    //     key: "22:55",
    //     text: "22:55",
    //     value: "22:55",
    // },
    // {
    //     key: "23:00",
    //     text: "23:00",
    //     value: "23:00",
    // },
    // {
    //     key: "23:05",
    //     text: "23:05",
    //     value: "23:05",
    // },
    // {
    //     key: "23:10",
    //     text: "23:10",
    //     value: "23:10",
    // },
    // {
    //     key: "23:15",
    //     text: "23:15",
    //     value: "23:15",
    // },
    // {
    //     key: "23:20",
    //     text: "23:20",
    //     value: "23:20",
    // },
    // {
    //     key: "23:25",
    //     text: "23:25",
    //     value: "23:25",
    // },
    // {
    //     key: "23:30",
    //     text: "23:30",
    //     value: "23:30",
    // },
    // {
    //     key: "23:35",
    //     text: "23:35",
    //     value: "23:35",
    // },
    // {
    //     key: "23:40",
    //     text: "23:40",
    //     value: "23:40",
    // },
    // {
    //     key: "23:45",
    //     text: "23:45",
    //     value: "23:45",
    // },
    // {
    //     key: "23:50",
    //     text: "23:50",
    //     value: "23:50",
    // },
    // {
    //     key: "23:55",
    //     text: "23:55",
    //     value: "23:55",
    // },
];


export const timeSchedule2 = [
    {
        key: "09:00",
        text: "09:00",
        value: "09:00",
    },
    {
        key: "09:30",
        text: "09:30",
        value: "09:30",
    },
    {
        key: "10:00",
        text: "10:00",
        value: "10:00",
    },
    {
        key: "10:30",
        text: "10:30",
        value: "10:30",
    },
    {
        key: "11:00",
        text: "11:00",
        value: "11:00",
    },
    {
        key: "11:30",
        text: "11:30",
        value: "11:30",
    },
    {
        key: "12:00",
        text: "12:00",
        value: "12:00",
    },
    {
        key: "12:30",
        text: "12:30",
        value: "12:30",
    },
    {
        key: "13:00",
        text: "13:00",
        value: "13:00",
    },
    {
        key: "13:30",
        text: "13:30",
        value: "13:30",
    },
    {
        key: "14:00",
        text: "14:00",
        value: "14:00",
    },
    {
        key: "14:30",
        text: "14:30",
        value: "14:30",
    },
    {
        key: "15:00",
        text: "15:00",
        value: "15:00",
    },
    {
        key: "15:30",
        text: "15:30",
        value: "15:30",
    },
    {
        key: "16:00",
        text: "16:00",
        value: "16:00",
    }
]

export const timeSchedule3 = [
    {
        key: "09:30",
        text: "09:30",
        value: "09:30",
    },
    {
        key: "10:30",
        text: "10:30",
        value: "10:30",
    },
]

export const timeSchedule4 = [
    {
        key: "09:00 - 10:00",
        text: "09:00 - 10:00",
        value: "09:00",
    },
    {
        key: "10:00 - 11:00",
        text: "10:00 - 11:00",
        value: "10:00",
    },
    {
        key: "11:00 - 12:00",
        text: "11:00 - 12:00",
        value: "11:00",
    },
    {
        key: "13:00 - 14:00",
        text: "13:00 - 14:00",
        value: "13:00",
    },
    {
        key: "14:00 - 15:00",
        text: "14:00 - 15:00",
        value: "14:00",
    },
]
