import React, { useState, useEffect } from "react";
import { Field, reduxForm, formValueSelector } from "redux-form";
import renderField from "../../../components/renderField";
import renderSelectField from "../../../components/renderSelectField";
import renderFileField from "../../../components/renderFileField";
import renderDateTimeField from "../../../components/renderDateTimeField";
import renderDateOnlyField from "../../../components/renderDateOnlyField";
import { Button, Form, Message, Grid } from "semantic-ui-react";
import SecondButton from "../../../components/secondbutton";
import * as config from "../../../config";
import { connect } from "react-redux";
import {
  Header,
  Image,
  Segment,
  Breadcrumb,
  Loader,
  Dimmer,
  Tab,
  Table,
  Icon,
  Label,
  FormGroup,
  Step,
} from "semantic-ui-react";
import renderDateField from "../../../components/renderDateOnlyField";
import ModalBasic from "../../../components/modal";
import Wrap from "../../../components/wrap";
import { withRouter } from "react-router-dom";

import {
  getstudentdetailProfile,
  getFather,
  getMother,
  getStudentHasGuardian1,
  getStudentHasGuardian2,
} from "./service";
// import GuardianFather from "./guardianfather";
// import GuardianMother from "./guardianmother";
import Guardian1 from "./guardian1";
import Guardian2 from "./guardian2";

const windowUrl = window.location.search;
const Id = windowUrl.split("=");
console.log(Id[1]);

let Guardian = (props) => {
  const [loading, setLoading] = useState(true);
  const [visitProfiles] = useState(Id[1]);
  const [data, setData] = useState([]);
  const [dataFather, setDataFather] = useState([]);
  const [dataMother, setDataMother] = useState([]);
  const [dataStudentHasGuardian1, setDataStudentHasGuardian1] = useState([]);
  const [dataStudentHasGuardian2, setDataStudentHasGuardian2] = useState([]);
  const [showSelectFather, setShowSelectFather] = useState(true);
  const [showSelectMother, setShowSelectMother] = useState(true);
  const [showFather, setShowFather] = useState(false);
  const [showMother, setShowMother] = useState(false);
  const [values, setValues] = useState({
    selectGuardian1: "Father",
    selectGuardian2: "Mother",
  });

  useEffect(() => {
    setLoading(true);
    if (visitProfiles) {
      getData();
    }
  }, [visitProfiles]);

  const getData = () => {
    getstudentdetailProfile(Id[1]).then((res) => {
      if (res && res.status === 200) {
        setData(res.data);
      }
      setLoading(false);
    });

    // Original
    // getFather(Id[1]).then((res) => {
    //   setDataFather(res.data);
    //   setLoading(false);
    // });

    // getMother(Id[1]).then((res) => {
    //   setDataMother(res.data);
    //   setLoading(false);
    // });

    // getStudentHasGuardian1(Id[1]).then((res) => {
    //   if (res && res.status === 200) {
    //     if (res.data.relationship == "Father") {
    //       setDataStudentHasGuardian1(res.data);
    //       setShowSelectFather(false);
    //       setShowFather(true);
    //     } else if (
    //       res.data.relationship == "GrandFather" ||
    //       res.data.relationship == "GrandMother" ||
    //       res.data.relationship == "Uncle" ||
    //       res.data.relationship == "Aunt" ||
    //       res.data.relationship == "Other"
    //     ) {
    //       setShowSelectFather(false);
    //       setShowFather(false);
    //     }
    //   }
    //   setLoading(false);
    // });

    getStudentHasGuardian2(Id[1]).then((res) => {
      if (res && res.status === 200) {
        if (res.data.relationship == "Mother") {
          setDataStudentHasGuardian2(res.data);
          setShowSelectMother(false);
          setShowMother(true);
        } else if (
          res.data.relationship == "GrandFather" ||
          res.data.relationship == "GrandMother" ||
          res.data.relationship == "Uncle" ||
          res.data.relationship == "Aunt" ||
          res.data.relationship == "Other"
        ) {
          setShowSelectMother(false);
          setShowMother(false);
        }
      }
      setLoading(false);
    });
  };
  // console.log('dataFather:', dataFather);
  // console.log('dataMother:', dataMother);
  // console.log('dataStudentHasGuardian1:', dataStudentHasGuardian1);
  // console.log('dataStudentHasGuardian2:', dataStudentHasGuardian2);

  const handleSelectChange = (e, data) => {
    const { name, value } = data;
    setValues({ ...values, [name]: value });
  };

  const nextPage = () => {
    props.history.push({
      pathname: "/formregister/health" + props.location.search,
    });
    window.location.reload();
    // window.close();
  };

  const goPagestudent = () => {
    props.history.push({
      pathname: "/formregister/student" + props.location.search,
    });
    window.location.reload();
    // window.close();
  };

  const goPagestudent2 = () => {
    props.history.push({
      pathname: "/formregister/student2" + props.location.search,
    });
    window.location.reload();
    // window.close();
  };

  const goPageguardian = () => {
    props.history.push({
      pathname: "/formregister/guardian" + props.location.search,
    });
    window.location.reload();
    // window.close();
  };

  const goPagehealth = () => {
    props.history.push({
      pathname: "/formregister/health" + props.location.search,
    });
    window.location.reload();
    // window.close();
  };

  const goPagedocument = () => {
    props.history.push({
      pathname: "/formregister/document" + props.location.search,
    });
    window.location.reload();
    // window.close();
  };

  const goPagepreviewregister = () => {
    props.history.push({
      pathname: "/formregister/previewregister" + props.location.search,
    });
    window.location.reload();
    // window.close();
  };

  return (
    <Segment basic textAlign="left">
      <Segment style={{ width: "100%" }}>
        <Grid columns="7">
          <Grid.Column
            style={{
              color: "#eee",
              fontSize: "13px",
              width: "25%",
              backgroundColor: "#769393",
            }}
          >
            <b style={{ textAlign: "center" }}>Application Form</b>
          </Grid.Column>
          <Grid.Column
            style={{ color: "#aaa", fontSize: "13px", width: "10%" }}
          >
            <b
              style={{ textAlign: "center", cursor: "pointer" }}
              onClick={() => goPagestudent()}
            >
              1.
            </b>
            <b style={{ paddingLeft: "20%" }}>/</b>
          </Grid.Column>
          <Grid.Column
            style={{ color: "#aaa", fontSize: "13px", width: "10%" }}
          >
            <b style={{ cursor: "pointer" }} onClick={() => goPagestudent2()}>
              2.
            </b>
            <b style={{ paddingLeft: "10%" }}>/</b>
          </Grid.Column>
          <Grid.Column
            style={{ color: "#8e8d8d", fontSize: "13px", width: "20%" }}
          >
            <b style={{ cursor: "pointer" }} onClick={() => goPageguardian()}>
              3.Guardian
            </b>
            <b style={{ paddingLeft: "10%" }}>/</b>
          </Grid.Column>
          <Grid.Column
            style={{ color: "#aaa", fontSize: "13px", width: "10%" }}
          >
            <b style={{ cursor: "pointer" }} onClick={() => goPagehealth()}>
              4.
            </b>
            <b style={{ paddingLeft: "100%" }}>/</b>
          </Grid.Column>
          <Grid.Column
            style={{ color: "#aaa", fontSize: "13px", width: "10%" }}
          >
            <b style={{ cursor: "pointer" }} onClick={() => goPagedocument()}>
              5.
            </b>
            <b style={{ paddingLeft: "100%" }}>/</b>
          </Grid.Column>
          <Grid.Column
            style={{ color: "#aaa", fontSize: "13px", width: "10%" }}
          >
            <b
              style={{ textAlign: "center", cursor: "pointer" }}
              onClick={() => goPagepreviewregister()}
            >
              6.
            </b>
            <b style={{ paddingLeft: "100%" }}>/</b>
          </Grid.Column>
        </Grid>
      </Segment>

      <Header as="h4">
        <u>Part III</u> : Guardian
      </Header>

      {/* Original */}
      {/* {showSelectFather ? (
        <Segment>
          <div style={{ paddingLeft: "15px" }}>
            <Form.Dropdown
              icon={null}
              fluid
              floating
              style={{ width: "25%", marginRight: "30%" }}
              name="selectGuardian1"
              trigger={
                <Button
                  basic
                  icon
                  fluid
                  size="small"
                  labelPosition="right"
                  style={{ textAlign: "left" }}
                >
                  <Icon name="angle down" />
                  Guardian1 : <b>{values.selectGuardian1}</b>
                </Button>
              }
              options={[
                { key: "Father", value: "Father", text: "Father" },
                { key: "Mother", value: "Mother", text: "Mother" },
                { key: "Other", value: "Other", text: "Other" },
              ]}
              defaultValue={values.selectGuardian1}
              selectOnBlur={false}
              onChange={handleSelectChange}
            />
          </div>
          {values.selectGuardian1 == "Father" && <GuardianFather />}
          {values.selectGuardian1 == "Mother" && <GuardianMother />}
          {values.selectGuardian1 == "Other" && <Guardian1 />}
        </Segment>
      ) : (
        <>{showFather ? <GuardianFather /> : <Guardian1 />}</>
      )} */}

      <Guardian1 />

      <br />
      {/* Original */}
      {/* {showSelectMother ? (
        <Segment>
          <div style={{ paddingLeft: "15px" }}>
            <Form.Dropdown
              icon={null}
              fluid
              floating
              style={{ width: "25%", marginRight: "30%" }}
              name="selectGuardian2"
              trigger={
                <Button
                  basic
                  icon
                  fluid
                  size="small"
                  labelPosition="right"
                  style={{ textAlign: "left" }}
                >
                  <Icon name="angle down" />
                  Guardian2 : <b>{values.selectGuardian2}</b>
                </Button>
              }
              options={[
                { key: "Father", value: "Father", text: "Father" },
                { key: "Mother", value: "Mother", text: "Mother" },
                { key: "Other", value: "Other", text: "Other" },
              ]}
              defaultValue={values.selectGuardian2}
              selectOnBlur={false}
              onChange={handleSelectChange}
            />
          </div>
          {values.selectGuardian2 == "Mother" && <GuardianMother />}
          {values.selectGuardian2 == "Father" && <GuardianFather />}
          {values.selectGuardian2 == "Other" && <Guardian2 />}
        </Segment>
      ) : (
        <>{showMother ? <GuardianMother /> : <Guardian2 />}</>
      )} */}

      <Guardian2 />

      <Button
        floated="right"
        content="Next Page"
        style={{
          backgroundColor: "#4682B4",
          color: "#ffffff",
          minWidth: "20%",
          marginRight: "10%",
        }}
        onClick={() => nextPage()}
      />
      <Button
        floated="left"
        content="Back"
        style={{
          backgroundColor: "#4682B4",
          color: "#ffffff",
          minWidth: "20%",
          marginLeft: "10%",
        }}
        onClick={() => goPagestudent2()}
      />
      <br></br>
      <br></br>
    </Segment>
  );
};

export default withRouter(Guardian);
