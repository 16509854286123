import React, { useState, useEffect } from "react";
import { Field, reduxForm, formValueSelector } from "redux-form";
import renderField from "../../../components/renderField";
import renderSelectField from "../../../components/renderSelectField";
import renderFileField from "../../../components/renderFileField";
import renderDateTimeField from "../../../components/renderDateTimeField";
import renderDateOnlyField from "../../../components/renderDateOnlyField";
import { Button, Form, Message, Grid, Card } from "semantic-ui-react";
import SecondButton from "../../../components/secondbutton";
import * as config from '../../../config';
import { connect } from "react-redux";
import {
    Header,
    Image,
    Segment,
    Breadcrumb,
    Loader,
    Dimmer,
    Tab,
    Table,
    Icon,
    Label,
    FormGroup
} from 'semantic-ui-react';
import renderDateField from "../../../components/renderDateOnlyField";
import { withRouter } from "react-router-dom";
import Wrap from "../../../components/wrap";
import ModalBasic from "../../../components/modal";

let FormPreviewApplication = (props) => {
    // const Id = props.location.search.split("=");

    const { loading, pristine, submitting, onSubmit, handleSubmit, initialValue, onClose, errormsg, start_card, end_card, studentId } = props;
    const [open, setOpen] = useState(false);

    const showPopup = () => {
        setOpen(true);
    };
    const popUpClose = () => {
        setOpen(false);
        window.location.reload();
        window.close();
    };

    return (

        <center>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Button floated='center' content='Submit'
                    style={{ backgroundColor: config.colorTheme, color: "#ffff", minWidth: '10%' }}
                    onClick={() => showPopup()}
                />
            </Form>

            <ModalBasic open={open} size='big' close={popUpClose}>
                <Header as='h2' style={{ textAlign: 'center' }}>Want to confirm the submission of application form?</Header>
                <center><Button content='OK'
                    style={{ backgroundColor: config.colorTheme, color: "#ffffff", minWidth: '5%' }}
                    onClick={() => popUpClose()}
                /></center>
            </ModalBasic>
        </center>

    );

}




FormPreviewApplication = reduxForm({
    // a unique name for the form
    form: "FormStudentDetail2",
    enableReinitialize: true,
})(FormPreviewApplication);

const selector = formValueSelector("class");
FormPreviewApplication = connect(state => {
    const start_card = selector(state, "start_card");
    const end_card = selector(state, "end_card");
    return {
        start_card: start_card,
        end_card: end_card
    };
})(FormPreviewApplication);

export default withRouter(FormPreviewApplication);



