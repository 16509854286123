import React, { useState, useEffect } from "react";
import { Field, reduxForm, formValueSelector } from "redux-form";
import renderField from "../../../components/renderField";
import renderSelectField from "../../../components/renderSelectField";
import renderFileField from "../../../components/renderFileField";
import renderDateTimeField from "../../../components/renderDateTimeField";
import renderDateOnlyField from "../../../components/renderDateOnlyField";
import { Button, Form, Message, Grid, Card } from "semantic-ui-react";
import SecondButton from "../../../components/secondbutton";
import * as config from "../../../config";
import { connect } from "react-redux";
import {
  Header,
  Image,
  Segment,
  Breadcrumb,
  Loader,
  Dimmer,
  Tab,
  Table,
  Icon,
  Label,
  FormGroup,
} from "semantic-ui-react";
import renderDateField from "../../../components/renderDateOnlyField";
import { withRouter } from "react-router-dom";
import Wrap from "../../../components/wrap";
import Sibling from "./sibling";

const windowUrl = window.location.search;
const Id = windowUrl.split("=");
console.log(Id[1]);

let Student2 = (props) => {
  const [loading, setLoading] = useState(true);
  const [visitProfiles] = useState(Id[1]);

  const nextPage = () => {
    props.history.push({
      pathname: "/formregister/guardian" + props.location.search,
    });
    window.location.reload();
    window.close();
  };

  const goPagestudent = () => {
    props.history.push({
      pathname: "/formregister/student" + props.location.search,
    });
    window.location.reload();
    window.close();
  };

  const goPagestudent2 = () => {
    props.history.push({
      pathname: "/formregister/student2" + props.location.search,
    });
    window.location.reload();
    window.close();
  };

  const goPageguardian = () => {
    props.history.push({
      pathname: "/formregister/guardian" + props.location.search,
    });
    window.location.reload();
    window.close();
  };

  const goPagehealth = () => {
    props.history.push({
      pathname: "/formregister/health" + props.location.search,
    });
    window.location.reload();
    window.close();
  };

  const goPagedocument = () => {
    props.history.push({
      pathname: "/formregister/document" + props.location.search,
    });
    window.location.reload();
    window.close();
  };

  const goPagepreviewregister = () => {
    props.history.push({
      pathname: "/formregister/previewregister" + props.location.search,
    });
    window.location.reload();
    window.close();
  };

  return (
    <Segment basic textAlign="left" style={{ backgroundColor: "#eee" }}>
      <Segment style={{ width: "100%" }}>
        <Grid columns="7">
          <Grid.Column
            style={{
              color: "#eee",
              fontSize: "13px",
              width: "25%",
              backgroundColor: "#769393",
            }}
          >
            <b style={{ textAlign: "center" }}>Application Form</b>
          </Grid.Column>
          <Grid.Column
            style={{ color: "#aaa", fontSize: "13px", width: "10%" }}
          >
            <b
              style={{ textAlign: "center", cursor: "pointer" }}
              onClick={() => goPagestudent()}
            >
              1.
            </b>
            <b style={{ paddingLeft: "20%" }}>/</b>
          </Grid.Column>
          <Grid.Column
            style={{ color: "#8e8d8d", fontSize: "13px", width: "20%" }}
          >
            <b style={{ cursor: "pointer" }} onClick={() => goPagestudent2()}>
              2.Student Details(Cont.)
            </b>
            <b style={{ paddingLeft: "10%" }}>/</b>
          </Grid.Column>
          <Grid.Column
            style={{ color: "#aaa", fontSize: "13px", width: "10%" }}
          >
            <b style={{ cursor: "pointer" }} onClick={() => goPageguardian()}>
              3.
            </b>
            <b style={{ paddingLeft: "50%" }}>/</b>
          </Grid.Column>
          <Grid.Column
            style={{ color: "#aaa", fontSize: "13px", width: "10%" }}
          >
            <b style={{ cursor: "pointer" }} onClick={() => goPagehealth()}>
              4.
            </b>
            <b style={{ paddingLeft: "50%" }}>/</b>
          </Grid.Column>
          <Grid.Column
            style={{ color: "#aaa", fontSize: "13px", width: "10%" }}
          >
            <b style={{ cursor: "pointer" }} onClick={() => goPagedocument()}>
              5.
            </b>
            <b style={{ paddingLeft: "50%" }}>/</b>
          </Grid.Column>
          <Grid.Column
            style={{ color: "#aaa", fontSize: "13px", width: "10%" }}
          >
            <b
              style={{ textAlign: "center", cursor: "pointer" }}
              onClick={() => goPagepreviewregister()}
            >
              6.
            </b>
            <b style={{ paddingLeft: "50%" }}>/</b>
          </Grid.Column>
        </Grid>
      </Segment>

      <Segment basic textAlign="left" style={{ backgroundColor: "#eee" }}>
        <Segment>
          <Image
            src="../storage/imgs/KV-logo.png"
            size="tiny"
            spaced
            floated="right"
          />

          <Header as="h4">
            <u>Part II</u> : Student(Cont.)
          </Header>
          <div style={{ paddingLeft: "20px" }}>
            <Grid divided stackable>
              <br></br>
              <Header as="h4">Name and ages of student sibling.</Header>
              <Grid.Row>
                <Grid.Column>
                  <Sibling />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </div>
        </Segment>
        <Button
          floated="right"
          content="Next Page"
          style={{
            backgroundColor: "#4682B4",
            color: "#ffffff",
            minWidth: "20%",
            marginRight: "10%",
          }}
          onClick={() => nextPage()}
        />
        <Button
          floated="left"
          content="Back"
          style={{
            backgroundColor: "#4682B4",
            color: "#ffffff",
            minWidth: "20%",
            marginLeft: "10%",
          }}
          onClick={() => goPagestudent()}
        />
        <br></br>
        <br></br>
      </Segment>
    </Segment>
  );
};

Student2 = reduxForm({
  // a unique name for the form
  form: "Sibling",
  enableReinitialize: true,
})(Student2);

const selector = formValueSelector("class");
Student2 = connect((state) => {
  const start_card = selector(state, "start_card");
  const end_card = selector(state, "end_card");
  return {
    start_card: start_card,
    end_card: end_card,
  };
})(Student2);

export default withRouter(Student2);
