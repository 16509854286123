import axios from "axios";
import * as config from "../../../config";

export function getstudentdetailProfile(Id) {
  return axios
    .get(config.urlAPI + "/getstudentdetailProfile/" + Id, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function getvisitProfile(Id) {
  return axios
    .get(config.urlAPI + "/visit/" + Id, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function getParentVisits(Id) {
  return axios
    .get(config.urlAPI + "/getparentvisits/" + Id)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function getinterview(id) {
  return axios
    .get(config.urlAPI + "/getinterview/" + id)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function updateImageProfile2(data, id) {
  return (
    axios
      // .post(config.urlAPI + "/parent/upload/" + id, data, config.headerCallAPI)
      .post(
        config.urlAPI + "/guardian/upload/" + id,
        data,
        config.headerCallAPI
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err.response;
      })
  );
}

export function consentpdpa(data, Id) {
  return axios
    .post(config.urlAPI + "/consentpdpa/" + Id, data)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function addapplication(data) {
  return axios
    .post(config.urlAPI + "/addapplication", data)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function updateImageProfile(data, id) {
  return axios
    .post(config.urlAPI + "/student/upload/" + id, data, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
