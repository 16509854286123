import React, { useState, useEffect } from "react";
import { Field, reduxForm, formValueSelector } from "redux-form";
import renderField from "../../../components/renderField";
import renderSelectField from "../../../components/renderSelectField";
import renderFileField from "../../../components/renderFileField";
import renderDateTimeField from "../../../components/renderDateTimeField";
import renderDateOnlyField from "../../../components/renderDateOnlyField";
import renderDateField from "../../../components/renderDateOnlyField";
import { Button, Form, Message, Grid } from "semantic-ui-react";
import * as config from "../../../config";
import { connect } from "react-redux";
import {
  Header,
  Image,
  Segment,
  Breadcrumb,
  Loader,
  Dimmer,
  Tab,
  Table,
  Icon,
  Label,
  FormGroup,
  Card,
  Step,
} from "semantic-ui-react";
import ModalBasic from "../../../components/modal";
import Wrap from "../../../components/wrap";
import { withRouter } from "react-router-dom";

import {
  getvisitProfile,
  getstudentdetailProfile,
  getParentVisits,
  getSibling,
  getguardian1,
  getguardian2,
  getimmunization_gethealthCheckStudents_health,
  getdocumentbyvisit_id,
  submitregister,
  getinterview,
} from "./service";
import FormPreviewRegister from "./frompreviewregister";

const windowUrl = window.location.search;
const Id = windowUrl.split("=");
// console.log(Id[1]);

let PreviewStudent = (props) => {
  const [loading, setLoading] = useState(true);
  const [visitProfiles] = useState(Id[1]);
  const [data, setData] = useState([]);
  const [dataFather, setDataFather] = useState([]);
  const [dataMother, setDataMother] = useState([]);
  const [dataGuardian1, setDataGuardian1] = useState([]);
  const [dataGuardian2, setDataGuardian2] = useState([]);
  const [studentSibling, setStudentSibling] = useState([]);
  const [
    immunization_gethealthCheckStudents_health,
    setimmunization_gethealthCheckStudents_health,
  ] = useState([]);
  const [document, setDocument] = useState([]);
  const [visitData, setVisitData] = useState([]);
  const [interview, setInterview] = useState([]);
  const [saveYet, setSaveYet] = useState(false);
  const [currentData, setCurrentData] = useState(null);
  const [open1, setOpen1] = useState(true);
  const [errormsg, setErrormsg] = useState(null);
  const [open, setOpen] = useState(false);

  // console.log(props);
  useEffect(() => {
    setLoading(true);
    if (visitProfiles) {
      getData();
    }
  }, [visitProfiles]);

  const getData = () => {
    getstudentdetailProfile(Id[1]).then((res) => {
      if (res && res.status === 200) {
        setData(res.data);
        // console.log(res.data);
      }
      setLoading(false);
    });

    getvisitProfile(Id[1]).then((res) => {
      if (res && res.status === 200) {
        setVisitData(res.data);
      }
      setLoading(false);
    });

    getParentVisits(Id[1]).then((res) => {
      if (res && res.status === 200) {
        setDataFather(res.data[0]);
        setDataMother(res.data[1]);
      }
      setLoading(false);
    });

    getSibling(Id[1]).then((res) => {
      if (res && res.status === 200) {
        setStudentSibling(res.data);
        // console.log(res.data);
      }
      setLoading(false);
    });

    getguardian1(Id[1]).then((res) => {
      if (res && res.status === 200) {
        setDataGuardian1(res.data);
      }
      setLoading(false);
    });

    getguardian2(Id[1]).then((res) => {
      if (res && res.status === 200) {
        setDataGuardian2(res.data);
      }
      setLoading(false);
    });

    getimmunization_gethealthCheckStudents_health(Id[1]).then((res) => {
      if (res && res.status === 200) {
        // console.log(res.data[0])
        setimmunization_gethealthCheckStudents_health(res.data[0]);
      }
      setLoading(false);
    });

    getdocumentbyvisit_id(Id[1]).then((res) => {
      if (res && res.status === 200) {
        setDocument(res.data);
      }
      setLoading(false);
    });

    getinterview(Id[1]).then((res) => {
      if (res && res.status === 200) {
        setInterview(res.data);
        if (res.data.submit_register != "yes") {
          // console.log(111, res.data);
          setSaveYet(true);
          setOpen1(false);
        } else if (res.data.submit_register == "yes") {
          // console.log(222, res.data);
          setSaveYet(false);
          setOpen1(true);
        }
      }
      setLoading(false);
    });
  };

  const nextPage = () => {
    props.history.push({
      pathname: "/formregister/student2" + props.location.search,
    });
    window.location.reload();
    window.close();
  };

  const goPagestudent = () => {
    props.history.push({
      pathname: "/formregister/student" + props.location.search,
    });
    window.location.reload();
    window.close();
  };

  const goPagestudent2 = () => {
    props.history.push({
      pathname: "/formregister/student2" + props.location.search,
    });
    window.location.reload();
    window.close();
  };

  const goPageguardian = () => {
    props.history.push({
      pathname: "/formregister/guardian" + props.location.search,
    });
    window.location.reload();
    window.close();
  };

  const goPagehealth = () => {
    props.history.push({
      pathname: "/formregister/health" + props.location.search,
    });
    window.location.reload();
    window.close();
  };

  const goPagedocument = () => {
    props.history.push({
      pathname: "/formregister/document" + props.location.search,
    });
    window.location.reload();
    window.close();
    window.location.reload();
    window.close();
    window.location.reload();
    window.close();
  };

  const goPagepreviewregister = () => {
    props.history.push({
      pathname: "/formregister/previewregister" + props.location.search,
    });
    window.location.reload();
    window.close();
  };

  const onSubmitRegister = (values) => {
    setErrormsg(null);
    setLoading(true);
    // let data = values;

    // console.log(`onSubmitRegister`, values);

    // Protect human error
    // >> Verify interview date is empty
    if (values.interview_date.length < 16) {
      setErrormsg("Please fill in the Appointment to Interview");
      setLoading(false);
      return;
    }

    submitregister(values).then((res) => {
      // console.log(`submitregister`, res);
      if (res && res.status === 200) {
        // setActMessage("บันทึกข้อมูล submitregister สำเร็จ");
        // window.location.reload();
        setLoading(false);
        setOpen(true);
      }
    });
  };

  const popUpClose = () => {
    setOpen(false);
    window.location.reload();
  };

  // console.log(props.location.search);

  return (
    <Segment basic textAlign="left">
      {saveYet ? (
        <Segment style={{ width: "100%" }}>
          <Grid columns="7">
            <Grid.Column
              style={{
                color: "#eee",
                fontSize: "13px",
                width: "25%",
                backgroundColor: "#769393",
              }}
            >
              <b style={{ textAlign: "center" }}>Application Form</b>
            </Grid.Column>
            <Grid.Column
              style={{ color: "#aaa", fontSize: "15px", width: "10%" }}
            >
              <b
                style={{ textAlign: "center", cursor: "pointer" }}
                onClick={() => goPagestudent()}
              >
                1.
              </b>
              <b style={{ paddingLeft: "20%" }}>/</b>
            </Grid.Column>
            <Grid.Column
              style={{ color: "#aaa", fontSize: "13px", width: "10%" }}
            >
              <b style={{ cursor: "pointer" }} onClick={() => goPagestudent2()}>
                2.
              </b>
              <b style={{ paddingLeft: "50%" }}>/</b>
            </Grid.Column>
            <Grid.Column
              style={{ color: "#aaa", fontSize: "13px", width: "10%" }}
            >
              <b style={{ cursor: "pointer" }} onClick={() => goPageguardian()}>
                3.
              </b>
              <b style={{ paddingLeft: "50%" }}>/</b>
            </Grid.Column>
            <Grid.Column
              style={{ color: "#aaa", fontSize: "13px", width: "10%" }}
            >
              <b style={{ cursor: "pointer" }} onClick={() => goPagehealth()}>
                4.
              </b>
              <b style={{ paddingLeft: "50%" }}>/</b>
            </Grid.Column>
            <Grid.Column
              style={{ color: "#aaa", fontSize: "13px", width: "10%" }}
            >
              <b style={{ cursor: "pointer" }} onClick={() => goPagedocument()}>
                5.
              </b>
              <b style={{ paddingLeft: "50%" }}>/</b>
            </Grid.Column>
            <Grid.Column
              style={{ color: "#8e8d8d", fontSize: "13px", width: "20%" }}
            >
              <b
                style={{ textAlign: "center", cursor: "pointer" }}
                onClick={() => goPagepreviewregister()}
              >
                6. Preview
              </b>
              <b style={{ paddingLeft: "50%" }}>/</b>
            </Grid.Column>
          </Grid>
        </Segment>
      ) : null}

      <Segment>
        <Image
          src="../storage/imgs/KV-logo.png"
          size="tiny"
          spaced
          floated="right"
        />

        <Header as="h4">
          <u>Part I</u> : Student
        </Header>
        <div style={{ paddingLeft: "20px", paddingTop: "30px" }}>
          <Grid>
            <Grid.Row>
              <Grid.Column>
                <Image
                  width={"15%"}
                  verticalAlign="middle"
                  src={
                    data.imageURL
                      ? data.imageURL + "?time=" + new Date()
                      : "../storage/imgs/dfkid.png"
                  }
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = "../storage/imgs/dfkid.png";
                  }}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid stackable columns="2" style={{ width: "100%" }}>
                <Grid.Column style={{ paddingLeft: "40px" }}>
                  Passport or ID No.:
                  <b> {data.idnumber}</b>
                </Grid.Column>
                <Grid.Column>
                  Date Of Birth:
                  <b> {data.dateOfBirth}</b>
                </Grid.Column>
              </Grid>
              <Grid stackable columns="4" style={{ width: "100%" }}>
                <Grid.Column style={{ paddingLeft: "40px" }}>
                  firstname:
                  <b> {data.firstName}</b>
                </Grid.Column>
                <Grid.Column>
                  lastname:
                  <b> {data.lastName}</b>
                </Grid.Column>
                <Grid.Column>
                  Nickname:
                  <b> {data.nickname}</b>
                </Grid.Column>
              </Grid>
              <Grid stackable columns="4" style={{ width: "100%" }}>
                <Grid.Column style={{ paddingLeft: "40px" }}>
                  ชื่อ:
                  <b> {data.firstName_th}</b>
                </Grid.Column>
                <Grid.Column>
                  นามสกุล:
                  <b> {data.lasttName_th}</b>
                </Grid.Column>
                <Grid.Column>
                  ชื่อเล่น:
                  <b> {data.nickname_th}</b>
                </Grid.Column>
              </Grid>
              <Grid stackable columns="4" style={{ width: "100%" }}>
                <Grid.Column style={{ paddingLeft: "40px" }}>
                  Gender:
                  <b> {data.gender}</b>
                </Grid.Column>
                <Grid.Column>
                  Nationality:
                  <b> {data.nationality}</b>
                </Grid.Column>
                <Grid.Column>
                  Native Language:
                  <b> {data.nativelanguage}</b>
                </Grid.Column>
                <Grid.Column>
                  Native Language (Use at home):
                  <b> {data.nativelanguageAtHome}</b>
                </Grid.Column>
              </Grid>
              <Grid stackable columns="4" style={{ width: "100%" }}>
                <Grid.Column style={{ paddingLeft: "40px" }}>
                  {/* โรคประจำตัว: <b>{data.allergy}</b> */}
                  {/* replace allergy */}
                  โรคประจำตัว: <b>{data.healthConditions}</b>
                </Grid.Column>
                <Grid.Column>
                  แพ้อาหาร : <b>{data.food_allergy}</b>
                </Grid.Column>
                <Grid.Column>
                  BloodType:
                  <b> {data.bloodtype}</b>
                </Grid.Column>
                <Grid.Column>
                  Race:
                  <b> {data.race}</b>
                </Grid.Column>
              </Grid>
            </Grid.Row>
          </Grid>
          <br></br>
          <br></br>

          <Grid>
            <Header as="h4">FATHER</Header>
            <Grid.Row>
              <Grid.Column style={{ paddingLeft: "40px" }}>
                <Image
                  width={"15%"}
                  verticalAlign="middle"
                  src={
                    dataFather.GimageURL
                      ? dataFather.GimageURL + "?time=" + new Date()
                      : "../storage/imgs/dfkid.png"
                  }
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = "../storage/imgs/dfkid.png";
                  }}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid stackable columns="2" style={{ width: "100%" }}>
                <Grid.Column style={{ paddingLeft: "40px" }}>
                  Passport or ID No.:
                  <b> {dataFather.idnumber}</b>
                </Grid.Column>
                <Grid.Column>
                  Date Of Birth:
                  <b> {dataFather.dateOfBirth}</b>
                </Grid.Column>
              </Grid>
              <Grid stackable columns="4" style={{ width: "100%" }}>
                <Grid.Column style={{ paddingLeft: "40px" }}>
                  firstname:
                  <b> {dataFather.firstName}</b>
                </Grid.Column>
                <Grid.Column>
                  lastname:
                  <b> {dataFather.lastName}</b>
                </Grid.Column>
                <Grid.Column>
                  Nationality:
                  <b> {dataFather.nationality}</b>
                </Grid.Column>
                <Grid.Column>
                  Native Language:
                  <b> {dataFather.nativelanguage}</b>
                </Grid.Column>
              </Grid>
              <Grid stackable columns="4" style={{ width: "100%" }}>
                <Grid.Column style={{ paddingLeft: "40px" }}>
                  ชื่อ:
                  <b> {dataFather.firstName_th}</b>
                </Grid.Column>
                <Grid.Column>
                  นามสกุล:
                  <b> {dataFather.lastName_th}</b>
                </Grid.Column>
                <Grid.Column>
                  Mobile:
                  <b> {dataFather.phone}</b>
                </Grid.Column>
                <Grid.Column>
                  Email:
                  <b> {dataFather.email}</b>
                </Grid.Column>
              </Grid>
              <Grid stackable columns="4" style={{ width: "100%" }}>
                <Grid.Column style={{ paddingLeft: "40px" }}>
                  Place of work:
                  <b> {dataFather.placeOfWork}</b>
                </Grid.Column>
                <Grid.Column>
                  Position:
                  <b> {dataFather.position}</b>
                </Grid.Column>
                <Grid.Column>
                  Office Tel:
                  <b> {dataFather.officePhone}</b>
                </Grid.Column>
              </Grid>
            </Grid.Row>
          </Grid>
          <br></br>
          <br></br>

          <Grid>
            <Header as="h4">MOTHER</Header>
            <Grid.Row>
              <Grid.Column style={{ paddingLeft: "40px" }}>
                <Image
                  width={"15%"}
                  verticalAlign="middle"
                  src={
                    dataMother.GimageURL
                      ? dataMother.GimageURL + "?time=" + new Date()
                      : "../storage/imgs/dfkid.png"
                  }
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = "../storage/imgs/dfkid.png";
                  }}
                />
                {/* <br></br>
              <br></br> */}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid stackable columns="2" style={{ width: "100%" }}>
                <Grid.Column style={{ paddingLeft: "40px" }}>
                  Passport or ID No.:
                  <b> {dataMother.idnumber}</b>
                </Grid.Column>
                <Grid.Column>
                  Date Of Birth:
                  <b> {dataMother.dateOfBirth}</b>
                </Grid.Column>
              </Grid>
              <Grid stackable columns="4" style={{ width: "100%" }}>
                <Grid.Column style={{ paddingLeft: "40px" }}>
                  firstname:
                  <b> {dataMother.firstName}</b>
                </Grid.Column>
                <Grid.Column>
                  lastname:
                  <b> {dataMother.lastName}</b>
                </Grid.Column>
                <Grid.Column>
                  Nationality:
                  <b> {dataMother.nationality}</b>
                </Grid.Column>
                <Grid.Column>
                  Native Language:
                  <b> {dataMother.nativelanguage}</b>
                </Grid.Column>
              </Grid>
              <Grid stackable columns="4" style={{ width: "100%" }}>
                <Grid.Column style={{ paddingLeft: "40px" }}>
                  ชื่อ:
                  <b> {dataMother.firstName_th}</b>
                </Grid.Column>
                <Grid.Column>
                  นามสกุล:
                  <b> {dataMother.lastName_th}</b>
                </Grid.Column>
                <Grid.Column>
                  Mobile:
                  <b> {dataMother.phone}</b>
                </Grid.Column>
                <Grid.Column>
                  Email:
                  <b> {dataMother.email}</b>
                </Grid.Column>
              </Grid>
              <Grid stackable columns="4" style={{ width: "100%" }}>
                <Grid.Column style={{ paddingLeft: "40px" }}>
                  Place of work:
                  <b> {dataMother.placeOfWork}</b>
                </Grid.Column>
                <Grid.Column>
                  Position:
                  <b> {dataMother.position}</b>
                </Grid.Column>
                <Grid.Column>
                  Office Tel:
                  <b> {dataMother.officePhone}</b>
                </Grid.Column>
              </Grid>
              {/* </Grid.Column> */}
            </Grid.Row>
          </Grid>

          <Header as="h4">
            <u>Part II </u>: Student(Cont.)
          </Header>
          <Grid divided stackable>
            <br></br>
            <Header as="h4">Name and ages of student sibling.</Header>
            <Grid.Row>
              <Grid.Column>
                {studentSibling.map != null && (
                  <>
                    {studentSibling.map((item) => (
                      <Grid columns="1" style={{ width: "70%" }}>
                        <Grid.Column style={{ paddingLeft: "40px" }}>
                          <b>{item.name1} </b>
                          <b> {item.age1}</b>
                          <br />
                          <br />
                          <b>{item.name2} </b>
                          <b> {item.age2}</b>
                          <br />
                          <br />
                          <b>{item.name3} </b>
                          <b> {item.age3}</b>
                          <br />
                          <br />
                          <b>{item.name4} </b>
                          <b> {item.age4}</b>
                        </Grid.Column>
                      </Grid>
                    ))}
                  </>
                )}
                {studentSibling.map == null && (
                  <p style={{ color: "red" }}>
                    Not Found Sibling.
                    <br></br>
                    <br></br>
                  </p>
                )}

                <Header as="h4">Emergency contact person.</Header>
                <Card style={{ width: "50%", backgroundColor: "#efefef" }}>
                  <Card.Content>
                    <Grid columns="1" style={{ width: "70%" }}>
                      <Grid.Column>
                        Emergency person:<b> {data.emergency_name}</b>
                      </Grid.Column>
                    </Grid>
                    <Grid columns="2" style={{ width: "70%" }}>
                      <Grid.Column>
                        Contact no.:<b> {data.emergency_phone}</b>
                      </Grid.Column>
                      <Grid.Column>
                        Relationship:<b> {data.emergency_relation}</b>
                      </Grid.Column>
                    </Grid>
                  </Card.Content>
                </Card>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>

        <Header as="h4">
          <u>Part III</u> : Guardian
        </Header>
        <div style={{ paddingLeft: "20px" }}>
          {dataGuardian1 && (
            <>
              <Header as="h4">Guardian 1 : </Header>
              <Grid divided stackable>
                <Grid.Row>
                  <Grid.Column>
                    <Image
                      width={"15%"}
                      verticalAlign="middle"
                      src={
                        dataGuardian1.imageURL
                          ? dataGuardian1.imageURL + "?time=" + new Date()
                          : "../storage/imgs/dfkid.png"
                      }
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = "../storage/imgs/dfkid.png";
                      }}
                    />
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <Grid columns="2" style={{ width: "50%" }}>
                      <Grid.Column style={{ paddingLeft: "40px" }}>
                        Passport or ID No.:
                        <b> {dataGuardian1.idnumber}</b>
                      </Grid.Column>
                      <Grid.Column>
                        Date Of Birth:
                        <b> {dataGuardian1.dateOfBirth}</b>
                      </Grid.Column>
                    </Grid>
                    <Grid columns="4" style={{ width: "80%" }}>
                      <Grid.Column style={{ paddingLeft: "40px" }}>
                        firstname:
                        <b> {dataGuardian1.firstName}</b>
                      </Grid.Column>
                      <Grid.Column>
                        lastname:
                        <b> {dataGuardian1.lastName}</b>
                      </Grid.Column>
                      <Grid.Column>
                        Nationality:
                        <b> {dataGuardian1.nationality}</b>
                      </Grid.Column>
                      <Grid.Column>
                        Native Language:
                        <b> {dataGuardian1.nativelanguage}</b>
                      </Grid.Column>
                    </Grid>
                    <Grid columns="4" style={{ width: "80%" }}>
                      <Grid.Column style={{ paddingLeft: "40px" }}>
                        ชื่อ:
                        <b> {dataGuardian1.firstName_th}</b>
                      </Grid.Column>
                      <Grid.Column>
                        นามสกุล:
                        <b> {dataGuardian1.lastName_th}</b>
                      </Grid.Column>
                      <Grid.Column>
                        Mobile:
                        <b> {dataGuardian1.phone}</b>
                      </Grid.Column>
                      <Grid.Column>
                        Email:
                        <b> {dataGuardian1.email}</b>
                      </Grid.Column>
                    </Grid>
                    <Grid columns="3" style={{ width: "70%" }}>
                      <Grid.Column style={{ paddingLeft: "40px" }}>
                        Place of work:
                        <b> {dataGuardian1.placeOfWork}</b>
                      </Grid.Column>
                      <Grid.Column>
                        Position:
                        <b> {dataGuardian1.position}</b>
                      </Grid.Column>
                      <Grid.Column>
                        Office Tel:
                        <b> {dataGuardian1.officePhone}</b>
                      </Grid.Column>
                    </Grid>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </>
          )}

          {dataGuardian2 && (
            <>
              <Header as="h4">Guardian 2 : </Header>
              <Grid divided stackable>
                <Grid.Row>
                  <Grid.Column>
                    <Image
                      width={"15%"}
                      verticalAlign="middle"
                      src={
                        dataGuardian2.imageURL
                          ? dataGuardian2.imageURL + "?time=" + new Date()
                          : "../storage/imgs/dfkid.png"
                      }
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = "../storage/imgs/dfkid.png";
                      }}
                    />
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <Grid columns="2" style={{ width: "50%" }}>
                      <Grid.Column style={{ paddingLeft: "40px" }}>
                        Passport or ID No.:
                        <b> {dataGuardian2.idnumber}</b>
                      </Grid.Column>
                      <Grid.Column>
                        Date Of Birth:
                        <b> {dataGuardian2.dateOfBirth}</b>
                      </Grid.Column>
                    </Grid>
                    <Grid columns="4" style={{ width: "80%" }}>
                      <Grid.Column style={{ paddingLeft: "40px" }}>
                        firstname:
                        <b> {dataGuardian2.firstName}</b>
                      </Grid.Column>
                      <Grid.Column>
                        lastname:
                        <b> {dataGuardian2.lastName}</b>
                      </Grid.Column>
                      <Grid.Column>
                        Nationality:
                        <b> {dataGuardian2.nationality}</b>
                      </Grid.Column>
                      <Grid.Column>
                        Native Language:
                        <b> {dataGuardian2.nativelanguage}</b>
                      </Grid.Column>
                    </Grid>
                    <Grid columns="4" style={{ width: "80%" }}>
                      <Grid.Column style={{ paddingLeft: "40px" }}>
                        ชื่อ:
                        <b> {dataGuardian2.firstName_th}</b>
                      </Grid.Column>
                      <Grid.Column>
                        นามสกุล:
                        <b> {dataGuardian2.lastName_th}</b>
                      </Grid.Column>
                      <Grid.Column>
                        Mobile:
                        <b> {dataGuardian2.phone}</b>
                      </Grid.Column>
                      <Grid.Column>
                        Email:
                        <b> {dataGuardian2.email}</b>
                      </Grid.Column>
                    </Grid>
                    <Grid columns="3" style={{ width: "70%" }}>
                      <Grid.Column style={{ paddingLeft: "40px" }}>
                        Place of work:
                        <b> {dataGuardian2.placeOfWork}</b>
                      </Grid.Column>
                      <Grid.Column>
                        Position:
                        <b> {dataGuardian2.position}</b>
                      </Grid.Column>
                      <Grid.Column>
                        Office Tel:
                        <b> {dataGuardian2.officePhone}</b>
                      </Grid.Column>
                    </Grid>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </>
          )}
        </div>

        <Header as="h4">
          <u>Part IV</u> : Health And Medical
        </Header>
        <div style={{ paddingLeft: "20px" }}>
          <Header as="h4">
            ประวัติการรับวัคซีน (CERTIFICATE of IMMUIZATION)
          </Header>
          <Table celled padded style={{ borderRadius: 0, fontSize: "95%" }}>
            <Table.Header
              style={{
                boxShadow: "0 0.5px 5px 0 rgb(0 0 0 / 15%)",
                textAlign: "center",
              }}
            >
              <Table.Row>
                <Table.HeaderCell
                  style={{
                    borderRadius: 0,
                    backgroundColor: "#ddd",
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    width: "30%",
                  }}
                >
                  IMMUIZATION
                </Table.HeaderCell>
                <Table.HeaderCell
                  style={{
                    borderRadius: 0,
                    backgroundColor: "#ddd",
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    width: "70%",
                  }}
                >
                  RECECORD COMPLETE DATES OF VACCINE DOSES GIVEN
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              <Table.Row style={{ textAlign: "center" }}>
                <Table.HeaderCell
                  style={{
                    borderRadius: 0,
                    backgroundColor: "#ddd",
                    paddingTop: "5px",
                    paddingBottom: "5px",
                  }}
                ></Table.HeaderCell>
                <Table.HeaderCell
                  style={{
                    borderRadius: 0,
                    backgroundColor: "#ddd",
                    paddingTop: "5px",
                    paddingBottom: "5px",
                  }}
                >
                  <Table style={{ textAlign: "center" }}>
                    <Table.HeaderCell
                      style={{
                        borderRadius: 0,
                        backgroundColor: "#ddd",
                        paddingTop: "5px",
                        paddingBottom: "5px",
                        width: "20%",
                      }}
                    >
                      1
                    </Table.HeaderCell>
                    <Table.HeaderCell
                      style={{
                        borderRadius: 0,
                        backgroundColor: "#ddd",
                        paddingTop: "5px",
                        paddingBottom: "5px",
                        width: "20%",
                      }}
                    >
                      2
                    </Table.HeaderCell>
                    <Table.HeaderCell
                      style={{
                        borderRadius: 0,
                        backgroundColor: "#ddd",
                        paddingTop: "5px",
                        paddingBottom: "5px",
                        width: "20%",
                      }}
                    >
                      3
                    </Table.HeaderCell>
                    <Table.HeaderCell
                      style={{
                        borderRadius: 0,
                        backgroundColor: "#ddd",
                        paddingTop: "5px",
                        paddingBottom: "5px",
                        width: "20%",
                      }}
                    >
                      4
                    </Table.HeaderCell>
                    <Table.HeaderCell
                      style={{
                        borderRadius: 0,
                        backgroundColor: "#ddd",
                        paddingTop: "5px",
                        paddingBottom: "5px",
                        width: "20%",
                      }}
                    >
                      5
                    </Table.HeaderCell>
                  </Table>
                </Table.HeaderCell>
              </Table.Row>
              <Table.Row>
                <Table.Cell
                  style={{
                    borderRadius: 0,
                    backgroundColor: "#eee",
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    width: "20%",
                  }}
                >
                  1.Diphtheria Tetanus Pertussis(DTP)
                </Table.Cell>
                <Table.Cell>
                  <Table style={{ textAlign: "center" }}>
                    <Table.Cell style={{ width: "20%" }}>
                      {immunization_gethealthCheckStudents_health &&
                        immunization_gethealthCheckStudents_health.DTP1}
                    </Table.Cell>
                    <Table.Cell style={{ width: "20%" }}>
                      {immunization_gethealthCheckStudents_health &&
                        immunization_gethealthCheckStudents_health.DTP2}
                    </Table.Cell>
                    <Table.Cell style={{ width: "20%" }}>
                      {immunization_gethealthCheckStudents_health &&
                        immunization_gethealthCheckStudents_health.DTP3}
                    </Table.Cell>
                    <Table.Cell style={{ width: "20%" }}>
                      {immunization_gethealthCheckStudents_health &&
                        immunization_gethealthCheckStudents_health.DTP4}
                    </Table.Cell>
                    <Table.Cell style={{ width: "20%" }}>
                      {immunization_gethealthCheckStudents_health &&
                        immunization_gethealthCheckStudents_health.DTP5}
                    </Table.Cell>
                  </Table>
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell
                  style={{
                    borderRadius: 0,
                    backgroundColor: "#eee",
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    width: "20%",
                  }}
                >
                  2.Diphtheria Tetanus(Td)
                </Table.Cell>
                <Table.Cell>
                  <Table style={{ textAlign: "center" }}>
                    <Table.Cell style={{ width: "20%" }}>
                      {immunization_gethealthCheckStudents_health &&
                        immunization_gethealthCheckStudents_health.Td1}
                    </Table.Cell>
                    <Table.Cell style={{ width: "20%" }}>
                      {immunization_gethealthCheckStudents_health &&
                        immunization_gethealthCheckStudents_health.Td2}
                    </Table.Cell>
                    <Table.Cell style={{ width: "20%" }}>
                      {immunization_gethealthCheckStudents_health &&
                        immunization_gethealthCheckStudents_health.Td3}
                    </Table.Cell>
                    <Table.Cell style={{ width: "20%" }}>
                      {immunization_gethealthCheckStudents_health &&
                        immunization_gethealthCheckStudents_health.Td4}
                    </Table.Cell>
                    <Table.Cell style={{ width: "20%" }}>
                      {immunization_gethealthCheckStudents_health &&
                        immunization_gethealthCheckStudents_health.Td5}
                    </Table.Cell>
                  </Table>
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell
                  style={{
                    borderRadius: 0,
                    backgroundColor: "#eee",
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    width: "20%",
                  }}
                >
                  3.Poliomyelitis(OPV)
                </Table.Cell>
                <Table.Cell>
                  <Table style={{ textAlign: "center" }}>
                    <Table.Cell style={{ width: "20%" }}>
                      {immunization_gethealthCheckStudents_health &&
                        immunization_gethealthCheckStudents_health.OPV1}
                    </Table.Cell>
                    <Table.Cell style={{ width: "20%" }}>
                      {immunization_gethealthCheckStudents_health &&
                        immunization_gethealthCheckStudents_health.OPV2}
                    </Table.Cell>
                    <Table.Cell style={{ width: "20%" }}>
                      {immunization_gethealthCheckStudents_health &&
                        immunization_gethealthCheckStudents_health.OPV3}
                    </Table.Cell>
                    <Table.Cell style={{ width: "20%" }}>
                      {immunization_gethealthCheckStudents_health &&
                        immunization_gethealthCheckStudents_health.OPV4}
                    </Table.Cell>
                    <Table.Cell style={{ width: "20%" }}>
                      {immunization_gethealthCheckStudents_health &&
                        immunization_gethealthCheckStudents_health.OPV5}
                    </Table.Cell>
                  </Table>
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell
                  style={{
                    borderRadius: 0,
                    backgroundColor: "#eee",
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    width: "20%",
                  }}
                >
                  4.Measles Booster
                </Table.Cell>
                <Table.Cell>
                  <Table style={{ textAlign: "center" }}>
                    <Table.Cell style={{ width: "20%" }}>
                      {immunization_gethealthCheckStudents_health &&
                        immunization_gethealthCheckStudents_health.Measles_Booster1}
                    </Table.Cell>
                    <Table.Cell style={{ width: "20%" }}>
                      {immunization_gethealthCheckStudents_health &&
                        immunization_gethealthCheckStudents_health.Measles_Booster2}
                    </Table.Cell>
                    <Table.Cell
                      style={{ width: "20%", backgroundColor: "#eee" }}
                    ></Table.Cell>
                    <Table.Cell
                      style={{ width: "20%", backgroundColor: "#eee" }}
                    ></Table.Cell>
                    <Table.Cell
                      style={{ width: "20%", backgroundColor: "#eee" }}
                    ></Table.Cell>
                  </Table>
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell
                  style={{
                    borderRadius: 0,
                    backgroundColor: "#eee",
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    width: "20%",
                  }}
                >
                  5.Rubella
                </Table.Cell>
                <Table.Cell>
                  <Table style={{ textAlign: "center" }}>
                    <Table.Cell style={{ width: "20%" }}>
                      {immunization_gethealthCheckStudents_health &&
                        immunization_gethealthCheckStudents_health.Rubella}
                    </Table.Cell>
                    <Table.Cell
                      style={{ width: "20%", backgroundColor: "#eee" }}
                    ></Table.Cell>
                    <Table.Cell
                      style={{ width: "20%", backgroundColor: "#eee" }}
                    ></Table.Cell>
                    <Table.Cell
                      style={{ width: "20%", backgroundColor: "#eee" }}
                    ></Table.Cell>
                    <Table.Cell
                      style={{ width: "20%", backgroundColor: "#eee" }}
                    ></Table.Cell>
                  </Table>
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell
                  style={{
                    borderRadius: 0,
                    backgroundColor: "#eee",
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    width: "20%",
                  }}
                >
                  6.Mumps
                </Table.Cell>
                <Table.Cell>
                  <Table style={{ textAlign: "center" }}>
                    <Table.Cell style={{ width: "20%" }}>
                      {immunization_gethealthCheckStudents_health &&
                        immunization_gethealthCheckStudents_health.vaccineMumps}
                    </Table.Cell>
                    <Table.Cell
                      style={{ width: "20%", backgroundColor: "#eee" }}
                    ></Table.Cell>
                    <Table.Cell
                      style={{ width: "20%", backgroundColor: "#eee" }}
                    ></Table.Cell>
                    <Table.Cell
                      style={{ width: "20%", backgroundColor: "#eee" }}
                    ></Table.Cell>
                    <Table.Cell
                      style={{ width: "20%", backgroundColor: "#eee" }}
                    ></Table.Cell>
                  </Table>
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell
                  style={{
                    borderRadius: 0,
                    backgroundColor: "#eee",
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    width: "20%",
                  }}
                >
                  7.Measles,Mumps,Rubella(MMR)
                </Table.Cell>
                <Table.Cell>
                  <Table style={{ textAlign: "center" }}>
                    <Table.Cell style={{ width: "20%" }}>
                      {immunization_gethealthCheckStudents_health &&
                        immunization_gethealthCheckStudents_health.MMR}
                    </Table.Cell>
                    <Table.Cell
                      style={{ width: "20%", backgroundColor: "#eee" }}
                    ></Table.Cell>
                    <Table.Cell
                      style={{ width: "20%", backgroundColor: "#eee" }}
                    ></Table.Cell>
                    <Table.Cell
                      style={{ width: "20%", backgroundColor: "#eee" }}
                    ></Table.Cell>
                    <Table.Cell
                      style={{ width: "20%", backgroundColor: "#eee" }}
                    ></Table.Cell>
                  </Table>
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
          <u>ข้อมูลเกี่ยวกับสุขภาพ</u>
          <p>Please check / any that this child has had</p>
          <Table
            celled
            padded
            style={{ borderRadius: 0, fontSize: "90%", width: "80%" }}
          >
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell
                  style={{
                    borderRadius: 0,
                    backgroundColor: "#ddd",
                    paddingTop: "5px",
                    paddingBottom: "5px",
                  }}
                >
                  Conditions
                </Table.HeaderCell>
                <Table.HeaderCell
                  style={{
                    borderRadius: 0,
                    backgroundColor: "#ddd",
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    textAlign: "center",
                  }}
                >
                  Yes/No
                </Table.HeaderCell>
                <Table.HeaderCell
                  style={{
                    borderRadius: 0,
                    backgroundColor: "#ddd",
                    paddingTop: "5px",
                    paddingBottom: "5px",
                  }}
                >
                  Conditions
                </Table.HeaderCell>
                <Table.HeaderCell
                  style={{
                    borderRadius: 0,
                    backgroundColor: "#ddd",
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    textAlign: "center",
                  }}
                >
                  Yes/No
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              <Table.Row>
                <Table.Cell
                  style={{
                    borderRadius: 0,
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    width: "30%",
                  }}
                >
                  1.Abnormal spinal curvature (scoliosis, etc.)
                </Table.Cell>
                <Table.Cell
                  style={{
                    borderRadius: 0,
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    width: "10%",
                    textAlign: "center",
                  }}
                >
                  {immunization_gethealthCheckStudents_health && (
                    <>
                      {immunization_gethealthCheckStudents_health.Abnormal_spinal ==
                        null && "No"}
                      {immunization_gethealthCheckStudents_health.Abnormal_spinal ==
                        0 && "No"}
                      {immunization_gethealthCheckStudents_health.Abnormal_spinal ==
                        1 && "Yes"}
                    </>
                  )}
                </Table.Cell>
                <Table.Cell
                  style={{
                    borderRadius: 0,
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    width: "30%",
                  }}
                >
                  19.Hepatitis
                </Table.Cell>
                <Table.Cell
                  style={{
                    borderRadius: 0,
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    width: "10%",
                    textAlign: "center",
                  }}
                >
                  {immunization_gethealthCheckStudents_health && (
                    <>
                      {immunization_gethealthCheckStudents_health.Hepatitis ==
                        null && "No"}
                      {immunization_gethealthCheckStudents_health.Hepatitis ==
                        0 && "No"}
                      {immunization_gethealthCheckStudents_health.Hepatitis ==
                        1 && "Yes"}
                    </>
                  )}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell
                  style={{
                    borderRadius: 0,
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    width: "30%",
                  }}
                >
                  2.Anemia
                </Table.Cell>
                <Table.Cell
                  style={{
                    borderRadius: 0,
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    width: "10%",
                    textAlign: "center",
                  }}
                >
                  {immunization_gethealthCheckStudents_health && (
                    <>
                      {immunization_gethealthCheckStudents_health.Anemia ==
                        null && "No"}
                      {immunization_gethealthCheckStudents_health.Anemia == 0 &&
                        "No"}
                      {immunization_gethealthCheckStudents_health.Anemia == 1 &&
                        "Yes"}
                    </>
                  )}
                </Table.Cell>
                <Table.Cell
                  style={{
                    borderRadius: 0,
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    width: "30%",
                  }}
                >
                  20.Measles (old fashioned or "ten day")
                </Table.Cell>
                <Table.Cell
                  style={{
                    borderRadius: 0,
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    width: "10%",
                    textAlign: "center",
                  }}
                >
                  {immunization_gethealthCheckStudents_health && (
                    <>
                      {immunization_gethealthCheckStudents_health.Measles ==
                        null && "No"}
                      {immunization_gethealthCheckStudents_health.Measles ==
                        0 && "No"}
                      {immunization_gethealthCheckStudents_health.Measles ==
                        1 && "Yes"}
                    </>
                  )}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell
                  style={{
                    borderRadius: 0,
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    width: "30%",
                  }}
                >
                  3.Asthma or wheezing
                </Table.Cell>
                <Table.Cell
                  style={{
                    borderRadius: 0,
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    width: "10%",
                    textAlign: "center",
                  }}
                >
                  {immunization_gethealthCheckStudents_health && (
                    <>
                      {immunization_gethealthCheckStudents_health.Asthma_or_wheezing ==
                        null && "No"}
                      {immunization_gethealthCheckStudents_health.Asthma_or_wheezing ==
                        0 && "No"}
                      {immunization_gethealthCheckStudents_health.Asthma_or_wheezing ==
                        1 && "Yes"}
                    </>
                  )}
                </Table.Cell>
                <Table.Cell
                  style={{
                    borderRadius: 0,
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    width: "30%",
                  }}
                >
                  21.Meninggitis or encephalitis
                </Table.Cell>
                <Table.Cell
                  style={{
                    borderRadius: 0,
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    width: "10%",
                    textAlign: "center",
                  }}
                >
                  {immunization_gethealthCheckStudents_health && (
                    <>
                      {immunization_gethealthCheckStudents_health.Meninggitis ==
                        null && "No"}
                      {immunization_gethealthCheckStudents_health.Meninggitis ==
                        0 && "No"}
                      {immunization_gethealthCheckStudents_health.Meninggitis ==
                        1 && "Yes"}
                    </>
                  )}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell
                  style={{
                    borderRadius: 0,
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    width: "30%",
                  }}
                >
                  4.Bedwetting at night
                </Table.Cell>
                <Table.Cell
                  style={{
                    borderRadius: 0,
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    width: "10%",
                    textAlign: "center",
                  }}
                >
                  {immunization_gethealthCheckStudents_health && (
                    <>
                      {immunization_gethealthCheckStudents_health.Bedwetting_at_night ==
                        null && "No"}
                      {immunization_gethealthCheckStudents_health.Bedwetting_at_night ==
                        0 && "No"}
                      {immunization_gethealthCheckStudents_health.Bedwetting_at_night ==
                        1 && "Yes"}
                    </>
                  )}
                </Table.Cell>
                <Table.Cell
                  style={{
                    borderRadius: 0,
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    width: "30%",
                  }}
                >
                  22.Multiple ear infections (3 or more)
                </Table.Cell>
                <Table.Cell
                  style={{
                    borderRadius: 0,
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    width: "10%",
                    textAlign: "center",
                  }}
                >
                  {immunization_gethealthCheckStudents_health && (
                    <>
                      {immunization_gethealthCheckStudents_health.Multiple_ear ==
                        null && "No"}
                      {immunization_gethealthCheckStudents_health.Multiple_ear ==
                        0 && "No"}
                      {immunization_gethealthCheckStudents_health.Multiple_ear ==
                        1 && "Yes"}
                    </>
                  )}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell
                  style={{
                    borderRadius: 0,
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    width: "30%",
                  }}
                >
                  5.Behavior problem
                </Table.Cell>
                <Table.Cell
                  style={{
                    borderRadius: 0,
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    width: "10%",
                    textAlign: "center",
                  }}
                >
                  {immunization_gethealthCheckStudents_health && (
                    <>
                      {immunization_gethealthCheckStudents_health.Behavior_problem ==
                        null && "No"}
                      {immunization_gethealthCheckStudents_health.Behavior_problem ==
                        0 && "No"}
                      {immunization_gethealthCheckStudents_health.Behavior_problem ==
                        1 && "Yes"}
                    </>
                  )}
                </Table.Cell>
                <Table.Cell
                  style={{
                    borderRadius: 0,
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    width: "30%",
                  }}
                >
                  23.Wetting during day
                </Table.Cell>
                <Table.Cell
                  style={{
                    borderRadius: 0,
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    width: "10%",
                    textAlign: "center",
                  }}
                >
                  {immunization_gethealthCheckStudents_health && (
                    <>
                      {immunization_gethealthCheckStudents_health.Wetting_during_day ==
                        null && "No"}
                      {immunization_gethealthCheckStudents_health.Wetting_during_day ==
                        0 && "No"}
                      {immunization_gethealthCheckStudents_health.Wetting_during_day ==
                        1 && "Yes"}
                    </>
                  )}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell
                  style={{
                    borderRadius: 0,
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    width: "30%",
                  }}
                >
                  6.Near-drowning or near-suffocation
                </Table.Cell>
                <Table.Cell
                  style={{
                    borderRadius: 0,
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    width: "10%",
                    textAlign: "center",
                  }}
                >
                  {immunization_gethealthCheckStudents_health && (
                    <>
                      {immunization_gethealthCheckStudents_health.Neardrowning ==
                        null && "No"}
                      {immunization_gethealthCheckStudents_health.Neardrowning ==
                        0 && "No"}
                      {immunization_gethealthCheckStudents_health.Neardrowning ==
                        1 && "Yes"}
                    </>
                  )}
                </Table.Cell>
                <Table.Cell
                  style={{
                    borderRadius: 0,
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    width: "30%",
                  }}
                >
                  24.Urinary tract infections
                </Table.Cell>
                <Table.Cell
                  style={{
                    borderRadius: 0,
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    width: "10%",
                    textAlign: "center",
                  }}
                >
                  {immunization_gethealthCheckStudents_health && (
                    <>
                      {immunization_gethealthCheckStudents_health.Urinary_tract_infections ==
                        null && "No"}
                      {immunization_gethealthCheckStudents_health.Urinary_tract_infections ==
                        0 && "No"}
                      {immunization_gethealthCheckStudents_health.Urinary_tract_infections ==
                        1 && "Yes"}
                    </>
                  )}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell
                  style={{
                    borderRadius: 0,
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    width: "30%",
                  }}
                >
                  7.Nervous twitches or tics
                </Table.Cell>
                <Table.Cell
                  style={{
                    borderRadius: 0,
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    width: "10%",
                    textAlign: "center",
                  }}
                >
                  {immunization_gethealthCheckStudents_health && (
                    <>
                      {immunization_gethealthCheckStudents_health.Nervous_twitches ==
                        null && "No"}
                      {immunization_gethealthCheckStudents_health.Nervous_twitches ==
                        0 && "No"}
                      {immunization_gethealthCheckStudents_health.Nervous_twitches ==
                        1 && "Yes"}
                    </>
                  )}
                </Table.Cell>
                <Table.Cell
                  style={{
                    borderRadius: 0,
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    width: "30%",
                  }}
                >
                  25.Birth or congenital malformation
                </Table.Cell>
                <Table.Cell
                  style={{
                    borderRadius: 0,
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    width: "10%",
                    textAlign: "center",
                  }}
                >
                  {immunization_gethealthCheckStudents_health && (
                    <>
                      {immunization_gethealthCheckStudents_health.Birth_or_congenital ==
                        null && "No"}
                      {immunization_gethealthCheckStudents_health.Birth_or_congenital ==
                        0 && "No"}
                      {immunization_gethealthCheckStudents_health.Birth_or_congenital ==
                        1 && "Yes"}
                    </>
                  )}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell
                  style={{
                    borderRadius: 0,
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    width: "30%",
                  }}
                >
                  8.Chronic diarrhea or constipation
                </Table.Cell>
                <Table.Cell
                  style={{
                    borderRadius: 0,
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    width: "10%",
                    textAlign: "center",
                  }}
                >
                  {immunization_gethealthCheckStudents_health && (
                    <>
                      {immunization_gethealthCheckStudents_health.Chronic_diarrhea ==
                        null && "No"}
                      {immunization_gethealthCheckStudents_health.Chronic_diarrhea ==
                        0 && "No"}
                      {immunization_gethealthCheckStudents_health.Chronic_diarrhea ==
                        1 && "Yes"}
                    </>
                  )}
                </Table.Cell>
                <Table.Cell
                  style={{
                    borderRadius: 0,
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    width: "30%",
                  }}
                >
                  26.Chicken pox
                </Table.Cell>
                <Table.Cell
                  style={{
                    borderRadius: 0,
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    width: "10%",
                    textAlign: "center",
                  }}
                >
                  {immunization_gethealthCheckStudents_health && (
                    <>
                      {immunization_gethealthCheckStudents_health.Chicken_pox ==
                        null && "No"}
                      {immunization_gethealthCheckStudents_health.Chicken_pox ==
                        0 && "No"}
                      {immunization_gethealthCheckStudents_health.Chicken_pox ==
                        1 && "Yes"}
                    </>
                  )}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell
                  style={{
                    borderRadius: 0,
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    width: "30%",
                  }}
                >
                  9.Concern about relationship with sibling or friends
                </Table.Cell>
                <Table.Cell
                  style={{
                    borderRadius: 0,
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    width: "10%",
                    textAlign: "center",
                  }}
                >
                  {immunization_gethealthCheckStudents_health && (
                    <>
                      {immunization_gethealthCheckStudents_health.Concern_about_relationship ==
                        null && "No"}
                      {immunization_gethealthCheckStudents_health.Concern_about_relationship ==
                        0 && "No"}
                      {immunization_gethealthCheckStudents_health.Concern_about_relationship ==
                        1 && "Yes"}
                    </>
                  )}
                </Table.Cell>
                <Table.Cell
                  style={{
                    borderRadius: 0,
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    width: "30%",
                  }}
                >
                  27.Diabetes
                </Table.Cell>
                <Table.Cell
                  style={{
                    borderRadius: 0,
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    width: "10%",
                    textAlign: "center",
                  }}
                >
                  {immunization_gethealthCheckStudents_health && (
                    <>
                      {immunization_gethealthCheckStudents_health.Diabetes ==
                        null && "No"}
                      {immunization_gethealthCheckStudents_health.Diabetes ==
                        0 && "No"}
                      {immunization_gethealthCheckStudents_health.Diabetes ==
                        1 && "Yes"}
                    </>
                  )}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell
                  style={{
                    borderRadius: 0,
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    width: "30%",
                  }}
                >
                  10.Cystic fibrosis
                </Table.Cell>
                <Table.Cell
                  style={{
                    borderRadius: 0,
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    width: "10%",
                    textAlign: "center",
                  }}
                >
                  {immunization_gethealthCheckStudents_health && (
                    <>
                      {immunization_gethealthCheckStudents_health.Cystic_fibrosis ==
                        null && "No"}
                      {immunization_gethealthCheckStudents_health.Cystic_fibrosis ==
                        0 && "No"}
                      {immunization_gethealthCheckStudents_health.Cystic_fibrosis ==
                        1 && "Yes"}
                    </>
                  )}
                </Table.Cell>
                <Table.Cell
                  style={{
                    borderRadius: 0,
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    width: "30%",
                  }}
                >
                  28.Eczema
                </Table.Cell>
                <Table.Cell
                  style={{
                    borderRadius: 0,
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    width: "10%",
                    textAlign: "center",
                  }}
                >
                  {immunization_gethealthCheckStudents_health && (
                    <>
                      {immunization_gethealthCheckStudents_health.Eczema ==
                        null && "No"}
                      {immunization_gethealthCheckStudents_health.Eczema == 0 &&
                        "No"}
                      {immunization_gethealthCheckStudents_health.Eczema == 1 &&
                        "Yes"}
                    </>
                  )}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell
                  style={{
                    borderRadius: 0,
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    width: "30%",
                  }}
                >
                  11.Eye problems, poor vision
                </Table.Cell>
                <Table.Cell
                  style={{
                    borderRadius: 0,
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    width: "10%",
                    textAlign: "center",
                  }}
                >
                  {immunization_gethealthCheckStudents_health && (
                    <>
                      {immunization_gethealthCheckStudents_health.Eye_problems ==
                        null && "No"}
                      {immunization_gethealthCheckStudents_health.Eye_problems ==
                        0 && "No"}
                      {immunization_gethealthCheckStudents_health.Eye_problems ==
                        1 && "Yes"}
                    </>
                  )}
                </Table.Cell>
                <Table.Cell
                  style={{
                    borderRadius: 0,
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    width: "30%",
                  }}
                >
                  29.Emotional Problems
                </Table.Cell>
                <Table.Cell
                  style={{
                    borderRadius: 0,
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    width: "10%",
                    textAlign: "center",
                  }}
                >
                  {immunization_gethealthCheckStudents_health && (
                    <>
                      {immunization_gethealthCheckStudents_health.Emotional_Problems ==
                        null && "No"}
                      {immunization_gethealthCheckStudents_health.Emotional_Problems ==
                        0 && "No"}
                      {immunization_gethealthCheckStudents_health.Emotional_Problems ==
                        1 && "Yes"}
                    </>
                  )}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell
                  style={{
                    borderRadius: 0,
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    width: "30%",
                  }}
                >
                  12.Frequent skin infections
                </Table.Cell>
                <Table.Cell
                  style={{
                    borderRadius: 0,
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    width: "10%",
                    textAlign: "center",
                  }}
                >
                  {immunization_gethealthCheckStudents_health && (
                    <>
                      {immunization_gethealthCheckStudents_health.Frequent_skin ==
                        null && "No"}
                      {immunization_gethealthCheckStudents_health.Frequent_skin ==
                        0 && "No"}
                      {immunization_gethealthCheckStudents_health.Frequent_skin ==
                        1 && "Yes"}
                    </>
                  )}
                </Table.Cell>
                <Table.Cell
                  style={{
                    borderRadius: 0,
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    width: "30%",
                  }}
                >
                  30.Frequent headaches
                </Table.Cell>
                <Table.Cell
                  style={{
                    borderRadius: 0,
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    width: "10%",
                    textAlign: "center",
                  }}
                >
                  {immunization_gethealthCheckStudents_health && (
                    <>
                      {immunization_gethealthCheckStudents_health.Frequent_headaches ==
                        null && "No"}
                      {immunization_gethealthCheckStudents_health.Frequent_headaches ==
                        0 && "No"}
                      {immunization_gethealthCheckStudents_health.Frequent_headaches ==
                        1 && "Yes"}
                    </>
                  )}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell
                  style={{
                    borderRadius: 0,
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    width: "30%",
                  }}
                >
                  13.Heart disease, type
                </Table.Cell>
                <Table.Cell
                  style={{
                    borderRadius: 0,
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    width: "10%",
                    textAlign: "center",
                  }}
                >
                  {immunization_gethealthCheckStudents_health && (
                    <>
                      {immunization_gethealthCheckStudents_health.Heart_disease ==
                        null && "No"}
                      {immunization_gethealthCheckStudents_health.Heart_disease ==
                        0 && "No"}
                      {immunization_gethealthCheckStudents_health.Heart_disease ==
                        1 && "Yes"}
                    </>
                  )}
                </Table.Cell>
                <Table.Cell
                  style={{
                    borderRadius: 0,
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    width: "30%",
                  }}
                >
                  31.Frequent sore throat infections
                </Table.Cell>
                <Table.Cell
                  style={{
                    borderRadius: 0,
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    width: "10%",
                    textAlign: "center",
                  }}
                >
                  {immunization_gethealthCheckStudents_health && (
                    <>
                      {immunization_gethealthCheckStudents_health.Frequent_sore_throat ==
                        null && "No"}
                      {immunization_gethealthCheckStudents_health.Frequent_sore_throat ==
                        0 && "No"}
                      {immunization_gethealthCheckStudents_health.Frequent_sore_throat ==
                        1 && "Yes"}
                    </>
                  )}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell
                  style={{
                    borderRadius: 0,
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    width: "30%",
                  }}
                >
                  14.Poisoning
                </Table.Cell>
                <Table.Cell
                  style={{
                    borderRadius: 0,
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    width: "10%",
                    textAlign: "center",
                  }}
                >
                  {immunization_gethealthCheckStudents_health && (
                    <>
                      {immunization_gethealthCheckStudents_health.Poisoning ==
                        null && "No"}
                      {immunization_gethealthCheckStudents_health.Poisoning ==
                        0 && "No"}
                      {immunization_gethealthCheckStudents_health.Poisoning ==
                        1 && "Yes"}
                    </>
                  )}
                </Table.Cell>
                <Table.Cell
                  style={{
                    borderRadius: 0,
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    width: "30%",
                  }}
                >
                  32.Mumps
                </Table.Cell>
                <Table.Cell
                  style={{
                    borderRadius: 0,
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    width: "10%",
                    textAlign: "center",
                  }}
                >
                  {immunization_gethealthCheckStudents_health && (
                    <>
                      {immunization_gethealthCheckStudents_health.Mumps ==
                        null && "No"}
                      {immunization_gethealthCheckStudents_health.Mumps == 0 &&
                        "No"}
                      {immunization_gethealthCheckStudents_health.Mumps == 1 &&
                        "Yes"}
                    </>
                  )}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell
                  style={{
                    borderRadius: 0,
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    width: "30%",
                  }}
                >
                  15.Rheumatic fever
                </Table.Cell>
                <Table.Cell
                  style={{
                    borderRadius: 0,
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    width: "10%",
                    textAlign: "center",
                  }}
                >
                  {immunization_gethealthCheckStudents_health && (
                    <>
                      {immunization_gethealthCheckStudents_health.Rheumatic_fever ==
                        null && "No"}
                      {immunization_gethealthCheckStudents_health.Rheumatic_fever ==
                        0 && "No"}
                      {immunization_gethealthCheckStudents_health.Rheumatic_fever ==
                        1 && "Yes"}
                    </>
                  )}
                </Table.Cell>
                <Table.Cell
                  style={{
                    borderRadius: 0,
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    width: "30%",
                  }}
                >
                  33.Poor hearing
                </Table.Cell>
                <Table.Cell
                  style={{
                    borderRadius: 0,
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    width: "10%",
                    textAlign: "center",
                  }}
                >
                  {immunization_gethealthCheckStudents_health && (
                    <>
                      {immunization_gethealthCheckStudents_health.Poor_hearing ==
                        null && "No"}
                      {immunization_gethealthCheckStudents_health.Poor_hearing ==
                        0 && "No"}
                      {immunization_gethealthCheckStudents_health.Poor_hearing ==
                        1 && "Yes"}
                    </>
                  )}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell
                  style={{
                    borderRadius: 0,
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    width: "30%",
                  }}
                >
                  16.Sickle cell disease
                </Table.Cell>
                <Table.Cell
                  style={{
                    borderRadius: 0,
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    width: "10%",
                    textAlign: "center",
                  }}
                >
                  {immunization_gethealthCheckStudents_health && (
                    <>
                      {immunization_gethealthCheckStudents_health.Sickle_cell ==
                        null && "No"}
                      {immunization_gethealthCheckStudents_health.Sickle_cell ==
                        0 && "No"}
                      {immunization_gethealthCheckStudents_health.Sickle_cell ==
                        1 && "Yes"}
                    </>
                  )}
                </Table.Cell>
                <Table.Cell
                  style={{
                    borderRadius: 0,
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    width: "30%",
                  }}
                >
                  34.Seizures or epilepsy
                </Table.Cell>
                <Table.Cell
                  style={{
                    borderRadius: 0,
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    width: "10%",
                    textAlign: "center",
                  }}
                >
                  {immunization_gethealthCheckStudents_health && (
                    <>
                      {immunization_gethealthCheckStudents_health.Seizures ==
                        null && "No"}
                      {immunization_gethealthCheckStudents_health.Seizures ==
                        0 && "No"}
                      {immunization_gethealthCheckStudents_health.Seizures ==
                        1 && "Yes"}
                    </>
                  )}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell
                  style={{
                    borderRadius: 0,
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    width: "30%",
                  }}
                >
                  17.Kidney disease, type
                </Table.Cell>
                <Table.Cell
                  style={{
                    borderRadius: 0,
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    width: "10%",
                    textAlign: "center",
                  }}
                >
                  {immunization_gethealthCheckStudents_health && (
                    <>
                      {immunization_gethealthCheckStudents_health.Kidney_disease ==
                        null && "No"}
                      {immunization_gethealthCheckStudents_health.Kidney_disease ==
                        0 && "No"}
                      {immunization_gethealthCheckStudents_health.Kidney_disease ==
                        1 && "Yes"}
                    </>
                  )}
                </Table.Cell>
                <Table.Cell
                  style={{
                    borderRadius: 0,
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    width: "30%",
                  }}
                >
                  35.Stool soiling
                </Table.Cell>
                <Table.Cell
                  style={{
                    borderRadius: 0,
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    width: "10%",
                    textAlign: "center",
                  }}
                >
                  {immunization_gethealthCheckStudents_health && (
                    <>
                      {immunization_gethealthCheckStudents_health.Stool ==
                        null && "No"}
                      {immunization_gethealthCheckStudents_health.Stool == 0 &&
                        "No"}
                      {immunization_gethealthCheckStudents_health.Stool == 1 &&
                        "Yes"}
                    </>
                  )}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell
                  style={{
                    borderRadius: 0,
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    width: "30%",
                  }}
                >
                  18.Allergies or hay fever
                </Table.Cell>
                <Table.Cell
                  style={{
                    borderRadius: 0,
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    width: "10%",
                    textAlign: "center",
                  }}
                >
                  {immunization_gethealthCheckStudents_health && (
                    <>
                      {immunization_gethealthCheckStudents_health.Allergies ==
                        null && "No"}
                      {immunization_gethealthCheckStudents_health.Allergies ==
                        0 && "No"}
                      {immunization_gethealthCheckStudents_health.Allergies ==
                        1 && "Yes"}
                    </>
                  )}
                </Table.Cell>
                <Table.Cell
                  style={{
                    borderRadius: 0,
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    width: "30%",
                  }}
                >
                  36.Toothaches or dental infections
                </Table.Cell>
                <Table.Cell
                  style={{
                    borderRadius: 0,
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    width: "10%",
                    textAlign: "center",
                  }}
                >
                  {immunization_gethealthCheckStudents_health && (
                    <>
                      {immunization_gethealthCheckStudents_health.Toothaches ==
                        null && "No"}
                      {immunization_gethealthCheckStudents_health.Toothaches ==
                        0 && "No"}
                      {immunization_gethealthCheckStudents_health.Toothaches ==
                        1 && "Yes"}
                    </>
                  )}
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
          <b>What medication are grien frequently/daily</b>
          <Grid>
            <Grid.Row>
              <Grid.Column width="4">
                ข้อมูลการใช้ยาเป็นประจำ :{" "}
                <b>
                  {immunization_gethealthCheckStudents_health &&
                    immunization_gethealthCheckStudents_health.medication}
                </b>
              </Grid.Column>
              <Grid.Column width="4">
                Allergy information :{" "}
                <b>
                  {immunization_gethealthCheckStudents_health &&
                    immunization_gethealthCheckStudents_health.allergy}
                </b>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <Grid>
            <Grid.Row>
              <Grid.Column width="4">
                Allergy reaction :{" "}
                <b>
                  {immunization_gethealthCheckStudents_health &&
                    immunization_gethealthCheckStudents_health.reaction}
                </b>
              </Grid.Column>
              <Grid.Column width="4">
                Allergy treatment :{" "}
                <b>
                  {immunization_gethealthCheckStudents_health &&
                    immunization_gethealthCheckStudents_health.treatment}
                </b>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <br></br>
          <b>ข้อมูลโรงพยาบาลและแพทย์ที่ดูแลเป็นประจำ</b>
          <Grid>
            <Grid.Row>
              <Grid.Column width="4">
                Hospital Name :{" "}
                <b>
                  {immunization_gethealthCheckStudents_health &&
                    immunization_gethealthCheckStudents_health.hospital_name}
                </b>
              </Grid.Column>
              <Grid.Column width="4">
                H/N No. :{" "}
                <b>
                  {immunization_gethealthCheckStudents_health &&
                    immunization_gethealthCheckStudents_health.h_n_no}
                </b>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <Grid>
            <Grid.Row>
              <Grid.Column width="4">
                Doctor's name :{" "}
                <b>
                  {immunization_gethealthCheckStudents_health &&
                    immunization_gethealthCheckStudents_health.doctor_name}
                </b>
              </Grid.Column>
              <Grid.Column width="4">
                Doctor's Phone :{" "}
                <b>
                  {immunization_gethealthCheckStudents_health &&
                    immunization_gethealthCheckStudents_health.doctor_phone}
                </b>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>

        <Header as="h4">
          <u>Part V : Sumitted Document (Digital File)</u>
        </Header>
        <Grid columns="2" style={{ width: "60%" }}>
          <Grid.Column style={{ width: "30%" }}>
            <b>Student's Birth Certificate</b>
          </Grid.Column>
          <Grid.Column style={{ width: "70%" }}>
            <Card>
              <Card.Content>
                {document.docType1 != null && (
                  <a href={document.docType1}>
                    ไฟล์ Student's Birth Certificate
                  </a>
                )}
                {document.docType1 == null && (
                  <p style={{ color: "red" }}>File Not Found</p>
                )}
              </Card.Content>
            </Card>
          </Grid.Column>
        </Grid>
        <Grid columns="2" style={{ width: "60%" }}>
          <Grid.Column style={{ width: "30%" }}>
            <b>Home Registation</b>
          </Grid.Column>
          <Grid.Column style={{ width: "70%" }}>
            <Card>
              <Card.Content>
                {document.docType2 != null && (
                  <a href={document.docType2}>ไฟล์ Home Registation</a>
                )}
                {document.docType2 == null && (
                  <p style={{ color: "red" }}>File Not Found</p>
                )}
              </Card.Content>
            </Card>
          </Grid.Column>
        </Grid>
        <Grid columns="2" style={{ width: "60%" }}>
          <Grid.Column style={{ width: "30%" }}>
            <b>Father ID / Passport</b>
          </Grid.Column>
          <Grid.Column style={{ width: "70%" }}>
            <Card>
              <Card.Content>
                {document.docType3 != null && (
                  <a href={document.docType3}>ไฟล์ Father ID / Passport</a>
                )}
                {document.docType3 == null && (
                  <p style={{ color: "red" }}>File Not Found</p>
                )}
              </Card.Content>
            </Card>
          </Grid.Column>
        </Grid>
        <Grid columns="2" style={{ width: "60%" }}>
          <Grid.Column style={{ width: "30%" }}>
            <b>Mother ID / Passport</b>
          </Grid.Column>
          <Grid.Column style={{ width: "70%" }}>
            <Card>
              <Card.Content>
                {document.docType4 != null && (
                  <a href={document.docType4}>ไฟล์ Mother ID / Passport</a>
                )}
                {document.docType4 == null && (
                  <p style={{ color: "red" }}>File Not Found</p>
                )}
              </Card.Content>
            </Card>
          </Grid.Column>
        </Grid>
        <Grid columns="2" style={{ width: "60%" }}>
          <Grid.Column style={{ width: "30%" }}>
            <b>Guardian 1 ID / Passport</b>
          </Grid.Column>
          <Grid.Column style={{ width: "70%" }}>
            <Card>
              <Card.Content>
                {document.docType5 != null && (
                  <a href={document.docType5}>ไฟล์ Guardian 1 ID / Passport</a>
                )}
                {document.docType5 == null && (
                  <p style={{ color: "red" }}>File Not Found</p>
                )}
              </Card.Content>
            </Card>
          </Grid.Column>
        </Grid>
        <Grid columns="2" style={{ width: "60%" }}>
          <Grid.Column style={{ width: "30%" }}>
            <b>Guardian 2 ID / Passport</b>
          </Grid.Column>
          <Grid.Column style={{ width: "70%" }}>
            <Card>
              <Card.Content>
                {document.docType6 != null && (
                  <a href={document.docType6}>ไฟล์ Guardian 2 ID / Passport</a>
                )}
                {document.docType6 == null && (
                  <p style={{ color: "red" }}>File Not Found</p>
                )}
              </Card.Content>
            </Card>
          </Grid.Column>
        </Grid>
        <Grid columns="2" style={{ width: "60%" }}>
          <Grid.Column style={{ width: "30%" }}>
            <b>Health Report Signed</b>
          </Grid.Column>
          <Grid.Column style={{ width: "70%" }}>
            <Card>
              <Card.Content>
                {document.docType7 != null && (
                  <a href={document.docType7}>ไฟล์ Health Report Signed</a>
                )}
                {document.docType7 == null && (
                  <p style={{ color: "red" }}>File Not Found</p>
                )}
              </Card.Content>
            </Card>
          </Grid.Column>
        </Grid>

        <br></br>
        <br></br>
        <br></br>
        {saveYet ? (
          <FormPreviewRegister
            onSubmit={onSubmitRegister}
            initialValues={data}
            loading={loading}
            errormsg={errormsg}
          />
        ) : (
          <>
            {data.id != null && (
              <ModalBasic
                open={open1}
                size="big"
                style={{ color: "#7AC142", textAlign: "center" }}
                content="ข้อมูลทั้งหมดถูกบันทึกในระบบแล้วครับ"
              ></ModalBasic>
            )}
            <p style={{ color: "#7AC142" }}>
              ข้อมูลทั้งหมดถูกบันทึกในระบบแล้วครับ
            </p>
          </>
        )}
        <br></br>
      </Segment>

      <br></br>
      <br></br>
      {saveYet ? (
        <center>
          <Button
            floated="left"
            content="Back"
            style={{
              backgroundColor: "#4682B4",
              color: "#ffffff",
              minWidth: "20%",
              marginLeft: "10%",
            }}
            onClick={() => goPagedocument()}
          />
        </center>
      ) : null}
      <br></br>
      <br></br>

      <ModalBasic open={open} size="large" close={popUpClose}>
        <Header as="h2" style={{ textAlign: "center" }}>
          บัททึกข้อมูลเรียบร้อย
        </Header>
        <center>
          <Button
            content="OK"
            style={{
              backgroundColor: config.colorTheme,
              color: "#ffffff",
              minWidth: "5%",
            }}
            onClick={() => popUpClose()}
          />
        </center>
      </ModalBasic>
    </Segment>
  );
};

export default withRouter(PreviewStudent);
