import React, { useState, useEffect } from "react";
import { Field, reduxForm, formValueSelector } from "redux-form";
import renderField from "../../../components/renderField";
import renderSelectField from "../../../components/renderSelectField";
import renderFileField from "../../../components/renderFileField";
import renderDateTimeField from "../../../components/renderDateTimeField";
import renderDateOnlyField from "../../../components/renderDateOnlyField";
import { Button, Form, Message, Grid } from "semantic-ui-react";
import SecondButton from "../../../components/secondbutton";
import * as config from "../../../config";
import { connect } from "react-redux";
import {
  Header,
  Image,
  Segment,
  Breadcrumb,
  Loader,
  Dimmer,
  Tab,
  Table,
  Icon,
  Label,
  FormGroup,
  Step,
} from "semantic-ui-react";
import renderDateField from "../../../components/renderDateOnlyField";
import ModalBasic from "../../../components/modal";
import Wrap from "../../../components/wrap";

import { getguardian2, updateguardian2, addguardian2 } from "./service";
import FormGuardian2 from "./formguardian2";
import FormEditGuardian2 from "./formeditguardian2";
import FormImg from "./formimg";

const windowUrl = window.location.search;
const Id = windowUrl.split("=");
// console.log(Id[1]);

let Guardian2 = (props) => {
  const [loading, setLoading] = useState(true);
  const [student_Id, setStudent_Id] = useState(Id[1]);
  const [data, setData] = useState([]);
  const [showdata, setShowData] = useState(false);
  const [currentData, setCurrentData] = useState(null);
  const [open, setOpen] = useState(false);
  const [openImg, setOpenImg] = useState(false);
  const [actMessage, setActMessage] = useState("");
  const [errormsg, setErrormsg] = useState(null);

  useEffect(() => {
    setLoading(true);
    getData();
  }, [student_Id]);

  const getData = () => {
    getguardian2(student_Id).then((res) => {
      // console.log(student_Id);
      if (res && res.status === 200) {
        setData(res.data);
        if (res.data.id == " " || res.data.id == null) {
          setShowData(true);
        } else if (res.data.id != " " || res.data.id != null) {
          setShowData(false);
        }
      }

      if (res && res.status === 500) {
        setShowData(true);
      }
      setLoading(false);
    });
  };

  const onEdit = (values) => {
    setOpen(true);
    setCurrentData(values);
  };
  const onClose = () => {
    setOpen(false);
    setCurrentData(null);
  };

  const updateData = (values) => {
    setLoading(true);
    let data = values;
    updateguardian2(values).then((res) => {
      console.log(res);
      if (res && res.status === 200) {
        getData();
        setOpen(false);
      }
      setCurrentData(null);
      setLoading(false);
    });
  };

  const onEditImg = (values) => {
    setOpenImg(true);
    setCurrentData(values);
  };
  const onCloseImg = () => {
    setOpenImg(false);
    setCurrentData(null);
  };

  const updateDataImg = (values) => {
    setLoading(true);
    let data = values;
  };

  const onAddGuardian2 = (values) => {
    setErrormsg(null);
    setLoading(true);
    let data = values;

    // Protect human error
    // >> Verify phone No. must be 10 digits
    if (values.phone && values.phone.length != 10) {
      setErrormsg("Invalid phone number, must be 10 digits");
      setLoading(false);
      return;
    }
    // >> Verify not allow (-) at first digit
    if (values.phone && parseInt(values.phone) < 0) {
      setErrormsg("Invalid phone number, do not allow (-) at first digit.");
      setLoading(false);
      return;
    }

    addguardian2(values).then((res) => {
      console.log(`addguardian2`, res);
      if (res && res.status === 201) {
        setActMessage("บันทึกข้อมูล Guardian2 สำเร็จ");
        window.location.reload();
        setLoading(false);
      }
    });
  };

  // console.log(data)

  return (
    <Segment basic textAlign="left">
      {showdata ? (
        <FormGuardian2
          onSubmit={onAddGuardian2}
          initialValues={student_Id}
          errormsg={errormsg}
          loading={loading}
          actMessage={actMessage}
        />
      ) : (
        <Segment>
          <Header as="h4">Guardian 2</Header>
          <Segment>
            <Grid columns="2" style={{ width: "70%" }}>
              {data.imageURL && (
                <>
                  <Grid.Column style={{ paddingLeft: "40px" }}>
                    <Image
                      width={"25%"}
                      verticalAlign="middle"
                      src={
                        data.imageURL
                          ? data.imageURL + "?time=" + new Date()
                          : "../storage/imgs/dfkid.png"
                      }
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = "../storage/imgs/dfkid.png";
                      }}
                    />
                    {/* <Button content='Image'
                                                onClick={() => onEditImg(data)}
                                                style={{ backgroundColor: config.colorTheme, color: "#ffffff", minWidth: '10%', marginLeft: "10%" }}
                                            >
                                            </Button> */}
                  </Grid.Column>
                </>
              )}
              {!data.imageURL && (
                <>
                  <Grid.Column style={{ paddingLeft: "40px" }}>
                    <Image
                      width={"25%"}
                      verticalAlign="middle"
                      src={
                        data.imageURL
                          ? data.imageURL + "?time=" + new Date()
                          : "../storage/imgs/dfkid.png"
                      }
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = "../storage/imgs/dfkid.png";
                      }}
                    />
                    <Button
                      content="Image"
                      onClick={() => onEditImg(data)}
                      style={{
                        backgroundColor: config.colorTheme,
                        color: "#ffffff",
                        minWidth: "10%",
                        marginLeft: "10%",
                      }}
                    ></Button>
                  </Grid.Column>
                  <Grid.Column>
                    <p style={{ color: "red", marginTop: "8%" }}>
                      Please Upload Image
                    </p>
                  </Grid.Column>
                </>
              )}
            </Grid>
          </Segment>
          <Grid>
            <Grid.Row>
              <Grid columns="2" style={{ width: "70%" }}>
                <Grid.Column style={{ paddingLeft: "40px" }}>
                  Passport or ID No. : <b>{data.idnumber}</b>
                </Grid.Column>
                <Grid.Column>
                  Date Of Birth : <b>{data.dateOfBirth}</b>
                </Grid.Column>
              </Grid>
            </Grid.Row>
            <Grid.Row>
              <Grid columns="4" style={{ width: "70%" }}>
                <Grid.Column style={{ paddingLeft: "40px" }}>
                  firstname: <b>{data.firstName}</b>
                </Grid.Column>
                <Grid.Column>
                  lastname : <b>{data.lastName}</b>
                </Grid.Column>
                <Grid.Column>
                  Nationality : <b>{data.nationality}</b>
                </Grid.Column>
                <Grid.Column>
                  Native Language :<b>{data.nativelanguage}</b>
                </Grid.Column>
              </Grid>
            </Grid.Row>
            <Grid.Row>
              <Grid columns="4" style={{ width: "70%" }}>
                <Grid.Column style={{ paddingLeft: "40px" }}>
                  ชื่อ: <b>{data.firstName_th}</b>
                </Grid.Column>
                <Grid.Column>
                  นามสกุล : <b>{data.lastName_th}</b>
                </Grid.Column>
                <Grid.Column>
                  mobile : <b>{data.phone}</b>
                </Grid.Column>
                <Grid.Column>
                  email :<b>{data.email}</b>
                </Grid.Column>
              </Grid>
            </Grid.Row>
            <Grid.Row>
              <Grid columns="4" style={{ width: "70%" }}>
                <Grid.Column style={{ paddingLeft: "40px" }}>
                  Place of work: <b>{data.placeOfWork}</b>
                </Grid.Column>
                <Grid.Column>
                  Position : <b>{data.position}</b>
                </Grid.Column>
                <Grid.Column>
                  Office Tel : <b>{data.officePhone}</b>
                </Grid.Column>
              </Grid>
            </Grid.Row>
            <Grid.Row>
              <Grid columns="4" style={{ width: "70%" }}>
                <Grid.Column style={{ paddingLeft: "40px" }}>
                  Relationship: <b>{data.relationship}</b>
                </Grid.Column>
              </Grid>
            </Grid.Row>
            {/* <Button floated='right' content='Edit Data'
                                onClick={() => onEdit(data)}
                                style={{ backgroundColor: config.colorTheme, color: "#ffffff", minWidth: '10%' }}
                            /> */}
          </Grid>
          <br></br>
        </Segment>
      )}

      <ModalBasic open={open} size="big" close={() => onClose()}>
        <FormEditGuardian2
          loading={loading}
          initialValues={currentData}
          onClose={() => onClose()}
          onSubmit={updateData}
        />
      </ModalBasic>

      <ModalBasic open={openImg} size="big" close={() => onCloseImg()}>
        <FormImg
          loading={loading}
          initialValues={currentData}
          onClose={() => onCloseImg()}
          onSubmit={updateDataImg}
        />
      </ModalBasic>
    </Segment>
  );
};

export default Guardian2;
