import axios from "axios";
import * as config from "../config";

export function postVisitRequest(data) {
    return axios
        .post(config.urlAPI + "/addvisitrequest", data)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            return err.response;
        });
}

export function getdatavisitrequest() {
    return axios
        .get(config.urlAPI + "/getdatavisitrequest", config.headerCallAPI)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            return err.response;
        });
}

export function updatevisitprofile(data) {
    return axios
        .post(config.urlAPI + "/updatevisitprofile/" + data.Id, data, config.headerCallAPI)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            return err.response;
        });
}

export function getvisitProfile(Id) {
    return axios
        .get(config.urlAPI + "/visit/" + Id, config.headerCallAPI)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            return err.response;
        });
}

export function addapplicationstudentdetail(data) {
    return axios
        .post(config.urlAPI + "/addapplicationstudentdetail", data)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            return err.response;
        });
}

export function updateSibling(data,id) {
    return axios
        .post(config.urlAPI + "/updateSibling/"+ id, data)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            return err.response;
        });
}

export function getsibling(sibling) {
    return axios
        .get(config.urlAPI + "/getsibling/" + sibling)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            return err.response;
        });
}

export function updateapplicationstudentdetail(data) {
    return axios
        .post(config.urlAPI + "/updateapplicationstudentdetail/" + data.visit_id, data, config.headerCallAPI)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            return err.response;
        });
}

export function getstudentdetailProfile(Id) {
    return axios
        .get(config.urlAPI + "/getstudentdetailProfile/" + Id, config.headerCallAPI)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            return err.response;
        });
}

