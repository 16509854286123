// import React from "react";
import React, { useState, useEffect } from "react";
import { Field, reduxForm, formValueSelector } from "redux-form";
import renderField from "../../../components/renderField";
import renderSelectField from "../../../components/renderSelectField";
import renderFileField from "../../../components/renderFileField";
import renderDateTimeField from "../../../components/renderDateTimeField";
import renderDateOnlyField from "../../../components/renderDateOnlyField";
import { Button, Form, Message, Grid } from "semantic-ui-react";
import SecondButton from "../../../components/secondbutton";
import * as config from '../../../config';
import { connect } from "react-redux";
import {
    Header,
    Image,
    Segment,
    Breadcrumb,
    Loader,
    Dimmer,
    Tab,
    Table,
    Icon,
    Label,
    FormGroup
} from 'semantic-ui-react';
import renderDateField from "../../../components/renderDateOnlyField";
import { withRouter } from "react-router-dom";


let FormEditGuardian2 = (props) => {
    const { loading, pristine, submitting, onSubmit, handleSubmit, initialValue, initialValues2, onClose } = props;

    return (

        <Segment>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <div style={{ paddingLeft: "20px" }}>
                    <Grid divided stackable>
                        <Grid.Row>
                            <Grid.Column>
                                <Header as='h4' >Guardian 2</Header>
                                <Form.Group>
                                    <Field
                                        width="5"
                                        name="idnumber"
                                        type="text"
                                        required={true}
                                        component={renderField}
                                        label="Passport or ID No."
                                    />
                                    <Field
                                        width="3"
                                        name="dateOfBirth"
                                        required={true}
                                        component={renderDateOnlyField}
                                        label="Date Of Birth"
                                    />
                                </Form.Group>
                                <Form.Group unstackable>
                                    <Field
                                        width="3"
                                        name="firstName"
                                        type="text"
                                        required={true}
                                        component={renderField}
                                        label="firstname"
                                    />
                                    <Field
                                        width="3"
                                        name="lastName"
                                        type="text"
                                        required={true}
                                        component={renderField}
                                        label="lastname"
                                    />
                                    <Field
                                        width="3"
                                        name="nationality"
                                        type="text"
                                        required={false}
                                        component={renderSelectField}
                                        options={config.options_nationality}
                                        label="Nationality"
                                    />
                                    <Field
                                        width="3"
                                        name="nativelanguage"
                                        type="text"
                                        required={false}
                                        component={renderField}
                                        label="Native Language"
                                    />
                                </Form.Group>
                                <Form.Group unstackable>
                                    <Field
                                        width="3"
                                        name="firstName_th"
                                        type="text"
                                        required={false}
                                        component={renderField}
                                        label="ชื่อ"
                                    />
                                    <Field
                                        width="3"
                                        name="lastName_th"
                                        type="text"
                                        required={false}
                                        component={renderField}
                                        label="นามสกุล"
                                    />
                                    <Field
                                        width="3"
                                        name="phone"
                                        type="text"
                                        required={true}
                                        component={renderField}
                                        label="Mobile"
                                    />
                                    <Field
                                        width="3"
                                        name="email"
                                        type="text"
                                        required={false}
                                        component={renderField}
                                        label="E-mail"
                                    />
                                </Form.Group>
                                <Form.Group unstackable>
                                    <Field
                                        width="6"
                                        name="placeOfWork"
                                        type="text"
                                        required={false}
                                        component={renderField}
                                        label="Place of work"
                                    />
                                    <Field
                                        width="3"
                                        name="position"
                                        type="text"
                                        required={false}
                                        component={renderField}
                                        label="Position"
                                    />
                                    <Field
                                        width="3"
                                        name="officePhone"
                                        type="text"
                                        required={false}
                                        component={renderField}
                                        label="Office Tel"
                                    />
                                </Form.Group>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                <Form.Group unstackable>
                                    <Field
                                        width="6"
                                        name="relationship"
                                        type="text"
                                        required={true}
                                        component={renderSelectField}
                                        label="Relationship"
                                        options={config.options_relationship}
                                    />
                                </Form.Group>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                    <Button floated='left' content='Save'
                        style={{ backgroundColor: config.colorTheme, color: "#ffffff", minWidth: '30%' }}
                    />
                    <SecondButton floated='right' content='Cancel' color='grey' onClick={onClose} />
                    <br></br>
                    <br></br>
                    <br></br>
                </div>
            </Form>
        </Segment>


    );

}




FormEditGuardian2 = reduxForm({
    // a unique name for the form
    form: "FormEditGuardian2",
    enableReinitialize: true,
})(FormEditGuardian2);

const selector = formValueSelector("class");
FormEditGuardian2 = connect(state => {
    const start_card = selector(state, "start_card");
    const end_card = selector(state, "end_card");
    return {
        start_card: start_card,
        end_card: end_card
    };
})(FormEditGuardian2);

export default withRouter(FormEditGuardian2);
