import axios from "axios";
import * as config from "../../../config";

export function getvisitProfile(Id) {
    return axios
        .get(config.urlAPI + "/visit/" + Id, config.headerCallAPI)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            return err.response;
        });
}

export function getstudentdetailProfile(Id) {
    return axios
        .get(config.urlAPI + "/getstudentdetailProfile/" + Id, config.headerCallAPI)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            return err.response;
        });
}

export function updatehealthform(data) {
    return axios
        .post(config.urlAPI + "/updatehealthform", data)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            return err.response;
        });
}

export function addhealth(data) {
    return axios
        .post(config.urlAPI + "/addhealth", data)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            return err.response;
        });
}

export function getimmunization_gethealthCheckStudents_health(id) {
    return axios
        .get(config.urlAPI + "/getimmunization_gethealthCheckStudents_health/"+id)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            return err.response;
        });
}

export function getinterview(id) {
    return axios
        .get(config.urlAPI + "/getinterview/"+id)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            return err.response;
        });
}
